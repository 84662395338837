import React from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, MenuItem, FormControl, RadioGroup, FormControlLabel, Radio, Fade } from '@mui/material';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { IForm } from '../SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplateModel';

interface AdditionalQuestionPreviewDialogProps {
  open: boolean;
  onClose: () => void;
  selectedForm: IForm; 
}

export const AdditionalQuestionPreviewDialog: React.FC<AdditionalQuestionPreviewDialogProps> = ({ open, onClose, selectedForm }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography variant="h6">Additional Question's Preview</Typography>
      </DialogTitle>
      <DialogContent>
        <Box flex={1}>
          <ShPaper variant="outlined" headerSection>
            <Typography>Preview</Typography>
          </ShPaper>
          <ShPaper variant="outlined" noBorderTop borderRadius={0}>
            <Fade in={true} timeout={800}>
              <Box>
                {selectedForm?.questions?.length ? (
                  selectedForm.questions.map((question: any, index: number) => (
                    <Box key={question.id} mb={3}>
                      <Typography variant="subtitle2" fontWeight="bold" gutterBottom>
                        {index + 1}. {question.question}
                        {question.required === "required" && (
                          <Typography component="span" color="error" ml={0.5}>
                            *
                          </Typography>
                        )}
                      </Typography>

                      {question.type === "textfield" && (
                        <ShTextFieldV2
                          size="small"
                          fullWidth
                          placeholder="Enter your response"
                          variant="outlined"
                        />
                      )}

                      {question.type === "dropdown" && (
                        <ShTextFieldV2
                          size="small"
                          select
                          fullWidth
                          placeholder="Select an option"
                          variant="outlined"
                        >
                          {question.options.map((option: any, optionIdx: number) => (
                            <MenuItem key={optionIdx} value={option.label}>
                              <Typography variant="subtitle2">
                                {option.label}{" "}
                                {option.autoReject && (
                                  <ShChip
                                    label="Auto Reject"
                                    color="error"
                                    customSize="xs"
                                    sx={{ ml: 1 }}
                                  />
                                )}
                              </Typography>
                            </MenuItem>
                          ))}
                        </ShTextFieldV2>
                      )}

                      {question.type === "radio" && (
                        <FormControl component="fieldset" size="small">
                          <RadioGroup>
                            {question.options.map((option: any, optionIdx: number) => (
                              <FormControlLabel
                                key={optionIdx}
                                value={option.label}
                                control={<Radio />}
                                label={
                                  <Typography variant="subtitle2">
                                    {option.label}{" "}
                                    {option.autoReject && (
                                      <ShChip
                                        label="Auto Reject"
                                        color="error"
                                        customSize="xs"
                                        size="small"
                                        sx={{ ml: 1 }}
                                      />
                                    )}
                                  </Typography>
                                }
                              />
                            ))}
                          </RadioGroup>
                        </FormControl>
                      )}
                    </Box>
                  ))
                ) : (
                  <Typography variant="body2">No additional question forms selected.</Typography>
                )}
              </Box>
            </Fade>
          </ShPaper>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalQuestionPreviewDialog;

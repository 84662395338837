import { ApiState, IBaseResponse } from "shared/SharedModels";
import { IAllJobDetails, ICompensation, IJobDetails, IRequirement, JobLocationType } from "store/slices/employer/jobs/jobs-slice-model";

export type CreateJobPath = 'new' | 'title' | 'details' | 'preview' | 'additional-questions' | 'eeoQuestionnaire' | 'bundles' | 'payment' | 'failed' | 'success' | 'welcome';
export type RequirementType = 'education' | 'qualification' | 'skill' | 'responsibility';
export type RequirementLevel = 'must_have' | 'nice_to_have' | 'important';
export type JobDescriptionType = 'sh' | 'custom' | 'ai';
export type BundleType = 'base' | 'addon';
export type PaymentResponse = 'success' | 'failed';
export type TJobTitleFields = 'role' | 'hiring_manager' | 'additional_hiring_manager' | 'job_location' |
  'neighborhood' | 'employment_type' | 'experience' | 'job_title' | 'job_location_remote' | 'job_location_onsite' | 'job_location_hybrid';
export type TJobCreationFLow = 'default' | 'templateJob' | 'useTemplateJob';

export type TRequirementTypeMap = {
  [key in RequirementType]: string;
};

export type TRequirementLevelMap = {
  [key in RequirementLevel]: string;
};

export enum CreateJobPathEnum {
  Title = "title",
  Details = "details",
  Preview = "preview",
  AdditionalQuestions = "additional-questions",
  EEO = "eeoQuestionnaire",
  Bundles = "bundles",
  Payment = "payment",
}

export interface ICreateJobSharedState {
  eeoQuestions?: number[],
  fetchEEOQuestionsStatus: ApiState,
  updateEEOQuestionsStatus: ApiState,
  jobTitleInfo?: IJobTitle;
  jobDetails?: IJobDetails;
  createOrUpdateJobStatus: ApiState;
  createOrUpdateJobResponse: string;
  getRequirementsStatus: ApiState;
  getRequirementsResponse: string;
  jobRequirements: IRequirement[];
  getRequirementsMetaDataStatus: ApiState;
  getRequirementsMetaDataResponse: string;
  requirementsMetaData: IRequirementsMetaData | undefined;
  defaultRequirementLevel: number;
  isDetailsEnabled: boolean;
  isEeoEnabled?: boolean;
  isPreviewEnabled?: boolean;
  isBundlesEnabled: boolean;
  isPaymentEnabled?: boolean,
  allJobDetails?: IAllJobDetails,
  currentJobCreationFlow: TJobCreationFLow;
  isJobIdWithTemplateCreated?: boolean
}

export interface IAboutCompanyState {
  getJobIndustriesStatus: ApiState,
  getJobIndustriesResponse: string,
  updateAboutCompanyStatus: ApiState,
  updateAboutCompanyResponse: string,
  newJobId: number
};

export interface INewJob {
  job_title: string;
  job_location: string;
  positiontype_id: number;
  type: string;
  salary_details: string;
  benefits: string;
  company_alias: string;
  hiring_manager_id: number;
  province_id: string;
  experience: string;
  country_id: number;
  company_description: string;
  postalcode: string;
  location: string;
  description: string;
}

export interface IAboutYourCompany {
  id: number;
  company_alias: string;
  location: string;
  province_id: number;
  positiontype_id: number | undefined;
  postalcode: string;
  description: string;
}

// Job title
export interface IJobTitle extends IPreviewJobDetails {
  job_title_id: number,
  name: string,
  hiring_manager_id: number | null,
  optional_hiring_manager_id: number | null,
  remote_job_type: JobLocationType | null,
  city: string,
  postalcode: string,
  province_id: number,
  neighbourhood: string,
  hours_per_week?: number,
  experience: string,
  positiontype_id: number | null
}

export interface IUseATemplateJobPayload {
  name: string,
  hiring_manager_id: number | null,
  country_id: number | undefined,
  province_id: number | null,
  postalcode: string,
  city: string,
  neighbourhood: string
}

export interface IJobHiringManager {
  email: string;
  first_name: string;
  id: number;
  last_name: string;
}

export interface IAreaInformation {
  country: string;
  state: string;
  city: string;
}

export interface IJobTitleState {
  getJobHiringManagersStatus: ApiState,
  getJobHiringManagersResponse: string,
  jobHiringManagers: IJobHiringManager[],
  newJobCreationStatus: ApiState,
  newJobCreationResponse: string,
  newJobId: number | undefined,
  areaInformation: IAreaInformation,
  getAreaInformationStatus: ApiState,
  rePostJobId?: string,
  useATemplateJobStatus?: ApiState,
  useATemplateJobResponse?: string,
  jobIdCreatedWithTemplate?: number
};


export interface IRequirementCategory { id: number; name: RequirementType };

export interface IRequirementsMetaData {
  categories: IRequirementCategory[];
  levels: { id: number; name: RequirementLevel; the_default: boolean }[];
  salaryTypes: { id: number; monster_id: number; monster_name: string }[];
  positionTypes: { id: number; name: string }[];
}

export interface ICreateJobDetailsState {
  createOrUpdateJobReqStatus: ApiState;
  createOrUpdateJobReqResponse: string;
  getAdditionalCompensationsStatus?: ApiState,
  getAdditionalCompensationsResponse?: string,
  additionalCompensations?: ICompensation[]
}

export interface IPreviewJobDetails {
  description?: string;
  ai_generated_description?: string;
  custom_description?: boolean;
  ai_job_description?: boolean;
  is_complete?: boolean;
}

export interface IUpdateAboutCompany {
  employer_id: number;
  company_name: string;
  industry_id: number;
  company_location: string;
  state_id: number;
  postalcode: string;
  company_description: string;
  jobId?: number;
}

export interface IUpdateCompanyInfoResponse extends IBaseResponse {
  newJobId: number;
}

export interface ICreateJobPreviewState {
  getAiJobDescStatus: ApiState;
  getAiJobDescResponse: string;
  aiJobDescription: string | undefined;

  getAiformattedJobDescStatus: ApiState;
  getAiformattedJobDescResponse: string;
  aiformattedJobDesc: string | undefined;

  getEnhancedAiJobDescResponse: string,
  getEnhancedAiJobDescStatus: ApiState,
  enhancedAiJobDesc: string | undefined,

}

export interface INewJobCreationResponse {
  message: string;
  newJobId: number;
}

export interface IRoles {
  roleId: number;
  roleName: string;
  industryId: number;
  industryName: string;
}

export interface IJobPayment {
  nameOnCard: string;
  cardNumber: number | undefined;
  expirationMonth: string | null;
  expirationYear: string | null;
  cvv: number | undefined;
  city: string;
  country: string | null;
  zipCode: string;
  province_id: number;
}

export interface IBundle {
  id: number;
  country_id: number;
  name: string;
  level: number;
  base_price: number;
  active: true,
  description: string;
  kind: BundleType;
  employer_pricing_group_id: number;
  code: string;
  order: number;
  discount?: number;                // Optional discount percentage
  is_featured?: boolean;            // Optional field indicating if the bundle is featured
  currency_symbol?: string;         // Optional currency symbol (e.g., "$")
  terms_and_conditions?: string;    // Optional field for terms and conditions
  features_included?: string;       // Optional field for features included (comma-separated string or array)
  features_excluded?: string;       // Optional field 
};

export interface IInventoryBundle {
  name: string,
  quantity: number,
  bundle_id: number
};

export interface IPaymentsState {
  getInventoryBundlesStatus: ApiState,
  getAllBundlesStatus: ApiState,
  getInventoryBundlesResponse: string,
  getAllBundlesResponse: string,
  allBundles: IBundle[],
  allBaseBundles: IBundle[],
  allAddOnBundles: IBundle[],
  inventoryBundles: IInventoryBundle[],
  selectedBaseBundle: number,
  selectedAddOnBundles: number[],
  totalJobCost: number,
  addJobToCartStatus?: ApiState,
  addJobToCartResponse?: string,
  orderId?: number,
  purchaseJobStatus?: ApiState | 'stripe_payment_required',
  purchaseJobResponse?: string,
  purchaseJobWithCouponStatus?: ApiState,
  purchaseJobWithCouponResponse?: string,
  purchaseUrl?: string,
};

export interface IAddJobToCart {
  jobId: number,
  payload: { bundles: number[] }
};

export interface IPurchaseJob {
  jobId: number,
  orderId: number
};

export interface IPurchaseJobWithCoupon {
  jobId: number,
  payload: { bundles: number[], coupon_code: string }
}

export interface ICheckoutJobResponse {
  message: string,
  order_id: number
};

export interface IPurchaseResponse {
  message: string,
  purchase_complete: boolean,
  url: string,
};

export interface ITestimonialData {
  review: string,
  name: string
}

export interface IJobDetailActionItems {
  removeReq: (req: IRequirement) => void,
  addReq: (req: IRequirement) => void,
  isMandatoryRow: boolean,
  requirement: IRequirement,
  isLastRow: boolean,
  isAddDisabled: boolean
};

export type TJobRequirementCategoryTooltip = {
  [key in RequirementType]: string;
};

export type IJobTitleTooltips = {
  [key in TJobTitleFields]: string;
};

export interface IAdditionalCompensation {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export interface EEOQuestionsPayload {
  jobId: string;
  eeoQuestions: number[];
}
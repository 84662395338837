import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  Fade,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShTableContainer } from "shared/SharedStyles/ShDataDisplay";
import { ShButton, ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchEEOQuestions, updateEEOQuestions } from "store/slices/employer/create-job/create-job-shared-slices";
import { JobDetailsActionsStack } from "./CreateJob.styled";
import { EEOQutionaareData } from "./EEOConfig";

export const EEOJobPreview = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const dispatch = useAppDispatch();
  const { eeoQuestions, fetchEEOQuestionsStatus } = useAppSelector((state) => state.employer.createJob.createJobShared);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [includedQuestions, setIncludedQuestions] = useState<{ [key: number]: boolean }>(
    EEOQutionaareData.reduce((acc, _, idx) => {
      acc[idx] = false;
      return acc;
    }, {} as { [key: number]: boolean })
  );

  useEffect(() => {
    // Initialize the includedQuestions state based on the fetched eeoQuestions
    if (eeoQuestions) {
      const initialQuestions = EEOQutionaareData.reduce((acc, item, idx) => {
        // Set true for indices present in eeoQuestions
        acc[idx] = eeoQuestions.includes(item.idx);
        return acc;

      }, {} as { [key: number]: boolean });
      setIncludedQuestions(initialQuestions);
    }
  }, [eeoQuestions, fetchEEOQuestionsStatus]);


  const handleIncludeChange = (idx: number, checked: boolean) => {
    setIncludedQuestions((prev) => ({ ...prev, [idx]: checked }));
  };

  const handleSelectAllChange = (checked: boolean) => {
    const allIncluded = EEOQutionaareData.reduce((acc, _, idx) => {
      acc[idx] = checked;
      return acc;
    }, {} as { [key: number]: boolean });

    setIncludedQuestions(allIncluded);
  };

  const handleSave = () => {
    const eeoQuestions = EEOQutionaareData.filter((item, idx) => includedQuestions[idx])
      .map((item) => item.idx); // Map to get the 'idx' of each selected question    
    if (jobId) {
      dispatch(updateEEOQuestions({ jobId, eeoQuestions }));
    }
  };

  useEffect(() => {
    if (jobId) {
      dispatch(fetchEEOQuestions({ jobId }));
    }
  }, [jobId, dispatch]);

  return (
    <>
      <Fade in={true} timeout={800}>
        <Stack alignContent='center'>
          <ShPaper variant='outlined' borderRadius={0} headerSection >
            <Stack columnGap={1} flexDirection="row" alignItems="center" display="flex" paddingY={1.5} justifyContent='space-between'>
              <Stack direction='row' alignItems="center" display="flex" spacing={1}>
                <Typography variant="subtitle1">Equal Employment Opportunity Questionnaire (Optional)</Typography>
                <ShTooltip
                  title={
                    "This section includes the EEO questionnaire, which gathers optional demographic details like gender, ethnicity, and disability status. This information is used for diversity tracking and fostering an inclusive workplace."
                  } placement="right">
                  <HelpOutlineIcon fontSize="small" color="disabled" sx={{ verticalAlign: 'middle' }} />
                </ShTooltip>
              </Stack>
              <ShGreenBtn variant="contained" size='small' disableElevation onClick={() => {
                handleSave();
                navigate(`/employer/job/${jobId}/preview`)
              }}>
                Skip
              </ShGreenBtn>
            </Stack>
            <Typography variant="subtitle2" color='textSecondary' maxWidth={800}>
              This questionnaire gathers optional demographic details about gender, ethnicity, and disability status, which are used for diversity tracking and fostering an inclusive workplace.
              You may choose which questions to include or disable the questionnaire from the job creation flow in settings.
            </Typography>
          </ShPaper>
          <ShPaper variant="outlined">
            <ShTableContainer>
              <Table size="small" aria-label="EEO Questionnaire table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderRight: "1px solid", borderColor: "divider" }}>
                      <FormControlLabel
                        control={
                          <ShSwitch onChange={(e) => handleSelectAllChange(e.target.checked)} />
                        }
                        label="" />
                    </TableCell>
                    <TableCell sx={{ borderRight: "1px solid", borderColor: "divider" }} >
                      <Typography variant="body1">Question</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body1">Answer Options</Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {EEOQutionaareData.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <ShSwitch
                              checked={includedQuestions[idx] ?? true}
                              onChange={(e) => handleIncludeChange(idx, e.target.checked)} />
                          }
                          label=""
                        />
                      </TableCell>

                      <TableCell>
                        <Typography variant="body2">{item.label}</Typography>
                      </TableCell>

                      <TableCell>
                        {includedQuestions[idx] && (
                          <>
                            {item.type === "text" && (
                              <ShTextFieldV2 size="small" variant="outlined" placeholder="Enter your answer" fullWidth sx={{ width: "100%" }} />
                            )}

                            {item.type === "radio" && (
                              <RadioGroup sx={{ justifyContent: "flex-start", flexDirection: "row" }}>
                                {item.options?.map((option, optionIdx) => (
                                  <Stack key={optionIdx} direction="row" alignItems="center">
                                    <Radio value={option} />
                                    <Typography variant="body2">{option}</Typography>
                                  </Stack>
                                ))}
                              </RadioGroup>
                            )}

                            {item.type === "dropdown" && (
                              <ShTextFieldV2 select fullWidth size="small" variant="outlined" label="Select option">
                                {item.options?.map((option, optionIdx) => (
                                  <MenuItem key={optionIdx} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </ShTextFieldV2>
                            )}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

            </ShTableContainer>
            <JobDetailsActionsStack paddingY={1}>
              <ShButton variant="contained" size="small" disableElevation onClick={() => {
                handleSave();
                navigate(`/employer/job/${jobId}/additional-questions`)
              }
              }>
                Back
              </ShButton>
              <Stack direction="row" columnGap={1}>
                <ShGreenBtn variant="contained" disableElevation
                  onClick={() => {
                    handleSave();
                    navigate(`/employer/job/${jobId}/preview`)
                  }}>
                  Next
                </ShGreenBtn>
              </Stack>
            </JobDetailsActionsStack>
          </ShPaper>
        </Stack>
      </Fade>
    </>
  );
};

import { CircularProgress } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { setLocalAccessToken } from "helpers/TokenHelper";
import { useCallback, useEffect, useImperativeHandle, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TSocialLoginProvider } from "store/slices/auth-v2/auth-v2-model";
import { loginThruSocial, logoutAccount, setAppContext, setTwoStepAuthForSocial } from "store/slices/auth-v2/auth-v2-slice";
import {
    candidateSignUpWithSocialProfile, getCandidateSocialLoginRedirectUrl, getSocialLoginProfile,
    getSocialLoginRedirectUrl, loginWithSocialProfile, resetCandidateSignUpWithSocialProfile, resetSocialLogin,
    resetSocialLoginWithProfile,
    setSocialLoginToApplyJobNavParams
} from "store/slices/auth-v2/social-login-slice";
import { resetTwoStepAuthState } from "store/slices/auth-v2/two-step-auth-slice";
import {
    AdminLoginRedirect, AdminLogoutRedirect, CandidateLoginRedirect, CandidateSurveyLogoutRedirect,
    EmployerLoginRedirect, EmployerLogoutRedirect
} from "./AuthConstants";
import { ISocialLoginProps } from "./AuthModel";
import { SocialButtons } from "./SocialButtons";
import { SocialLoader } from "./SocialLoader";
import { TwoStepAuthDialog } from "./TwoStepAuthDialog";

export const SocialLogin = ({ buttonType, isSignUp, usedIn, forwardedRef, loginProvider }: ISocialLoginProps) => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { postingSource } = useAppSelector((state) => state.candidate.applyJobHome);
    const { provider, status, jobCode } = useParams() as
        { provider: TSocialLoginProvider, status: 'success' | 'failed', jobCode: string };
    const location = useLocation();
    const authCode = new URLSearchParams(location.search)?.get('code');
    const urlState = new URLSearchParams(location.search)?.get('state');
    const codeVerifier = new URLSearchParams(location.search)?.get('codeVerifier');
    const { socialLoginRedirectUrl, getSocialLoginRedirectUrlStatus, employerSocialPayload, getSocialLoginProfileStatus,
        employerSocialProfileStatus, getCandidateSocialSignUpRedirectUrlStatus, candidateSocialLoginRedirectUrl,
        signUpWithCandidateSocialProfileStatus, candidateSignUpPayload, getSocialLoginProfileStatusCode
    } = useAppSelector((state) => state.auth.socialLogin);
    const { accountAccess, isAccountLoggedIn, currentAppContext } = useAppSelector(state => state.auth.auth);
    const [isAuthDialogOpen, setIsAuthDialogOpen] = useState<boolean>(false);
    const [authProvider, setAuthProvider] = useState<TSocialLoginProvider | undefined>(undefined);
    const [redirectedSocialUrlObj, setRedirectedSocialUrlObj] = useState<{ signUp: 'true' | 'false' } | undefined>(undefined);

    // Navigate to candidate survey flow if context is candidate_survey;
    useEffect(() => {
        if (currentAppContext === 'candidate_survey' && urlState) {
            const redirectObj = JSON.parse(decodeURIComponent(urlState)) as { signUp: 'true' | 'false' };
            if (redirectObj?.signUp === 'true') {
                if (location.search) {
                    const candidateSurveySignUpUrl = location.pathname.replace('accounts/login', 'candidate/sign-up') + location.search;
                    navigate(candidateSurveySignUpUrl);
                }
            } else if (redirectObj?.signUp === 'false') {
                const candidateSurveySignUpUrl = location.pathname.replace('accounts/login', 'candidate/login') + location.search;
                dispatch(setAppContext({ currentAppContext: 'candidate_survey' }));
                navigate(candidateSurveySignUpUrl);
            }
        }
    }, [currentAppContext, dispatch, location, navigate, urlState])

    const handleGetRedirect = (provider: TSocialLoginProvider) => {
        if (usedIn === 'candidate_apply_job') {
            dispatch(getCandidateSocialLoginRedirectUrl({ jobCode, postingSource: postingSource ?? '', provider: provider }));
        } else {
            dispatch(getSocialLoginRedirectUrl({ provider: provider, isSignUp: isSignUp ?? false }));
        }
        setAuthProvider(provider);
    }

    // Attach the childFunction to the ref passed from the parent
    useImperativeHandle(forwardedRef, () => ({
        handleGetRedirect
    }));

    // set "redirectedSocialUrlObj" if "urlState" is available.
    useEffect(() => {
        if (urlState) {
            setRedirectedSocialUrlObj(JSON.parse(decodeURIComponent(urlState)));
        }
    }, [urlState]);

    // Set Auth provider on redirect.
    useEffect(() => {
        if (provider && provider?.length > 0) {
            setAuthProvider(provider);
        }
    }, [provider]);

    // Call appropriate sign in api based on provider with auth code in payload.
    useEffect(() => {
        if (usedIn !== 'candidate_apply_job' && status === 'success' && authCode) {
            let payload;
    
            if (provider === "outlook" && urlState) {
                try {
                    const parsedState = JSON.parse(decodeURIComponent(urlState));
                    const codeVerifier = parsedState.codeVerifier;
    
                    payload = { 
                        oauth_code: authCode, 
                        codeVerifier:  codeVerifier
                    };
                } catch (error) {
                    console.error("Failed to parse urlState or extract codeVerifier:", error);
                    return; // Exit the effect if parsing fails
                }
            } else {
                payload = { oauth_code: authCode };
            }
    
            let redirectObj = null;
            if (urlState) {
                try {
                    redirectObj = JSON.parse(decodeURIComponent(urlState));
                } catch (error) {
                    console.error("Failed to parse redirect state:", error);
                }
            }
    
            if (redirectObj?.signUp === 'true') {
                dispatch(getSocialLoginProfile({ payload, provider }));
            } else {
                dispatch(loginWithSocialProfile({ payload, provider }));
            }
        }
    }, [authCode, dispatch, provider, status, urlState, usedIn]);
    

    // Call sign up candidate api based on provider with auth code in payload.
    useEffect(() => {
        if (usedIn === 'candidate_apply_job' && currentAppContext === 'candidate_apply_job') {
            if (status === 'success') {
                if (authCode) {
                    const payload = { oauth_code: authCode };
                    let redirectObj: { ps: string, jobCode: string };
                    if (urlState) {
                        redirectObj = JSON.parse(decodeURIComponent(urlState));
                        if (redirectObj && authProvider) {
                            dispatch(candidateSignUpWithSocialProfile({
                                jobCode: redirectObj.jobCode,
                                postingSource: redirectObj.ps,
                                payload: payload,
                                provider: authProvider
                            }));
                        }
                    }
                }
            }
        }
    }, [authCode, authProvider, currentAppContext, dispatch, status, urlState, usedIn]);

    // Navigate to questionnaire after sign up. 
    useEffect(() => {
        if (signUpWithCandidateSocialProfileStatus === 'success') {
            if (urlState) {
                let redirectObj: { ps: string, jobCode: string };
                redirectObj = JSON.parse(decodeURIComponent(urlState));
                if (redirectObj?.jobCode) {
                    const redirect = `/candidate/apply-job/${redirectObj?.jobCode}${redirectObj.ps ? ('?ps=' + redirectObj.ps) : ''}`;
                    dispatch(setSocialLoginToApplyJobNavParams({
                        postingSource: redirectObj.ps,
                        authToken: candidateSignUpPayload?.token ?? ' '
                    }));
                    navigate(redirect);
                    dispatch(resetCandidateSignUpWithSocialProfile());
                }
            }
        }
    }, [candidateSignUpPayload?.token, dispatch, navigate, signUpWithCandidateSocialProfileStatus, urlState]);

    // Once sign in url is retrieved from the get api, navigate to appropriate link based on the provider.
    useEffect(() => {
        if (getSocialLoginRedirectUrlStatus === 'success') {
            window.location.href = socialLoginRedirectUrl ?? '';
        }
    }, [getSocialLoginRedirectUrlStatus, socialLoginRedirectUrl]);

    // Once candidate sign in url is retrieved from the get api, navigate to appropriate link based on the provider.
    useEffect(() => {
        if (getCandidateSocialSignUpRedirectUrlStatus === 'success') {
            window.location.href = candidateSocialLoginRedirectUrl ?? '';
        }
    }, [getCandidateSocialSignUpRedirectUrlStatus, candidateSocialLoginRedirectUrl]);


    // login only after retrieving social profile
    useEffect(() => {
        if (employerSocialProfileStatus === 'success') {
            if (employerSocialPayload) {
                if (employerSocialPayload.showTwoStepAuthWindow) {
                    if (employerSocialPayload.token) {
                        setLocalAccessToken(employerSocialPayload.token);
                    }
                    dispatch(setTwoStepAuthForSocial({
                        emailVerification: employerSocialPayload.email_varification,
                        securityVerification: employerSocialPayload.security_varification,
                        question: employerSocialPayload.question
                    }));
                    setIsAuthDialogOpen(true);
                } else {
                    dispatch(loginThruSocial({ loginResponse: employerSocialPayload, loginPage: usedIn }));
                    dispatch(resetSocialLogin());
                }
            } else {
                switch (usedIn) {
                    case 'employer':
                        navigate('/accounts/sign-up');
                        break;
                    case 'candidate_survey':
                        navigate('/candidate/sign-up');
                        break;
                    default:
                        break;
                }
            }
        }
    }, [dispatch, employerSocialPayload, employerSocialProfileStatus, navigate, usedIn]);

    // re-direct to sign up with retrieved data from social profile.
    useEffect(() => {
        if (getSocialLoginProfileStatus === 'success') {
            if (usedIn === 'employer') {
                navigate('/accounts/sign-up');
            }
            dispatch(resetSocialLoginWithProfile());
        }
    }, [currentAppContext, dispatch, getSocialLoginProfileStatus, navigate, usedIn]);


    const redirectAfterLogin = useCallback(() => {
        switch (accountAccess?.dashboard) {
            case 'employer':
                navigate(EmployerLoginRedirect);
                break;
            case 'admin':
                navigate(AdminLoginRedirect);
                break;
            case 'candidate':
                if (currentAppContext === 'employer') {
                    navigate(CandidateLoginRedirect);
                } else if (currentAppContext === 'candidate_survey') {
                    navigate('/candidate/survey');
                }
                break;
            default:
                break;
        }
    }, [accountAccess?.dashboard, currentAppContext, navigate]);

    // if user is logged in, navigate accordingly to route
    useEffect(() => {
        if (isAccountLoggedIn) {
            redirectAfterLogin();
        }
    }, [isAccountLoggedIn, redirectAfterLogin]);

    /*
     * Redirect to prev page incase of invalid grant.
     * Happens when the user clicks on browser back button.
     */
    useEffect(() => {
        if (getSocialLoginProfileStatusCode === 500) {
            switch (usedIn) {
                case 'employer':
                    navigate(EmployerLogoutRedirect);
                    break;
                case 'admin':
                    navigate(AdminLogoutRedirect);
                    break;
                case 'candidate_apply_job':
                    navigate(`/candidate/apply-job/${jobCode}${postingSource ? ('?ps=' + postingSource) : ''}`);
                    break;
                case 'candidate_survey':
                    navigate(CandidateSurveyLogoutRedirect);

            }
            dispatch(resetSocialLoginWithProfile());
        }
    }, [dispatch, getSocialLoginProfileStatusCode, jobCode, navigate, postingSource, usedIn]);

    const onAuthDialogClose = (isAuthenticated: boolean) => {
        /* 
            If isAuthenticated is true then the user/device is authenticated.
            Set user login state to true, so that 'Authenticate' wrapper function gives access to the application URLs.
        */
        setIsAuthDialogOpen(false);
        if (isAuthenticated) {
            redirectAfterLogin();
            dispatch(resetSocialLogin());
        } else {
            dispatch(logoutAccount({ logoutType: 'auto' }));
        }
        // Reset device auth store on dialog close to ensure it works properly when its toggled again.
        dispatch(resetTwoStepAuthState());
    };

    return (<>
        {employerSocialPayload?.showTwoStepAuthWindow ?
            <TwoStepAuthDialog isAuthDialogOpen={isAuthDialogOpen} usedIn={usedIn}
                onAuthDialogClose={(isAuthenticated: boolean) => onAuthDialogClose(isAuthenticated)} /> :
            <>
                {/*  If authCode is null or undefined then social login buttons will be rendered, 
                
                else a loading screen with backdrop will be rendered. */}
                {!(redirectedSocialUrlObj?.signUp === 'true') && (authCode || status) && !isAccountLoggedIn ?
                    <>
                        {/* Auth redirect with backdrop and loading card. */}
                        <SocialLoader authProvider={authProvider} />
                    </> : (getSocialLoginRedirectUrlStatus === 'pending' || getCandidateSocialSignUpRedirectUrlStatus === 'pending' ||
                        employerSocialProfileStatus === 'pending' || signUpWithCandidateSocialProfileStatus === 'pending' ||
                        getSocialLoginProfileStatus === 'pending') ?
                        <CircularProgress color="inherit" /> :
                        <>
                            <SocialButtons buttonType={buttonType} handleGetRedirect={handleGetRedirect}
                                loginProvider={loginProvider} isSignUp={isSignUp} />
                        </>
                }
            </>}
    </>)
}
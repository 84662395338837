import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
  FormControl,
  FormHelperText,
  Typography,
  Box,
  Stack,
  LinearProgress,
} from "@mui/material";
import { ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import {
  fetchCandidateAdditionalQuestionsAnswers,
  updateCandidateAdditionalQuestionsJobApply,
} from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";
import { ApplyJobAdditionalQuestionsProps } from "../CreateJob/EEOConfig";
import { AdditionalQuestionAnswer } from "./ApplyJobModel";

export const ApplyJobAdditionalQuestions = ({ selectedJobForm, onFormValidation }: ApplyJobAdditionalQuestionsProps) => {
  const dispatch = useAppDispatch();
  const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
  const { additionalQuestions, fetchAdditionalQuestionsStatus} =
    useAppSelector((state) => state.candidate.applyJobQuestionnaire);
  const [answers, setAnswers] = useState<{ [questionId: string]: AdditionalQuestionAnswer }>({});

  useEffect(() => {
    if (selectedJobForm && additionalQuestions) {
        validateForm();
    }
}, [answers, selectedJobForm]);

    const validateForm = () => {
      if (selectedJobForm) {
          const allAnswered = selectedJobForm.questions.every((question) => {
              if (question.required === 'required') {
                  return answers[question.id]?.answer !== undefined && answers[question.id]?.answer !== '';
              }
              return true;
          });

          onFormValidation(allAnswered); 
      }
  };


  useEffect(() => {
    if (candidateDetails?.jobApplicationId) {
      dispatch(fetchCandidateAdditionalQuestionsAnswers({ jobApplicationId: candidateDetails.jobApplicationId }));
    }
  }, [dispatch, candidateDetails?.jobApplicationId]);

  useEffect(() => {
    // Mapping the incoming data to the answers state, including 'autoReject' for each option
    if (additionalQuestions && Object.keys(additionalQuestions).length > 0) {
      const initialAnswers = Object.entries(additionalQuestions).reduce((acc: { [key: string]: AdditionalQuestionAnswer }, [questionId, { answer, autoReject }]) => {
        acc[questionId] = { answer, autoReject };
        return acc;
      }, {});
      setAnswers(initialAnswers);
    }
  }, [additionalQuestions]);

  const handleChange = async (questionId: string, value: string, autoReject: boolean) => {
    setAnswers((prev) => ({
      ...prev,
      [questionId]: { answer: value, autoReject },
    }));
  
    if (candidateDetails?.jobApplicationId) {
      const updatedAnswers = Object.entries(answers).map(([questionId, { answer, autoReject }]) => ({
        questionId,
        answer,
        autoReject,
      }));
  
      updatedAnswers.push({
        questionId,
        answer: value,
        autoReject,
      });
  
      await dispatch(
        updateCandidateAdditionalQuestionsJobApply({
          jobApplicationId: candidateDetails.jobApplicationId,
          additionalQuestions: updatedAnswers,
        })
      );
    }
  };

  return (
    <ShPaper variant="outlined">
      {selectedJobForm ? (
        <Stack>
          {selectedJobForm.questions.map((question) => (
            <Box key={question.id} sx={{ marginBottom: 3 }}>
              <Typography variant="body2">
                {question.question}
                {question.required === "required" && <span style={{ color: "red" }}> *</span>}
              </Typography>

              {/* Textfield question type */}
              {question.type === "textfield" && (
                <ShTextFieldV2
                  size="small"
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Your answer"
                  required={question.required === "required"}
                  value={answers[question.id]?.answer || ""}
                  onChange={(e) => handleChange(question.id, e.target.value, false)} // no autoReject for text field
                />
              )}

              {/* Radio buttons question type */}
              {question.type === "radio" && (
                <RadioGroup
                  value={answers[question.id]?.answer || ""}
                  onChange={(e) => {
                    const selectedOption = question.options.find(option => option.label === e.target.value);
                    handleChange(question.id, e.target.value, selectedOption?.autoReject || false);
                  }}
                >
                  {question.options.map((option) => (
                    <FormControlLabel
                      key={option.label}
                      control={<Radio />}
                      label={option.label}
                      value={option.label}
                    />
                  ))}
                </RadioGroup>
              )}

              {/* Dropdown question type */}
              {question.type === "dropdown" && (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <ShTextFieldV2
                    select
                    fullWidth
                    size="small"
                    value={answers[question.id]?.answer || ""}
                    onChange={(e) => {
                      const selectedOption = question.options.find(option => option.label === e.target.value);
                      handleChange(question.id, e.target.value, selectedOption?.autoReject || false);
                    }}
                    required={question.required === "required"}
                  >
                    {question.options.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </ShTextFieldV2>
                  <FormHelperText>
                    {question.required === "required" ? "Required" : "Optional"}
                  </FormHelperText>
                </FormControl>
              )}
            </Box>
          ))}
        </Stack>
      ) : fetchAdditionalQuestionsStatus === "pending" ? (
        <Stack direction="row" spacing={1}>
          <LinearProgress />
          <Typography variant="body1" color="textSecondary">
            Loading form questions...
          </Typography>
        </Stack>
      ) : (
        <Typography variant="body1" color="error.main">
          Failed to load additional questions.
        </Typography>
      )}
    </ShPaper>
  );
};

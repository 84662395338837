import { IForm } from "../SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplateModel";

export interface EEOQuestion {
    id: string;   // unique ID for each question
    idx: number;  // index to represent the order of the question
    label: string;
    type: "text" | "radio" | "dropdown";
    options?: string[];
}

export interface EqualOpportunityQuestionsProps {
    eeoQuestions: number[]; // Array of question indices to be displayed (type number[])
}

export interface ApplyJobAdditionalQuestionsProps {
  selectedJobForm: IForm;
  onFormValidation: (isValid: boolean) => void
}


/* 
* IDX AND ID SHOULD NEVER CHANGE
*/
export const EEOQutionaareData: EEOQuestion[] = [
    {
      id: "gender", // unique id for the question
      idx: 1, // index for the question
      label: "What is your gender?",
      type: "radio",
      options: [
        "Male",
        "Female",
        "Non-Binary",
        "I do not wish to answer",
      ],
    },
    {
      id: "disability", // unique id for the question
      idx: 2, // index for the question
      label: "Do you identify as disabled?",
      type: "radio",
      options: [
        "Yes",
        "No",
        "I do not wish to answer",
      ],
    },
    {
      id: "race_ethnicity", // unique id for the question
      idx: 3, // index for the question
      label: "What is your race/ethnicity?",
      type: "dropdown",
      options: [
        "Hispanic or Latino",
        "White (not Hispanic or Latino)",
        "Black or African American",
        "Asian",
        "Native American or Alaska Native",
        "Native Hawaiian or Other Pacific Islander",
        "Two or more races",
        "I do not wish to answer",
      ],
    },
    {
      id: "veteran_status", // unique id for the question
      idx: 4, // index for the question
      label: "Veteran status (Check all that apply):",
      type: "radio",
      options: [
        "I am a veteran",
        "I am not a veteran",
        "I do not wish to answer",
      ],
    },
    {
      id: "sexual_orientation", // unique id for the question
      idx: 5, // index for the question
      label: "What is your sexual orientation?",
      type: "radio",
      options: [
        "Straight or Heterosexual",
        "Gay or Lesbian",
        "Bisexual",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "us_citizen", // unique id for the question
      idx: 6, // index for the question
      label: "Are you a U.S. citizen?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "additional_comments", // unique id for the question
      idx: 7, // index for the question
      label: "Please provide additional comments (optional):",
      type: "text",
    },
    {
      id: "transgender", // unique id for the question
      idx: 8, // index for the question
      label: "Do you identify as a transgender person?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "marital_status", // unique id for the question
      idx: 9, // index for the question
      label: "What is your marital status?",
      type: "radio",
      options: [
        "Single",
        "Married",
        "Divorced",
        "Widowed",
        "Domestic Partnership",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "age_group", // unique id for the question
      idx: 10, // index for the question
      label: "What is your age group?",
      type: "dropdown",
      options: [
        "Under 18",
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65 or older",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "education_level", // unique id for the question
      idx: 11, // index for the question
      label: "What is your highest level of education completed?",
      type: "dropdown",
      options: [
        "Some High School",
        "High School Diploma or Equivalent",
        "Some College",
        "Associate's Degree",
        "Bachelor's Degree",
        "Master's Degree",
        "Doctoral Degree",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "primary_language", // unique id for the question
      idx: 12, // index for the question
      label: "What is your primary language?",
      type: "dropdown",
      options: [
        "English",
        "Spanish",
        "Chinese",
        "Arabic",
        "French",
        "Hindi",
        "Other",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "accommodation_due_to_disability", // unique id for the question
      idx: 13, // index for the question
      label: "Do you require accommodations due to a disability?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "currently_employed", // unique id for the question
      idx: 14, // index for the question
      label: "Are you currently employed?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "employment_status", // unique id for the question
      idx: 15, // index for the question
      label: "What is your primary employment status?",
      type: "dropdown",
      options: [
        "Full-Time",
        "Part-Time",
        "Temporary",
        "Contract",
        "Self-Employed",
        "Unemployed",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "labor_union", // unique id for the question
      idx: 16, // index for the question
      label: "Are you a member of a labor union?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "disability_status", // unique id for the question
      idx: 17, // index for the question
      label: "What is your disability status?",
      type: "radio",
      options: [
        "I have a disability",
        "I do not have a disability",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "employment_type", // unique id for the question
      idx: 18, // index for the question
      label: "What is your employment type?",
      type: "dropdown",
      options: [
        "Employee",
        "Independent Contractor",
        "Freelancer",
        "Intern",
        "Apprentice",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "government_assistance", // unique id for the question
      idx: 19, // index for the question
      label: "Are you a participant in a government assistance program?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "reliable_transportation", // unique id for the question
      idx: 20, // index for the question
      label: "Do you have access to reliable transportation to work?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "speak_other_languages", // unique id for the question
      idx: 21, // index for the question
      label: "Do you speak any languages other than English?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "felony_conviction", // unique id for the question
      idx: 22, // index for the question
      label: "Have you ever been convicted of a felony?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
    {
      id: "mental_health_condition", // unique id for the question
      idx: 23, // index for the question
      label: "Have you ever been diagnosed with a mental health condition?",
      type: "radio",
      options: [
        "Yes",
        "No",
        
        "I do not wish to answer",
      ],
    },
];

import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { Badge, Box, Breadcrumbs, Divider, IconButton, Link, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { ShNotification } from "Modules/Core/Layouts/AdminLayout/AdminLayout.styled";
import { DashboardMenuItem } from 'Modules/Core/Layouts/AdminLayout/DashboardMenuItem';
import { Menus } from 'Modules/Core/Layouts/AdminLayout/DashboardMenus';
import { AppLayoutWrapper, LeftDrawerStyled, MainContainer, ParentContainerStyled, SideNavContainer, TopNavItemsContainerStyled } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { Notification } from 'Modules/Core/Notification';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect, useState } from "react";
import { Outlet, Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ShLogo } from 'shared/SharedComponents/ShLogo';
import ShRightDrawer from "shared/SharedComponents/ShRightDrawer/ShRightDrawer";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import UserAvatarBio from "shared/SharedComponents/UserAvatar/UserAvatarBio";
import { JobReviewsStates, RootState } from "shared/SharedModels";
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { LeftPanelContainer, MobileOnlyStack } from 'shared/SharedStyles/ShLayouts';
import { ShAppBar } from 'shared/SharedStyles/ShSurfaces';
import { WhiteColor } from "shared/SharedStyles/styleConstants";
import { AdminBreadcrumbNames } from "shared/constants";
import { getAdminJobs } from 'store/slices/admin/jobs-reviews-slice';
import { clearNotification } from "store/slices/admin/notifications-slice";
import { logoutAccount } from 'store/slices/auth-v2/auth-v2-slice';

export const AdminLayout = () => {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMiniDrawer, setIsMiniDrawer] = useState(false);
  const [isRightDrawerOpen, setIsRightDrawerOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const pathnames = useLocation().pathname.split('/').filter(path => path?.length > 0);
  const { notificationInfo, unreadNotifications } = useAppSelector((state: RootState) => state.admin.adminNotifications);
  const { approveJobApiStatus } = useAppSelector((state: RootState) => state.admin.jobReviews);

  const toggleDrawerHandler = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  // undo while commit
  // useEffect(() => {
  //   dispatch(getUnreadNotifications());
  //   const notificationInterval = setInterval(() => {
  //     dispatch(getLatestNotification());
  //   }, 5000);

  //   return () => clearInterval(notificationInterval);
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getAdminJobs({ state: JobReviewsStates.pending, pageNo: 1, pageSize: 20 }));
  }, [approveJobApiStatus, dispatch])

  useEffect(() => {
    setTimeout(() => {
      if (notificationInfo && notificationInfo.id) {
        dispatch(clearNotification(notificationInfo));
      }
    }, 5000);
  }, [notificationInfo, dispatch]);

  return (
    <ParentContainerStyled>
      {/* Snackbar type Notifications component */}
      <Notification />
      <ShAppBar position="sticky">
        <Toolbar>
          <Stack direction="row" alignContent="center" justifyContent="flex-start">
            <MobileOnlyStack>
              <IconButton size="large" edge="start" onClick={toggleDrawerHandler}>
                <MenuIcon />
              </IconButton>
            </MobileOnlyStack>
            <ShLogo link='/admin/pending-jobs' />
          </Stack>
          <TopNavItemsContainerStyled>
            <Tooltip title="Notifications">
              <IconButton size="large" onClick={() => setIsRightDrawerOpen(true)}>
                <Badge color='primary'>
                  <NotificationsOutlinedIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            {/* Button to change the theme */}
            <ThemeToggler />
            <Tooltip title="Log out">
              <IconButton size="large" onClick={() => dispatch(logoutAccount({ logoutType: 'user' }))}>
                <LogoutIcon className="logout-icon" />
              </IconButton>
            </Tooltip>
          </TopNavItemsContainerStyled>
          <MobileOnlyStack>
            {/* Button to change the theme */}
            <ThemeToggler />
            <IconButton size="large" edge="end" onClick={() => setIsRightDrawerOpen(true)}>
              <Badge color='primary'>
                <NotificationsOutlinedIcon />
              </Badge>
            </IconButton>
          </MobileOnlyStack>
        </Toolbar>
      </ShAppBar>
      {/* AppLayoutWrapper holds dynamically changing side nav and main outlet for components */}
      <AppLayoutWrapper>
        <LeftDrawerStyled anchor="left" open={isDrawerOpen} onClose={toggleDrawerHandler}>
          {/* Side nav wrapper switches the different side nav components based on the route */}
          <UserAvatarBio isMiniDrawer={isMiniDrawer} />
          <Divider />
          <DashboardMenuItem menus={Menus} isMiniDrawer={isMiniDrawer} />
        </LeftDrawerStyled>
        <SideNavContainer className={isMiniDrawer ? 'is-mini-drawer' : ''}>
          <LeftPanelContainer>
            <UserAvatarBio isMiniDrawer={isMiniDrawer} />
            <Divider />
            <DashboardMenuItem menus={Menus} isMiniDrawer={isMiniDrawer} setIsMiniDrawer={setIsMiniDrawer} />
          </LeftPanelContainer>
        </SideNavContainer>
        <ShRightDrawer isOpen={isRightDrawerOpen} onClose={() => { setIsRightDrawerOpen(false) }} title="Notifications">
          <Stack direction="column" p={1}>
            {unreadNotifications.map((notification) => {
              return (
                <ShNotification key={notification.id}>
                  <Typography color={WhiteColor} variant="body2">{notification.message}</Typography>
                  <Typography color={WhiteColor} variant="caption">{moment(notification.createdAt).fromNow()}</Typography>
                </ShNotification>
              )
            })}
          </Stack>
        </ShRightDrawer>
        <MainContainer>
          {/* Material Breadcrumbs component wraps around links with 'Home' as constant and dynamic path names.
          */}
          <Box className='breadcrumb-control'>
            <IconButton className='side-bar-tgl' size="medium" edge="start" onClick={() => setIsMiniDrawer(!isMiniDrawer)}>
              <MenuIcon />
            </IconButton>
            <Breadcrumbs className="breadcrumbs" separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              <Link underline='hover' color='inherit' variant='body2' component={RouterLink} to="/">Home</Link>
              {/* Color the last element to primary text color */}
              {pathnames.map((value, indx) => <Link key={indx} underline='hover'
                color={indx === pathnames?.length - 1 ? 'text.primary' : 'inherit'}
                variant='body2' component={RouterLink} to={pathnames.slice(1, indx + 1).join('/')}>
                {(AdminBreadcrumbNames[value as keyof typeof AdminBreadcrumbNames] ?? value) as string}
              </Link>)}
            </Breadcrumbs>
          </Box>
          {notificationInfo && !notificationInfo.isRead && <ShAlert severity="info">{notificationInfo.message}</ShAlert>}
          <Outlet />
        </MainContainer>
      </AppLayoutWrapper >
    </ParentContainerStyled>
  );
};
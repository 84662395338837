import { Stack, Typography } from "@mui/material"
import { useAppSelector } from "helpers/hooks"
import { RoleAccessControlMap } from "Modules/Core/AuthV2/AuthConstants"
import { Link as RouterLink } from 'react-router-dom'
import { ShMuiLink } from "shared/SharedStyles/ShNavigation"
import { DashboardFeatureTile } from "../../Dashboard.styled"
import { DashboardToolsFeatureTiles } from "../../DashboardConstants"

export const DashboardToolsTilesWidget = () => {

    const { accountAccess } = useAppSelector((state) => state.auth.auth);

    return (
                <Stack direction='row' columnGap={2} rowGap={2} flexWrap='wrap'>
                    {DashboardToolsFeatureTiles?.map((tile) => (
                        RoleAccessControlMap[accountAccess.role ?? 'employer'].featureMap
                            ?.[tile.featureName]?.visibility !== 'disabled' &&
                        <DashboardFeatureTile key={tile?.featureRedirectUrl}>
                            <ShMuiLink noUnderline component={RouterLink} to={tile.featureRedirectUrl}
                                display='flex' alignItems='center'
                                columnGap={1} noBlueHighlight paddingLeft={2}
                                paddingRight={1} paddingY={1} width='100%'>
                                {tile.tileIcon}
                                <Stack>
                                    <Stack direction='row' columnGap={1} alignItems='center'>
                                        {tile.chip}
                                        <Typography variant="body1">
                                            {tile.tileLabel}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="caption" color='gray'>
                                        {tile.caption}
                                    </Typography>
                                </Stack>
                            </ShMuiLink>
                        </DashboardFeatureTile>
                    ))}
                </Stack>
    )
}

import ClearIcon from '@mui/icons-material/Clear';
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Rating,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider, TimePicker, } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { InterviewDialogStyled } from 'Modules/Core/Interviews/Interviews.styled';
import {
  IUpdateInterviewStateDialog,
} from 'Modules/Core/Interviews/InterviewsModel';
import { useNotification } from 'Modules/Core/Notification';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from 'react';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import {
  ShButton,
  ShGreenBtn,
  ShGreenCheckbox,
  ShTextFieldV2,
} from 'shared/SharedStyles/ShInputs';
import { ratingLookup } from 'shared/constants';
import {
  resetUpdateInterviewStatus,
  updateInterviewCompleteStatus,
} from 'store/slices/employer/interviews/interviews-actions-slice';
import { getAccountList } from 'store/slices/employer/settingsTs/access-levels-slice';

const rolesLookup: Record<string, string> = {
  owner: 'Owner',
  hiring_manager: 'Hiring Manager',
  admin: 'Admin',
};

const UpdateInterviewCompleteStatusDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  interviewId,
  candidateName,
  status,
}: IUpdateInterviewStateDialog) => {
  const dispatch = useAppDispatch();
  const { accountList, getAccountListApiStatus } = useAppSelector(
    (state) => state.employer.employerSettings.accessLevels
  );
  const { updateInterviewStateStatus, updateInterviewStateResponse } =
    useAppSelector((state) => state.employer.interviews.interviewsActions);
  const notification = useNotification();

  const [completedDate, setCompletedDate] = useState(dayjs());
  const [completedTime, setCompletedTime] = useState(dayjs());
  const [feedback, setFeedback] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [selectedAccounts, setSelectedAccounts] = useState<number[]>([]);
  const [isCompleteInterviewDisabled, setIsCompleteInterviewDisabled] =
    useState(true);
  const [rating, setRating] = useState<number | null>(null);
  const [ratingHover, setRatingHover] = useState<number>(-1);

  useEffect(() => {
    if (isChecked) {
      dispatch(getAccountList());
    }

    if (!isChecked) {
      setSelectedAccounts([]);
    }
  }, [isChecked, dispatch]);

  useEffect(() => {
    const isValidFeedback = feedback.trim();
    const isValidDateAndTime =
      completedDate.isValid() && completedTime.isValid();
    // const isValidInterviewResult = interviewResult;
    const isValidShareFeedback = !isChecked || selectedAccounts.length > 0;
    const isValidRating = rating !== null;

    if (
      isValidFeedback &&
      isValidDateAndTime &&
      isValidShareFeedback &&
      isValidRating
    ) {
      setIsCompleteInterviewDisabled(false);
    } else {
      setIsCompleteInterviewDisabled(true);
    }
  }, [
    feedback,
    completedDate,
    completedTime,
    isChecked,
    selectedAccounts,
    rating,
  ]);

  useEffect(() => {
    if (updateInterviewStateStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: updateInterviewStateResponse,
      });
      dispatch(resetUpdateInterviewStatus());
      setIsDialogOpen(false);
    }
  }, [
    dispatch,
    notification,
    updateInterviewStateResponse,
    updateInterviewStateStatus,
    setIsDialogOpen,
  ]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleCompleteInterview = () => {
    const completedDateTime = dayjs(completedDate)
      .set('hour', completedTime.hour())
      .set('minute', completedTime.minute())
      .format('YYYY-MM-DD HH:mm:ss.SSS');

    const body = {
      completedDateTime,
      feedback,
      shareTo: selectedAccounts,
      status,
      rating: ratingLookup[rating!],
    };
    dispatch(
      updateInterviewCompleteStatus({
        id: interviewId,
        body,
      })
    );
  };

  const AlertForUpdateInterviewSchedule = () => {
    switch (updateInterviewStateStatus) {
      case 'pending':
        return (
          <ShAlert marginBottom={2} severity="info">
            Updating Interview status...
          </ShAlert>
        );
      case 'failed':
        return (
          <ShAlert marginBottom={2} severity="error">
            {updateInterviewStateResponse}
          </ShAlert>
        );
      default:
        return <></>;
    }
  };

  return (
    <InterviewDialogStyled
      open={isDialogOpen}
      onClick={(e) => e.stopPropagation()}
      aria-labelledby="title"
    >
      <DialogTitle id="title" textAlign="center" fontWeight={600}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box>{`Complete ${candidateName + `'s`} interview`}</Box>
          <IconButton onClick={handleClose}>
            <ClearIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        {AlertForUpdateInterviewSchedule()}
        <Stack gap={3}>
          <Stack>
            <Typography variant="body1" fontWeight={600} marginBottom={1}>
              Date and time of interview completion
            </Typography>
            <Stack direction="row" columnGap={3} maxWidth={450}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Select Date"
                  value={completedDate}
                  onChange={(newValue) =>
                    newValue && setCompletedDate(newValue)
                  }
                  slotProps={{ textField: { size: 'small' } }}
                />
                <TimePicker
                  label="Select Time"
                  value={completedTime}
                  onChange={(newValue) =>
                    newValue && setCompletedTime(newValue)
                  }
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Stack>
          </Stack>
          <Stack>
            <Typography variant="body1" fontWeight={600}>
              Rate the Interview
            </Typography>
            <Stack display="flex" alignItems="center" direction="row">
              <Rating
                value={rating}
                precision={1}
                onChange={(event, newValue) => {
                  setRating(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setRatingHover(newHover);
                }}
                getLabelText={(index) => ratingLookup[index] || ''}
                size="large"
              />
              {(rating !== null || ratingHover !== -1) && (
                <Stack ml={2}>
                  {ratingLookup[ratingHover !== -1 ? ratingHover : rating ?? 0]}
                </Stack>
              )}
            </Stack>
          </Stack>
          <ShTextFieldV2
            id="interview-feedback"
            label="Interview feedback"
            variant="outlined"
            multiline
            placeholder="Interview notes/feedback"
            rows={3}
            fullWidth
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
          />
          <Stack>
            <Stack direction="row" alignItems="center" columnGap={1}>
              <ShGreenCheckbox
                style={{ padding: 0 }}
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
              />
              <Typography variant="body1" fontWeight={600}>
                Share the interview feedback
              </Typography>
            </Stack>
            {isChecked && (
              <Stack marginTop={1}>
                <FormControl fullWidth>
                  <InputLabel id="select-label">Share feedback</InputLabel>
                  <Select
                    labelId="select-label"
                    label="Share feedback"
                    multiple
                    value={selectedAccounts}
                    onChange={(e) => {
                      if (getAccountListApiStatus === 'success') {
                        setSelectedAccounts(e.target.value as number[]);
                      }
                    }}
                    renderValue={(selected) =>
                      selected
                        .filter((selectedAccId) =>
                          accountList.some(
                            (acc) => acc.account_id === selectedAccId
                          )
                        )
                        .map(
                          (selectedAccId) =>
                            accountList.find(
                              (acc) => acc.account_id === selectedAccId
                            )?.fullName
                        )
                        .join(', ')
                    }
                  >
                    {getAccountListApiStatus === 'pending' && (
                      <Stack alignItems="center">
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          alignContent="center"
                        >
                          Loading...
                        </Typography>
                      </Stack>
                    )}
                    {getAccountListApiStatus === 'failed' && (
                      <Stack alignItems="center">
                        <ShButton
                          onClick={() => dispatch(getAccountList())}
                          variant="contained"
                          color="error"
                        >
                          Try again
                        </ShButton>
                      </Stack>
                    )}
                    {getAccountListApiStatus === 'success' &&
                      accountList
                        .filter((account) =>
                          Object.keys(rolesLookup).includes(account.role.name)
                        )
                        .map((account) => (
                          <MenuItem key={account.id} value={account.account_id}>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <Typography variant="body2">
                                {account.fullName}
                              </Typography>
                              <Typography variant="body2" color="textSecondary">
                                {rolesLookup[account.role.name]}
                              </Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
              </Stack>
            )}
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={1}>
          <ShButton onClick={handleClose} variant="contained">
            Cancel
          </ShButton>
          <ShGreenBtn
            variant="contained"
            disableElevation
            size="small"
            onClick={handleCompleteInterview}
            disabled={isCompleteInterviewDisabled}
          >
            Complete Interview
          </ShGreenBtn>
        </Stack>
      </DialogActions>
    </InterviewDialogStyled>
  );
};

export default UpdateInterviewCompleteStatusDialog;

import {
    Grid,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    Box,
    Stack,
    LinearProgress,
    FormControlLabel,
    Fade,
    Tooltip,
    DialogTitle,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useSelector } from 'react-redux';
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShReactQuill } from "shared/SharedComponents/ShReactQuill/ShReactQuill";
import { ShButton, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ILetterTemplate, LetterPresetsProps, TEMPLATE_TYPE_LABELS } from "./LettersModel";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { RootState } from "shared/SharedModels";
import { addRemoveOrUpdateEmployerLetterTemplates, getEmployerLettersTemplates } from "store/slices/employer/employer/employer-details-slice";
import { useAppDispatch } from "helpers/hooks";
import { Star as StarIcon } from "@mui/icons-material";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import React from "react";
import { sortTemplates } from "./LetterHelpers";

export const LetterPresets: React.FC<LetterPresetsProps> = ({ templateType }) => {
    const dispatch = useAppDispatch();
    const { employerLetters, getLettersStatus, employerDetails } = useSelector(
        (state: RootState) => state.employer.employerDetails
    );

    const [localOfferLetters, setLocalOfferLetters] = useState<ILetterTemplate[]>(employerLetters || []);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [templateName, setTemplateName] = useState("");
    const [templateDesc, setTemplateDesc] = useState("");
    const [subject, setSubject] = useState("");
    const [emailBodyEditorValue, setEmailBodyEditorValue] = useState<string>("");
    const [ccEmails, setCcEmails] = useState<string>("");
    const [preferred, setPreferred] = useState(false);
    const [editingId, setEditingId] = useState<number | null>(null);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const quillRef = useRef<any>(null);
    const [errors, setErrors] = useState({
        templateName: false,
        templateDesc: false,
        subject: false,
        emailBody: false,
    });

    useEffect(() => {
        if (employerLetters) {
            const filteredTemplates = employerLetters.filter(
                template => template.templateType === templateType
            );
            const sortedTemplates = sortTemplates(filteredTemplates);
            setLocalOfferLetters(sortedTemplates);
        }
    }, [employerLetters, templateType]);

    useEffect(() => {
        if (employerDetails?.id) {
            dispatch(getEmployerLettersTemplates({ empId: employerDetails?.id }));
        }
    }, [dispatch, employerDetails?.id]);

    const validateForm = () => {
        const newErrors = {
            templateName: !templateName.trim(),
            templateDesc: !templateDesc.trim(),
            subject: !subject.trim(),
            emailBody: !emailBodyEditorValue.trim(),
        };
        setErrors(newErrors);
        return Object.values(newErrors).every(value => !value);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setTemplateName("");
        setTemplateDesc("");
        setSubject("");
        setEmailBodyEditorValue("");
        setCcEmails("");
        setPreferred(false);
        setEditMode(false);
        setEditingId(null);
        setErrors({
            templateName: false,
            templateDesc: false,
            subject: false,
            emailBody: false,
        });
    };

    const handleAddTemplate = () => {
        if (validateForm()) {
            const newTemplate: ILetterTemplate = {
                id: Date.now(),
                dateCreated: new Date().toLocaleDateString(),
                templateName,
                templateDesc,
                subject,
                emailBody: emailBodyEditorValue,
                ccEmails: ccEmails.trim(),
                isPreferred: preferred,
                templateType,
            };

            if (preferred) {
                const updatedTemplates = localOfferLetters.map(template => ({
                    ...template,
                    isPreferred: false
                }));
                setLocalOfferLetters([newTemplate, ...updatedTemplates]);
            } else {
                setLocalOfferLetters((prev) => [...prev, newTemplate]);
            }

            if (employerDetails?.id) {
                dispatch(addRemoveOrUpdateEmployerLetterTemplates({
                    empId: employerDetails?.id,
                    action: 'add',
                    template: newTemplate
                }));
            }

            setAlertMessage(`${templateType} Template added successfully!`);
            setAlertOpen(true);

            handleDialogClose();
        }
    };

    const handleEditTemplate = () => {
        if (validateForm() && editingId !== null) {
            const updatedTemplate: ILetterTemplate = {
                id: editingId,
                dateCreated: new Date().toLocaleDateString(),
                templateName,
                templateDesc,
                subject,
                emailBody: emailBodyEditorValue,
                ccEmails: ccEmails.trim(),
                isPreferred: preferred,
                templateType,
            };

            if (preferred) {
                const updatedTemplates = localOfferLetters.map(template => ({
                    ...template,
                    isPreferred: false
                }));
                setLocalOfferLetters(updatedTemplates.map(template => template.id === editingId ? updatedTemplate : template));
            } else {
                setLocalOfferLetters((prev) => prev.map(template => template.id === editingId ? updatedTemplate : template));
            }

            if (employerDetails?.id) {
                dispatch(addRemoveOrUpdateEmployerLetterTemplates({
                    empId: employerDetails?.id,
                    action: 'update',
                    template: updatedTemplate,
                }));
            }

            setAlertMessage(`${templateType} Template updated successfully!`);
            setAlertOpen(true);

            handleDialogClose();
        }
    };

    const handleDeleteTemplate = (id: number, event: React.MouseEvent) => {

        event.stopPropagation();
        setLocalOfferLetters((prev) => prev.filter(template => template.id !== id));

        if (employerDetails?.id) {
            dispatch(addRemoveOrUpdateEmployerLetterTemplates({
                empId: employerDetails?.id,
                action: 'remove',
                template: {
                    id,
                    dateCreated: "",
                    templateName: "",
                    templateDesc: "",
                    subject: "",
                    emailBody: "",
                    isPreferred: false,
                    ccEmails: "",
                    templateType,
                },
            }));
        }

        setAlertMessage(`${templateType} Template deleted successfully!`);
        setAlertOpen(true);
    };

    const handleEditClick = (id: number) => {
        const templateToEdit = localOfferLetters.find((template) => template.id === id);
        if (templateToEdit) {
            setEditingId(id);
            setTemplateName(templateToEdit.templateName);
            setTemplateDesc(templateToEdit.templateDesc);
            setSubject(templateToEdit.subject);
            setEmailBodyEditorValue(templateToEdit.emailBody);
            setCcEmails(templateToEdit.ccEmails || "");
            setPreferred(templateToEdit.isPreferred);
            setEditMode(true);
            setDialogOpen(true);
        }
    };
    
    const insertPlaceholder = (placeholder: string) => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();  
            const cursorPosition = quill.getSelection()?.index || 0;
            const textWithSpace = placeholder + " ";  
            quill.insertText(cursorPosition, textWithSpace);
            quill.setSelection(cursorPosition + textWithSpace.length);
        }
    };

    return (
        <>
            {alertOpen &&
                <Box padding={2}>
                    <ShAlert onClose={() => setAlertOpen(false)} severity="success">
                        {alertMessage}
                    </ShAlert>
                </Box>
            }
            <Fade in={true} timeout={800}>
                <Stack>
                        <ShPaper variant="outlined" headerSection borderRadius={0}>
                            <Typography variant="subtitle1" gutterBottom paddingLeft={2}>
                                {TEMPLATE_TYPE_LABELS[templateType]?.label}
                            </Typography>
                            <Typography variant="caption" color="textSecondary" paddingLeft={2}>
                                {TEMPLATE_TYPE_LABELS[templateType]?.caption}
                            </Typography>
                        </ShPaper>

                        {TEMPLATE_TYPE_LABELS[templateType].label === 'Application Confirmation Letter' &&
                            <Stack pb={1} pt={1}>
                                <ShAlert severity="info">
                                    <Typography variant="subtitle2">The selected preferred application template is the one that will be used for future applicants.
                                     Any new applicants will see this template when they apply to your job. 
                                     You can switch back to the default template at any time by unselecting your preference.</Typography>
                                </ShAlert>
                            </Stack>
                        }

                    <ShPaper variant="outlined" noBorderTop borderRadius={0}>
                        <Grid container spacing={2} padding={2}>
                            {getLettersStatus === 'pending' ? (
                                <Box width={'100%'} padding={2}>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                localOfferLetters.map((template: ILetterTemplate) => (
                                    <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={template.id} onClick={() => handleEditClick(template.id)}>
                                        <ShPaper transElevateOnHover variant="outlined"   
                                            sx={{ 
                                                border: template.isPreferred ? '2px solid #74C05A' : '', 
                                                cursor: 'pointer', 
                                            }}>
                                            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent='space-between' spacing={2} paddingBottom={1}>
                                                <Box maxWidth={175}>
                                                    <ShChip size="small" label={template.templateName} />
                                                </Box>
                                                {template.isPreferred && (
                                                    <Box maxWidth={150}>
                                                        <ShChip size="small" label="Preferred" color="success" textColor="white" borderRadius="15px" icon={<StarIcon color="success" />}/>
                                                    </Box>
                                                )}
                                            </Stack>
                                            <Typography variant="body2" color="textSecondary">
                                                {template.templateDesc}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                <strong>Subject:</strong> {template.subject}
                                            </Typography>
                                            <Typography variant="caption" color="textSecondary">
                                                Created on: {template.dateCreated}
                                            </Typography>
                                            <Stack direction='row' justifyContent="space-between">
                                                    <IconButton onClick={() => handleEditClick(template.id)} color="primary">
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton onClick={(event) => handleDeleteTemplate(template.id, event)} color="error">
                                                        <DeleteIcon />
                                                    </IconButton>
                                            </Stack>
                                        </ShPaper>
                                    </Grid>
                                ))
                            )}
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Box height={175} display="flex" alignItems="center" justifyContent="center" border="2px dashed #74C05A">
                                    <IconButton onClick={handleDialogOpen} sx={{ color: "green", fontSize: 35 }}>
                                        <AddIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            </Grid>
                        </Grid>

                        <ShDialog open={dialogOpen} onClose={handleDialogClose} maxWidth='md'>
                            <ShPaper headerSection  borderRadius={0}>
                                <DialogTitle>
                                    <Box display="flex" alignItems="center">
                                        {editMode ? <EditIcon color='primary' fontSize="small" /> : <AddIcon color='primary' fontSize="small" />}
                                        <Typography variant="h6" component="div" marginLeft={1}>
                                            {editMode 
                                                ? `Edit ${TEMPLATE_TYPE_LABELS[templateType]?.label} Template` 
                                                : `Add ${TEMPLATE_TYPE_LABELS[templateType]?.label} Template`}
                                        </Typography>
                                    </Box>
                                </DialogTitle>
                            </ShPaper>
                            <ShPaper variant="outlined" borderRadius={0} noBorderTop>
                                <DialogContent>
                                    <Stack spacing={2}>
                                        <ShTextFieldV2
                                            label="Template Name" 
                                            fullWidth 
                                            value={templateName} 
                                            onChange={(e) => setTemplateName(e.target.value)} 
                                            error={errors.templateName}
                                            helperText={errors.templateName && "Template name is required."}
                                        />
                                        <ShTextFieldV2 
                                            isResizable
                                            label="Template Description" 
                                            fullWidth 
                                            multiline rows={3} 
                                            value={templateDesc} 
                                            onChange={(e) => setTemplateDesc(e.target.value)}
                                            error={errors.templateDesc}
                                            helperText={errors.templateDesc && "Template description is required."}
                                        />
                                        <ShTextFieldV2 
                                            label="Subject" 
                                            fullWidth 
                                            value={subject} 
                                            onChange={(e) => setSubject(e.target.value)}
                                            error={errors.subject}
                                            helperText={errors.subject && "Subject is required."}
                                        />
                                        <ShTextFieldV2 
                                            label="CC Emails" 
                                            fullWidth 
                                            value={ccEmails} 
                                            onChange={(e) => setCcEmails(e.target.value)} 
                                        />
                                        <FormControlLabel
                                            control={<ShSwitch checked={preferred} onChange={() => setPreferred(!preferred)} />}
                                            label={<Typography variant="subtitle1" ml={1}> Set as Preferred</Typography>}
                                        />
                                        <Typography variant="body2" color="textSecondary" gutterBottom>
                                            Email Body
                                        </Typography>
                                        <ShReactQuill
                                            ref={quillRef}
                                            quillEditorValue={emailBodyEditorValue}
                                            setQuillEditorValue={setEmailBodyEditorValue}
                                            error={errors.emailBody}
                                        />
                                    </Stack>
                                    <Stack direction="row" spacing={2} mt={2}>
                                        <Tooltip placement='left' title="Inserts the candidate's name into the email. This will be replaced with the candidate's actual name when the template is processed.">
                                            <ShButton variant="outlined" 
                                                onClick={() => insertPlaceholder('[CANDIDATE_NAME]')}>
                                                Insert [CANDIDATE_NAME]
                                            </ShButton>
                                        </Tooltip>
                                        <Tooltip placement='bottom' title="Inserts the company name into the email. This will be replaced with the company's actual name when the template is processed.">
                                            <ShButton variant="outlined" 
                                                onClick={() => insertPlaceholder('[COMPANY_NAME]')}>
                                                Insert [COMPANY_NAME]
                                            </ShButton>
                                        </Tooltip>
                                        <Tooltip placement='bottom' title="Inserts the job title into the email. This will be replaced with the job's actual title when the template is processed.">
                                            <ShButton variant="outlined" 
                                                onClick={() => insertPlaceholder('[JOB_NAME]')}>
                                                Insert [JOB_NAME]
                                            </ShButton>
                                        </Tooltip>
                                    </Stack>
                                </DialogContent>
                            </ShPaper>
                            <DialogActions>
                                <ShButton onClick={handleDialogClose} color="info">
                                    Cancel
                                </ShButton>
                                <ShButton onClick={editMode ? handleEditTemplate : handleAddTemplate} color="primary" variant="contained">
                                    {editMode ? "Save Changes" : "Add"}
                                </ShButton>
                            </DialogActions>
                        </ShDialog>
                    </ShPaper>
                </Stack>
            </Fade>
        </>
    );
};

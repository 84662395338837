import { Typography, Grid, Stack, Divider, Badge, Checkbox, Box, Fade } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import CheckIcon from '@mui/icons-material/Check';
import { ShButton, ShGreenBtn } from 'shared/SharedStyles/ShInputs';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { useEffect, useRef, useState } from 'react';
import StarIcon from '@mui/icons-material/Star'; // Star icon
import { useNotification } from 'Modules/Core/Notification';
import { useAppDispatch, IsXsScreen, useAppSelector } from 'helpers/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiState } from 'shared/SharedModels';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { DefaultAPIErrorMsg } from 'shared/constants';
import {
    getInventoryBundles,
    setSelectedAddOnBundles,
    resetAddToCart,
    setTotalJobCost,
    setSelectedBaseBundle,
    addJobToCart,
    getAllBundles,
    resetBundles,
} from 'store/slices/employer/create-job/create-job-payments-slice';
import { setIsPaymentEnabled } from 'store/slices/employer/create-job/create-job-shared-slices';
import { IBundle } from '../CreateJobModel';
import { ConfirmationDialog } from 'shared/SharedComponents/ConfirmationDialog/ConfirmationDialog';
import { JobValidity } from '../CreateJobConstants';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { updateArrayById } from 'shared/utils';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

export const Bundles2 = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { displayNotification } = useNotification();
    const { jobId } = useParams();
    const bundlesStackRef = useRef<HTMLDivElement>(null);
    const isXsScreen = IsXsScreen();
    const { allBundles, allBaseBundles, allAddOnBundles, inventoryBundles, getAllBundlesResponse, getAllBundlesStatus, getInventoryBundlesResponse,
        addJobToCartStatus, addJobToCartResponse, getInventoryBundlesStatus, selectedBaseBundle, selectedAddOnBundles,
} = useAppSelector((state) => state.employer.createJob.createJobPayments);
    const { currentJobCreationFlow } = useAppSelector((state) => state.employer.createJob.createJobShared);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const [_selectedBaseBundle, _setSelectedBaseBundle] = useState<number>(0);
    const [_selectedAddOnBundles, _setSelectedAddOnBundles] = useState<number[]>([]);
    const [_totalJobCost, _setTotalJobCost] = useState<number>(0);
    const [openNoBundleSelectedDialog, setOpenNoBundleSelectedDialog] = useState<boolean>(false);
    const [isBackClicked, setIsBackClicked] = useState<boolean>(false);

      // Function to handle selecting a base bundle
      const handleBaseBundleSelect = (bundleId: number) => {
        _setSelectedBaseBundle(bundleId === _selectedBaseBundle ? 0 : bundleId); // Toggle selection on click
        // _setSelectedBaseBundle(Number(bundleId))
    };

    // Function to handle selecting an addon bundle
    const handleAddOnBundleSelect = (addonId: number) => {
        _setSelectedAddOnBundles((prevSelected) => {
            if (prevSelected.includes(addonId)) {
                return prevSelected.filter((id) => id !== addonId);
            } else {
                return [...prevSelected, addonId];
            }
        });
    };

    // scroll to the top of bundles on loading this page.
    useEffect(() => {
        bundlesStackRef?.current?.scrollIntoView();
    }, []);

    // Update selected bundles if previously selected.
    useEffect(() => {
        if (selectedBaseBundle !== undefined) {
            _setSelectedBaseBundle(selectedBaseBundle);
        }
    }, [selectedBaseBundle]);

    useEffect(() => {
        if (selectedAddOnBundles?.length > 0) {
            _setSelectedAddOnBundles(selectedAddOnBundles);
        }
    }, [selectedAddOnBundles]);

    // Fetch all bundles on page load.
    useEffect(() => {
        if (allBundles.length === 0) {
            dispatch(getAllBundles());
        }
        // reset on unmount
        return () => {
            dispatch(resetBundles());
        }
    }, [allBundles.length, dispatch]);

    // Fetch inventory bundles on page load.
    useEffect(() => {
        if (inventoryBundles?.length === 0) {
            dispatch(getInventoryBundles());
        }
    }, [dispatch, inventoryBundles?.length]);

    useEffect(() => {
        if (employerDetails?.free_trail_signup && allAddOnBundles?.length > 0) {
            // Filtering LinkedIn for free trial.
            const addOnBundleIds = allAddOnBundles.filter(ab => ab.name !== 'LinkedIn').map(ab => ab.id);
            _setSelectedAddOnBundles([...addOnBundleIds]);
            dispatch(setSelectedAddOnBundles([...addOnBundleIds]));
        }
    }, [allAddOnBundles, dispatch, employerDetails?.free_trail_signup]);

    // Calculate total on selected bundles change.
    useEffect(() => {
        let totalCost = 0;
        [_selectedBaseBundle, ..._selectedAddOnBundles].forEach(bId => {
            // Count total only if the selected bundle is not present in inventory.
            if (inventoryBundles.find(iB => iB.bundle_id === bId) === undefined) {
                totalCost += allBundles.find(b => b.id === bId)?.base_price ?? 0
            }
        });
        // const atsId = allBundles.find(bundles => bundles.name === 'Basic Job Posting Package')?.id;
        // if (atsId) {
        //     setIsAtsSelected(_selectedBundles?.includes(atsId))
        // }
        _setTotalJobCost(totalCost);
        // Update state if all bundles are unselected     
        if (_selectedAddOnBundles?.length === 0) {
            dispatch(setSelectedAddOnBundles([]));
        }
    }, [_selectedAddOnBundles, _selectedBaseBundle, allBundles, dispatch, inventoryBundles]);

    // Go to payments only if job is added to cart.
    useEffect(() => {
        if (addJobToCartStatus === 'success') {
            displayNotification({
                open: true,
                type: 'success',
                message: addJobToCartResponse ?? ''
            });
            dispatch(resetAddToCart());
            dispatch(setIsPaymentEnabled(true));
            // dispatch(getAllBundles());
            // dispatch(getInventoryBundles());
            if (isBackClicked) {
                if (currentJobCreationFlow === 'useTemplateJob') {
                    navigate(`/employer/job/${jobId}/title`);
                } else {
                    navigate(`/employer/job/${jobId}/preview`);
                }
            } else {
                navigate(`/employer/job/${jobId}/payment`);
            }
        }
    }, [addJobToCartResponse, addJobToCartStatus, currentJobCreationFlow, dispatch, displayNotification, isBackClicked, jobId, navigate]);

    const getPricingLabel = (bundle: IBundle) => {
        const bundleInInventory = inventoryBundles?.find(ib => ib.bundle_id === bundle.id);
        return (<>
            {
                bundleInInventory ?
                    <>
                        {`1 of ${bundleInInventory?.quantity} credit${bundleInInventory?.quantity > 1 ? 's' : ''}`}
                    </>:
                    bundle.base_price > 0 &&
                    <>
                        ${bundle.base_price}
                    </>
            }
        </>);
    };

    const showFailureAlerts = (apiState: ApiState, msg: string, apiPendingMsg: string) => {
        switch (apiState) {
            case 'pending':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="info">{apiPendingMsg}</ShAlert>
                    </Box>
                </>);
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    const continueToPayment = () => {
        dispatch(setTotalJobCost(_totalJobCost));
        if (_selectedBaseBundle) {
            dispatch(setSelectedBaseBundle(_selectedBaseBundle));
        }
        dispatch(setSelectedAddOnBundles(_selectedAddOnBundles));

        // Only add to cart if a job is selected, 
        // otherwise since the continue to payment is called on JobBundlesBack, go back to preview.
        if (_selectedBaseBundle) {
            dispatch(addJobToCart({ jobId: parseInt(jobId ?? ''), payload: { bundles: [_selectedBaseBundle, ..._selectedAddOnBundles] } }));
        } else if (!_selectedBaseBundle) {
            if (currentJobCreationFlow === 'useTemplateJob') {
                navigate(`/employer/job/${jobId}/title`);
            } else {
                navigate(`/employer/job/${jobId}/preview`);
            }
        }
    };

    const onJobBundlesBack = () => {
        setIsBackClicked(true);
        continueToPayment();
    }

  return (
    <>
    {showFailureAlerts(getAllBundlesStatus, getAllBundlesResponse, 'Loading Bundles...')}
    {showFailureAlerts(getInventoryBundlesStatus, getInventoryBundlesResponse, 'Loading Inventory Bundles...')}
    {showFailureAlerts(addJobToCartStatus ?? 'idle', addJobToCartResponse ?? DefaultAPIErrorMsg, 'Adding Job to Cart...')}
    <Fade in={true} timeout={800}>
        <Stack direction='row' ref={bundlesStackRef}>
            <Stack>
                {/* Vertical Cards for Bundles */}
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <LocalOfferOutlinedIcon color='primary'/>
                        <Typography variant='body1'>Bundles</Typography>
                    </Stack>
                    {!employerDetails?.free_trail_signup &&
                    <Stack padding={.5}>
                      <ShPaper variant='outlined'>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <ShoppingCartOutlinedIcon color='primary' />
                          <Typography variant="body2" whiteSpace='nowrap' fontWeight={600}>
                            Total: ${_totalJobCost}
                          </Typography>
                        </Stack>
                      </ShPaper>
                    </Stack>
                    }
                </Stack>
                <Divider/>
                <Typography variant='body1' p={1} gutterBottom>Choose your Base Bundles</Typography>
                <Grid container columnGap={2}>
                    {allBaseBundles.map((bundle) => {
                        const combinedFeatures = [
                            ...(bundle.features_included?.split(',').map(feature => ({ feature: feature.trim(), included: true })) || []),
                            ...(bundle.features_excluded?.split(',').map(feature => ({ feature: feature.trim(), included: false })) || [])
                        ];

                        return (
                            <Grid item xs={12} md={3.5} key={bundle.id}>
                                <Badge
                                    color="primary"
                                    badgeContent={
                                        bundle.is_featured ? (
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <StarIcon color='inherit' fontSize="small" />
                                                <Typography variant="caption" fontWeight="bold">
                                                    RECOMMENDED
                                                </Typography>
                                            </Stack>
                                        ) : null
                                    }
                                    invisible={!bundle.is_featured}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    sx={{ 
                                        "& .MuiBadge-badge": 
                                            { fontSize: 12, height: 25, minWidth: 150, borderRadius:35, ml:10} 
                                    }}>
                                    <ShPaper 
                                        minHeight={600}
                                        sx={{ 
                                            display: 'flex', flexDirection: 'column',
                                            border: Number(_selectedBaseBundle) === bundle.id ? '3px solid #74C05A' : '',
                                        }}
                                        variant='outlined'  
                                        transElevateOnHover 
                                        cursor='pointer'
                                        borderRadius={15}
                                        onClick={() => handleBaseBundleSelect(bundle.id)}>
                                        <Stack spacing={2} flexGrow={2}>
                                            <Typography fontSize={18} component="div" gutterBottom>
                                                {bundle.name}
                                            </Typography>
                                            {bundle.base_price > 0 &&
                                                <Stack direction='row' pb={1} alignItems="baseline">
                                                    <Typography variant="h4" sx={{fontSize:'18px'}} fontWeight='bold'>
                                                        {!employerDetails?.free_trail_signup && getPricingLabel(bundle)}
                                                    </Typography>
                                                    <Typography variant="caption" fontWeight='bold' ml={1}>
                                                        /per job
                                                    </Typography>
                                                </Stack>
                                            }
                                            <Typography variant="body2" color="text.secondary" gutterBottom>
                                                {bundle.description}
                                            </Typography>
                                            <Stack alignItems='center'>
                                                <ShChip color='primary' bgColor='#4CAF50' textColor='#FFFFFF' label='Features' maxWidth='150px' size='small' borderRadius='0px'/>
                                                <ShPaper variant='outlined' borderRadius={15}>
                                                    <Grid container spacing={2}>
                                                        {combinedFeatures.map((item, index) => (
                                                            <Grid item xs={12} sm={6} key={index}>
                                                                <Stack direction="row" alignItems="center">
                                                                    {item.included ? (
                                                                        <CheckIcon color="success" />
                                                                    ) : (
                                                                        <CloseOutlinedIcon color="error" />
                                                                    )}
                                                                    <Typography ml={1} fontSize={12}>
                                                                        {item.feature}
                                                                    </Typography>
                                                                </Stack>
                                                                <Divider orientation='vertical' flexItem />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </ShPaper>
                                            </Stack>
                                        </Stack>
                                        <Stack pt={2} color='white'>
                                            <ShButton textColor='white' color='success' size='small' variant='contained' fullWidth startIcon={<AddShoppingCartIcon />} 
                                                onClick={() => handleBaseBundleSelect(bundle.id)}>
                                                Add to cart
                                            </ShButton>
                                        </Stack>
                                    </ShPaper>
                                </Badge>
                            </Grid>
                        );
                    })}
                </Grid>
                {/* Horizontal Cards for Addons */}
                <Stack direction='row' spacing={1} alignItems='center' p={0.5}>
                    <LibraryAddOutlinedIcon color='primary'/>
                    <Typography variant='body1' pt={1}> Add ons </Typography>
                </Stack>
                <Divider/>
                <Typography variant='body1' p={1} gutterBottom>Select your job posting packages</Typography>
                <Stack>
                <Grid container rowGap={2}>
                    {allAddOnBundles.map((addon) => (
                        <Grid item xs={12} key={addon.id}>
                            <ShPaper 
                            sx={{
                                border: _selectedAddOnBundles.includes(addon.id) ? '2px solid #74C05A' : ''
                            }}
                            variant="outlined" borderRadius={15} transElevateOnHover 
                            onClick={() => {
                                handleAddOnBundleSelect(addon.id);
                                _setSelectedAddOnBundles(updateArrayById<number, number>(addon.id, _selectedAddOnBundles));
                            }}>
                            <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between'>
                                {/* Checkbox for selecting addon */}
                                <Checkbox 
                                    disabled={!_selectedBaseBundle}
                                    onChange={() => _setSelectedAddOnBundles(updateArrayById<number, number>(addon.id, _selectedAddOnBundles))}
                                    checked={_selectedAddOnBundles?.includes(addon.id)}
                                    color="primary" 
                                />
                                <Stack flex={1} mx={2}> {/* Allow the inner content to take up remaining space */}
                                    <Stack direction='row' spacing={2} alignItems='center'>
                                        <Typography variant="body2" component="div">
                                            {addon.name}
                                        </Typography>
                                        <Typography variant="body1" color="primary">
                                            {!employerDetails?.free_trail_signup && getPricingLabel(addon)}
                                        </Typography>
                                    </Stack>
                                    <Typography variant="caption" color="text.secondary">
                                        {addon.description}
                                    </Typography>
                                </Stack>
                                <Stack direction='row' spacing={1.5}>
                                    {addon.features_included?.split(',').map((feature, index) => (
                                        <Stack direction="row" alignItems="center" key={index} justifyContent='flex-end'>
                                            <CheckIcon color="success" />
                                            <Typography ml={1} mr={1} fontSize={13}>
                                                {feature.trim()} 
                                            </Typography>
                                            {addon.features_included && index < addon.features_included?.split(',').length - 1 && (
                                                <Divider orientation='vertical' />
                                            )}
                                        </Stack>
                                    ))}
                                        <ShButton textColor='white' color='success' size='small' variant='contained' startIcon={<AddShoppingCartIcon />} 
                                        onClick={() => handleAddOnBundleSelect(addon.id)}
                                    >
                                        Add to cart
                                    </ShButton>
                                </Stack>    
                            </Stack> 
                            </ShPaper>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
                {!employerDetails?.free_trail_signup &&
                    <Stack flexDirection='row' alignItems='center' columnGap={0.5} m={2}>
                        <InfoOutlinedIcon fontSize="small" color='disabled' />
                        <Typography variant="caption" display='flex' alignItems='center'>
                            Jobs active for {JobValidity} days
                        </Typography>
                    </Stack>}
                <Stack flexDirection='row' justifyContent='space-between' alignItems='center'
                        columnGap={1} rowGap={1} mb={3} flexWrap='wrap'>
                        <ShButton variant='contained' size='small' disableElevation onClick={onJobBundlesBack}>Back</ShButton>
                        {/* <ShButton startIcon={<BalanceIcon />} onClick={() => dispatch(togglePricingDialog())}>Full Comparison</ShButton> */}
                        {!employerDetails?.free_trail_signup &&
                            <Typography variant="body2" whiteSpace='nowrap' fontWeight={600}>Total: ${_totalJobCost}</Typography>}

                    <ShGreenBtn disableElevation variant='contained' className='continue-btn' disabled={!_selectedBaseBundle}
                        onClick={() => {
                            _selectedAddOnBundles.length === 0 ? setOpenNoBundleSelectedDialog(true) :
                                continueToPayment();
                        }}>Continue</ShGreenBtn>
                </Stack>
            </Stack>
            <Stack>
                {/* Track current selections and price */}
            </Stack>
            <ConfirmationDialog onCancel={() => setOpenNoBundleSelectedDialog(false)}
                onConfirm={continueToPayment} confirmButtonLabel="Continue" cancelButtonLabel="Back"
                isDialogOpen={openNoBundleSelectedDialog} title={"WHOA!"}
                contentText="No job posting package selected. If you're not looking to post or promote a job, 
            click 'Continue', Otherwise, please click 'back' to select a package"></ConfirmationDialog>
        </Stack>
    </Fade> 
    </>
  );
};

export default Bundles2;

import { ApplicantsSortCols, IApplicantsFilter, IRejectionEmailPayload, IStageFolder, TInterviewStage } from "Modules/Core/Applicants/ApplicantsModel";
import { ILabelValueBase, ITableHeadersBase } from "shared/SharedModels";

export const ApplicantsHeaders: ITableHeadersBase<ApplicantsSortCols>[] = [
    { columnName: 'first_name', label: 'Name' },
    { columnName: 'created_at', label: 'Date' },
    { columnName: 'rating', label: 'Rating' }
];

export const AiApplicantsHeaders: ITableHeadersBase<ApplicantsSortCols>[] = [
    { columnName: 'first_name', label: '' },
    { columnName: 'created_at', label: 'Date' },
];

export const ApplicantStages: ILabelValueBase<string, Lowercase<TInterviewStage>>[] = [
    { label: 'New', value: 'new' },
    //{ label: 'Reviewed', value: 'reviewed' },
    { label: 'Not Qualified', value: 'not_qualified' },
    { label: 'Phone Screening', value: 'phone_screening' },
    { label: 'Interview', value: 'interview' },
    { label: 'Final Interview', value: 'second_interview' },
    { label: 'Job Offered', value: 'offer' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Hired', value: 'hired' },
    { label: 'Auto Rejected', value: 'auto_rejected' }
];

export const ApplicantsFiltersMap: IApplicantsFilter = {
    stages: [
        { label: 'New', value: 'new' },
        //{ label: 'Reviewed', value: 'reviewed' },
        { label: 'Not qualified', value: 'not_qualified' },
        { label: 'Phone Screening', value: 'phone_screening' },
        { label: 'Interview', value: 'interview' },
        { label: 'Second Interview', value: 'second_interview' },
        { label: 'Rejected', value: 'rejected' },
        { label: 'Hired', value: 'hired' },
        { label: 'Offer', value: 'offer' },
    ],
    fit: [
        { label: 'Strong Fit', value: 'strong', matchTypeKey: 'STRONG' },
        { label: 'Good Fit', value: 'match,good', matchTypeKey: 'GOOD' },
        { label: 'Fair Fit', value: 'fair', matchTypeKey: 'FAIR' },
        { label: 'Weak Fit', value: 'none,weak', matchTypeKey: 'WEAK' },
        { label: 'Distortion', value: 'distortion', matchTypeKey: 'DISTORTION' },
    ],
    keywords: [
        { label: 'Sales', value: 'sales' },
        { label: 'Business', value: 'business' },
        { label: 'Marketing', value: 'marketing' },
        { label: 'Management', value: 'management' },
        { label: 'Customer', value: 'customer' },
    ],
    rating: [
        { label: 'FIVE', value: '5' },
        { label: 'FOUR', value: '4' },
        { label: 'THREE', value: '3' },
        { label: 'TWO', value: '2' },
        { label: 'ONE', value: '1' },
    ]
};

export const SkillMap = {
    THREE: 3,
    TWO: 2,
    ONE: 1
};

//Prepare Applicants headers with generic ILabelValueBase and add order property to sort the headers.
export const ApplicantsExcelHeaders: (ILabelValueBase & { order: number })[] = [
    { label: 'Application Completed At', value: "applicationCompletedAt", order: 7 },
    { label: 'Candidate Employer Job Id', value: "candidateEmployerJobId", order: 5 },
    { label: 'Candidate Id', value: "candidateId", order: 4 },
    { label: 'Comment  Total', value: "commentTotal", order: 11 },
    { label: 'Distortion Count', value: "distortionCount", order: 10 },
    { label: 'Email', value: "email", order: 3 },
    { label: 'Employer Job Id', value: "employerJobId", order: 6 },
    { label: 'First Name', value: "firstName", order: 1 },
    { label: 'Last Name', value: "lastName", order: 2 },
    { label: 'Match Type', value: "matchType", order: 9 },
    { label: 'Rating', value: "rating", order: 8 }
];

export const MatchTypes = {
    strong: 'strong',
    strongFit: 'strong fit',
    good: 'good',
    fair: 'fair',
    match: 'match',
    none: 'none',
    weak: 'weak',
    distortion: 'distortion'
};

export const RejectionEmailPayload: IRejectionEmailPayload = {
    subject: '',
    to: '',
    cc: '',
    bcc: '',
    data: '',
}

export const StagesFolders: IStageFolder[] = [
    { label: 'All', value: 'all' },
    { label: 'New', value: 'new' },
    { label: 'Interview', value: 'interviews' },  // This will include all interview stages
    { label: 'Rejected', value: 'rejected' },
    //{ label: 'Reviewed', value: 'reviewed' },
    { label: 'Offer', value: 'offer' },
    { label: 'Hired', value: 'hired' },
];

import DownloadIcon from '@mui/icons-material/Download';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShTabsV2 } from "shared/SharedStyles/ShNavigation";
import { downloadApplicantHiringGuide, downloadCandidateProfile, resetDownloadApplicantHiringGuide, resetDownloadApplicantProfile } from "store/slices/employer/applicants/applicant-actions-slice";
import { IApplicant } from "../ApplicantsModel";
import { ApplicantComments } from "./ApplicantComments";
import { ApplicantProfile } from "./ApplicantProfile";
import { ApplicantDetailsSection, hashToSectionMap } from "./ApplicantProfileModel";
import { ApplicantQuestions } from "./ApplicantQuestions";
import { ApplicantResume } from "./ApplicantResume";
import { ApplicantEEO } from './ApplicantEEO';
import { ApplicantAdditionalQuestions } from './ApplicantAdditionalQuestions';
import { Stack, Tab, CircularProgress, Box } from '@mui/material';

export const ApplicantProfileWrapper = () => {

    const notification = useNotification();
    const dispatch = useAppDispatch();
    const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
    const { downloadingGuideStatus, downloadingGuideResponse, downloadCandidateProfileResponse, downloadCandidateProfileStatus } = useAppSelector(state => state.employer.applicants.applicantActions);
    const navigate = useNavigate();
    const location = useLocation();
    const [currentSection, setCurrentSection] = useState<ApplicantDetailsSection>(hashToSectionMap[location.hash] || 'profile');

    useEffect(() => {
        if (location.hash) {
            setCurrentSection(hashToSectionMap[location.hash] || 'profile');
        }
    }, [location.hash]);

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: ApplicantDetailsSection) => {
        setCurrentSection(newValue);
        navigate(`#${newValue}`);
    };


    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    const dispatchDownloadCandidateProfile = (applicant: IApplicant) => {
        dispatch(downloadCandidateProfile({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    // Show snackbar notifications on download list as csv.
    useEffect(() => {
        if (downloadingGuideStatus === 'success' || downloadingGuideStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadingGuideStatus === 'success' ? 'success' : 'error',
                message: downloadingGuideResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantHiringGuide());
        }
    }, [dispatch, downloadingGuideResponse, downloadingGuideStatus, notification]);

    useEffect(() => {
        if (downloadCandidateProfileStatus === 'success' || downloadCandidateProfileStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: downloadCandidateProfileStatus === 'success' ? 'success' : 'error',
                message: downloadCandidateProfileResponse ?? 'Download successful'
            });
            dispatch(resetDownloadApplicantProfile());
        }
    }, [dispatch, downloadCandidateProfileResponse, downloadCandidateProfileStatus, notification]);
    
    return (
        <>
        <Stack direction='row' alignItems='center' justifyContent='space-between' rowGap={1} flexWrap='wrap' paddingX={{ xs: 2, sm: 3, md: 4, lg: 4 }} marginBottom={1} marginTop={1}>
            <ShTabsV2 aria-label="Applicant Tabs" variant="scrollable" scrollButtons='auto'
                onChange={handleTabChange}
                allowScrollButtonsMobile value={currentSection}>
                <Tab label="Summary" value="profile" />
                <Tab label="Resume" value="resume" />
                <Tab label="Comments" value="comments" />
                <Tab label="Additional Questions" value="additionalquestions" />
                <Tab label="EEO" value="eeo" />
                {applicantDetails && !applicantDetails?.atsPurchased &&
                    <Tab label="Interview Questions" value="questions" />
                }
            </ShTabsV2>
            <Stack direction="row" spacing={1}>
                {!applicantDetails?.atsPurchased &&
                    <ShButton disableElevation
                        onClick={(e) => {
                            e.stopPropagation();
                            applicantDetails && dispatchDownloadCandidateProfile(applicantDetails);
                        }}
                        variant="outlined"
                        startIcon={
                            downloadCandidateProfileStatus === 'pending' ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <DownloadIcon fontSize="medium" />
                            )
                        }
                        disabled={downloadCandidateProfileStatus === 'pending'}
                    >
                        {downloadCandidateProfileStatus === 'pending' ? 'Downloading...' : 'Download Profile'}
                    </ShButton>
                }
                {!applicantDetails?.atsPurchased &&
                    <ShGreenBtn
                        startIcon={
                            downloadingGuideStatus === 'pending' ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <DownloadIcon fontSize="medium" />
                            )
                        }
                        onClick={(e) => {
                            e.stopPropagation();
                            applicantDetails && downloadHiringGuide(applicantDetails);
                        }}
                        variant="outlined"
                        disabled={downloadingGuideStatus === 'pending'}
                        disableElevation
                        color="primary"
                        size="small"
                    >
                        {downloadingGuideStatus === 'pending' ? 'Downloading...' : 'Download Hiring Guide'}
                    </ShGreenBtn>}
            </Stack>
        </Stack>

        <Box padding={{ xs: 2, sm: 3, md: 4, lg: 4 }}>
            {currentSection === 'profile' && <ApplicantProfile />}
            {currentSection === 'resume' && <ApplicantResume />}
            {currentSection === 'comments' && <ApplicantComments />}
            {currentSection === 'additionalquestions' && <ApplicantAdditionalQuestions />}
            {currentSection === 'eeo' && <ApplicantEEO />}
            {currentSection === 'questions' && <ApplicantQuestions />}
        </Box>
    </>
    
    );
}
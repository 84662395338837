import {
    Grid,
    Typography,
    IconButton,
    DialogContent,
    DialogActions,
    Box,
    Stack,
    Divider,
    LinearProgress,
    FormControlLabel,
    Checkbox,
    FormGroup,
    FormControl,
    Slider,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShButton, ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { RootState } from "shared/SharedModels";
import { addRemoveOrUpdateEmployerRejectCriteria, getEmployerRejectCriteria } from "store/slices/employer/employer/employer-details-slice";
import { useAppDispatch } from "helpers/hooks";
import { Star as StarIcon } from "@mui/icons-material";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { renderMatchChip } from "Modules/Core/Applicants/ApplicantsList/ApplicantsListView";
import { fitOptions } from "./CriteriaModel";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { Cancel as CancelIcon, Save as SaveIcon } from '@mui/icons-material';

export const Criteria = () => {
    const dispatch = useAppDispatch();
    const { employerRejectCriteria, getAutoRejectCriteraStatus, employerDetails, updateAutoRejectCriteraStatus } = useSelector(
        (state: RootState) => state.employer.employerDetails
    );

    const [localRejectCriteria, setLocalRejectCriteria] = useState(employerRejectCriteria || []);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [criteriaName, setCriteriaName] = useState("");
    const [criteriaDesc, setCriteriaDesc] = useState("");
    const [resumeScoreThreshold, setResumeScoreThreshold] = useState<number | "">("");
    const [selectedFits, setSelectedFits] = useState<string[]>([]);
    const [preferred, setPreferred] = useState(false);
    const [editingId, setEditingId] = useState<number | null>(null);
    const [alertMessage, setAlertMessage] = useState<string | null>(null);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);

    const [errors, setErrors] = useState({
        criteriaName: false,
        criteriaDesc: false,
        resumeScoreThreshold: false,
    });

    const sortCriteria = (criteria: any[]) => {
        return [...criteria].sort((a, b) => {  // Create a new array before sorting
            if (a.isPreferred && !b.isPreferred) return -1;
            if (!a.isPreferred && b.isPreferred) return 1;
    
            return new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime();
        });
    };
    

    useEffect(() => {
        if (employerRejectCriteria) {
            const sortedCriteria = sortCriteria(employerRejectCriteria);
            setLocalRejectCriteria(sortedCriteria);
        }
    }, [employerRejectCriteria]);

    useEffect(() => {
        if (employerDetails?.id) {
            dispatch(getEmployerRejectCriteria({ empId: employerDetails?.id }));
        }
    }, [dispatch, employerDetails?.id]);

    const validateForm = () => {
        const newErrors = {
            criteriaName: !criteriaName.trim(),
            criteriaDesc: !criteriaDesc.trim(),
            resumeScoreThreshold: resumeScoreThreshold === "" || Number(resumeScoreThreshold) < 0 || Number(resumeScoreThreshold) > 100,
        };
        setErrors(newErrors);
        return Object.values(newErrors).every(value => !value);
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setCriteriaName("");
        setCriteriaDesc("");
        setResumeScoreThreshold("");
        setSelectedFits([]);
        setPreferred(false);
        setEditMode(false);
        setEditingId(null);
        setErrors({
            criteriaName: false,
            criteriaDesc: false,
            resumeScoreThreshold: false,
        });
    };

    const handleAddCriteria = () => {
        if (validateForm()) {
            const newCriteria = {
                id: Date.now(),
                dateCreated: new Date().toLocaleDateString(),
                criteriaName,
                criteriaDesc,
                resumeScoreThreshold: Number(resumeScoreThreshold),
                selectedFits,
                isPreferred: preferred,
            };

            if (preferred) {
                const updatedCriteria = localRejectCriteria.map(criteria => ({
                    ...criteria,
                    isPreferred: false,
                }));
                setLocalRejectCriteria([newCriteria, ...updatedCriteria]);
            } else {
                setLocalRejectCriteria((prev) => [...prev, newCriteria]);
            }

            if (employerDetails?.id) {
                dispatch(addRemoveOrUpdateEmployerRejectCriteria({
                    empId: employerDetails?.id,
                    action: 'add',
                    criteria: newCriteria,
                }));

            }
            
            handleDialogClose();
        }
    };

    const handleEditCriteria = () => {
        if (validateForm() && editingId !== null) {
            const updatedCriteria = {
                id: editingId,
                dateCreated: new Date().toLocaleDateString(),
                criteriaName,
                criteriaDesc,
                resumeScoreThreshold: Number(resumeScoreThreshold),
                selectedFits,
                isPreferred: preferred,
            };

            if (preferred) {
                const updatedCriteriaList = localRejectCriteria.map(criteria => ({
                    ...criteria,
                    isPreferred: false,
                }));
                setLocalRejectCriteria(updatedCriteriaList.map(criteria => criteria.id === editingId ? updatedCriteria : criteria));
            } else {
                setLocalRejectCriteria((prev) => prev.map(criteria => criteria.id === editingId ? updatedCriteria : criteria));
            }

            if (employerDetails?.id) {
                dispatch(addRemoveOrUpdateEmployerRejectCriteria({
                    empId: employerDetails?.id,
                    action: 'update',
                    criteria: updatedCriteria,
                }));
            }

            handleDialogClose();
        }
    };

    const handleDeleteCriteria = (id: number, event: React.MouseEvent) => {
        event.stopPropagation();
        setLocalRejectCriteria((prev) => prev.filter(criteria => criteria.id !== id));

        if (employerDetails?.id) {
            dispatch(addRemoveOrUpdateEmployerRejectCriteria({
                empId: employerDetails?.id,
                action: 'remove',
                criteria: {
                    id,
                    dateCreated: "",
                    criteriaName: "",
                    criteriaDesc: "",
                    resumeScoreThreshold: 0,
                    selectedFits: [],
                    isPreferred: false,
                },
            }));
        }
    };

    const handleEditClick = (id: number) => {
        const criteriaToEdit = localRejectCriteria.find((criteria) => criteria.id === id);
        if (criteriaToEdit) {
            setEditingId(id);
            setCriteriaName(criteriaToEdit.criteriaName);
            setCriteriaDesc(criteriaToEdit.criteriaDesc);
            setResumeScoreThreshold(criteriaToEdit.resumeScoreThreshold);
            setSelectedFits(criteriaToEdit.selectedFits);
            setPreferred(criteriaToEdit.isPreferred);
            setEditMode(true);
            setDialogOpen(true);
        }
    };
    
    const handleFitToggle = (fitValue: string) => {
        setSelectedFits(prevFits =>
            prevFits.includes(fitValue)
                ? prevFits.filter(fit => fit !== fitValue)
                : [...prevFits, fitValue]
        );
    };

    useEffect(() => {
        if (updateAutoRejectCriteraStatus === 'success') {
            setAlertMessage("Criteria updated successfully!");
            setSuccessAlertOpen(true);
            setErrorAlertOpen(false);
        } else if (updateAutoRejectCriteraStatus === 'failed') {
            setAlertMessage("Criteria failed to be updated.");
            setErrorAlertOpen(true);
            setSuccessAlertOpen(false);
        }
    }, [updateAutoRejectCriteraStatus]);
    

    return (
        <>
            {successAlertOpen &&
                <Box padding={2}>
                    <ShAlert onClose={() => setSuccessAlertOpen(false)} severity="success">
                        {alertMessage}
                    </ShAlert>
                </Box>
            }
             {errorAlertOpen &&
                <Box padding={2}>
                    <ShAlert onClose={() => setSuccessAlertOpen(false)} severity="error">
                        {alertMessage}
                    </ShAlert>
                </Box>
            }

            <Stack spacing={2}>
                <ShPaper variant="outlined" headerSection borderRadius={0} sx={{ maxHeight: '100px' }}>
                    <Typography variant="h6" gutterBottom paddingLeft={2}>
                        Auto-Reject Applicants
                    </Typography>
                    <Typography variant="subtitle2" paddingLeft={2} color='gray'>
                        Setup criteria to automatically reject applicants, who do not meet the job requirements.
                    </Typography>
                </ShPaper>
                
                <ShAlert severity="info">
                    <Typography variant="subtitle2">
                        Note: Candidates will only be rejected when you manually initiate the 
                        batch reject action from the applicant list.
                    </Typography>
                </ShAlert>
                
                <ShPaper variant="outlined">
                    <Divider />
                    <Grid container spacing={2} padding={2}>
                        {getAutoRejectCriteraStatus === 'pending' || updateAutoRejectCriteraStatus === 'pending' ? (
                            <Box width={'100%'} padding={2}>
                                <LinearProgress />
                            </Box>
                        ) : (
                            localRejectCriteria?.map((criteria) => (
                                <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={criteria.id} onClick={() => handleEditClick(criteria.id)}>
                                    <ShPaper transElevateOnHover variant="outlined"   
                                        sx={{ 
                                            border: criteria.isPreferred ? '2px solid #74C05A' : '', 
                                            cursor: 'pointer', 
                                        }}>
                                        <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent='space-between' spacing={2}>
                                            <Box maxWidth={175}>
                                                <Typography variant="subtitle2">{criteria.criteriaName}</Typography>
                                            </Box>
                                            {criteria.isPreferred && (
                                                <Box maxWidth={150}>
                                                    <ShChip size="small" label="Preferred" color="success" textColor="white" borderRadius="15px" icon={<StarIcon color="success" />}/>
                                                </Box>
                                            )}
                                        </Stack>
                                        <Typography variant="body2" color="textSecondary">
                                            {criteria.criteriaDesc}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <strong>Resume Score Threshold:</strong> {criteria.resumeScoreThreshold}%
                                        </Typography>

                                        {criteria.selectedFits.length > 0 && 
                                            <Typography variant="body2" color="textSecondary"><strong>Rejecting:{" "}</strong>
                                                {criteria?.selectedFits?.map((fit) => {
                                                        const firstFit = fit.split(",")[0].trim();
                                                        return fitOptions.find((opt) => opt.value.includes(firstFit))?.label || firstFit;
                                                    }).join(" | ")
                                                }
                                            </Typography>
                                        }
                                        <Typography variant="caption" color="textSecondary">
                                            Created on: {criteria.dateCreated}
                                        </Typography>
                                        <Box display='flex' justifyContent="space-between">
                                            <IconButton onClick={() => handleEditClick(criteria.id)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={(event) => handleDeleteCriteria(criteria.id, event)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                    </ShPaper>
                                </Grid>
                            ))
                        )}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Box height={175} display="flex" alignItems="center" justifyContent="center" border="2px dashed #74C05A">
                                <IconButton onClick={handleDialogOpen} sx={{ color: "green", fontSize: 35 }}>
                                    <AddIcon fontSize="inherit" />
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                </ShPaper>
            </Stack>

            <ShDialog open={dialogOpen} onClose={handleDialogClose} maxWidth='md'>
                  <ShPaper variant="outlined" borderRadius={0} headerSection>
                        <Stack direction='row' alignItems="center">
                            {editMode ? <EditIcon color='primary' fontSize="small" /> : <AddIcon color='primary' fontSize="small" />}
                            <Typography variant="h6" marginLeft={1}>
                                {editMode ? "Edit Auto-Reject Criteria" : "Add Auto-Reject Criteria"}
                            </Typography>
                        </Stack>
                    </ShPaper>

                    <DialogContent>
                        <Stack spacing={3}>
                            <ShTextFieldV2  label="Criteria Name"  fullWidth  size='small' value={criteriaName} onChange={(e) => setCriteriaName(e.target.value)}  
                                error={errors.criteriaName}
                                helperText={errors.criteriaName && "Criteria name is required."}/>
                                
                            <ShTextFieldV2  label="Criteria Description" fullWidth size='small' multiline rows={3} value={criteriaDesc} 
                                onChange={(e) => setCriteriaDesc(e.target.value)} error={errors.criteriaDesc} 
                                helperText={errors.criteriaDesc && "Criteria description is required."}/>
                            
                            <ShPaper variant="outlined" headerSection>
                                <Typography variant="subtitle2" gutterBottom> Resume Threshold</Typography>
                                 {/* Added helper text for Resume Score Threshold */}
                                 <Typography variant="body2" color="textSecondary">
                                    Applicants with a score below this threshold will be rejected.
                                </Typography>
                                <Box display="flex" flexDirection="column" gap={2} paddingTop={2}>
                                    {/* TextField for direct input */}
                                    <ShTextFieldV2  label="Resume Score Threshold (%)" type="number" fullWidth 
                                        size='small'
                                        value={resumeScoreThreshold} 
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const numericValue = value === "" ? "" : Number(value);
                                            if (numericValue === "" || (numericValue >= 0 && numericValue <= 100)) {
                                                setResumeScoreThreshold(numericValue); 
                                            }
                                        }}
                                        error={errors.resumeScoreThreshold}
                                        helperText={errors.resumeScoreThreshold && "Invalid threshold. Enter a value between 0 and 100."}
                                    />
                                    <Slider
                                        value={typeof resumeScoreThreshold === "number" ? resumeScoreThreshold : 0} 
                                        onChange={(e, newValue) => {
                                            if (typeof newValue === "number" && newValue >= 0 && newValue <= 100) {
                                                setResumeScoreThreshold(newValue);
                                            }
                                        }}
                                        aria-labelledby="resume-score-slider"
                                        valueLabelDisplay="auto"
                                        step={1}
                                        min={0}
                                        max={100}
                                    />
                                </Box>
                            </ShPaper>

                            <ShPaper variant="outlined" headerSection>
                                <Typography variant="subtitle2" gutterBottom>  Reject a Job Fit</Typography>
                                    {/* Added helper text for Resume Score Threshold */}
                                    <Typography variant="body2" color="textSecondary">
                                    Any selected fits will result in automatic rejection of the applicant.
                                </Typography>
                                <FormControl>
                                    <FormGroup row>
                                        {fitOptions.map(option => (
                                            <FormControlLabel
                                                key={option.value}
                                                control={
                                                    <Checkbox
                                                        checked={selectedFits.includes(option.value)}
                                                        onChange={() => handleFitToggle(option.value)}
                                                    />
                                                }
                                                label={renderMatchChip(option.value)}
                                   
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            </ShPaper>
                            <FormControlLabel
                                control={<ShSwitch checked={preferred} onChange={() => setPreferred(!preferred)} />}
                                label={<Typography variant="subtitle1" ml={1}>Set as Preferred</Typography>}/>
                        </Stack>
                    </DialogContent>
                    
                    <DialogActions>
                        <ShButton onClick={handleDialogClose} color="info" startIcon={<CancelIcon />}>
                            Cancel
                        </ShButton>
                        <ShGreenBtn 
                            onClick={editMode ? handleEditCriteria : handleAddCriteria} 
                            color="primary" 
                            variant="contained" 
                            startIcon={editMode ? <SaveIcon /> : <AddIcon />}
                        >
                            {editMode ? "Save Changes" : "Add"}
                        </ShGreenBtn>
                    </DialogActions>
            </ShDialog>
  </>
);
};

import { Stack } from "@mui/material";
import { useAppSelector } from "helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ShButton, ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { FormTemplates } from "../SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplates";

export const AdditionalQuestionForm = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  // const { isEeoEnabled } = useAppSelector((state) => state.employer.createJob.createJobShared);
  const { companyInfo } = useAppSelector((state) => state.employer.employerSettings.companyInfo);


  return (
    <Stack spacing={2}>
      <FormTemplates usedInCreateJob={true} />
      {/* Navigation Buttons */}
      <Stack direction="row" justifyContent="space-between">

        <ShButton variant="contained" disableElevation onClick={() => navigate(`/employer/job/${jobId}/details`)}>
          Back
        </ShButton>

        {companyInfo.is_eeo_enabled ?
          <ShGreenBtn variant="contained" disableElevation onClick={() => navigate(`/employer/job/${jobId}/eeoQuestionnaire`)}>
            Next
          </ShGreenBtn>
          :
          <ShGreenBtn variant="contained" disableElevation onClick={() => navigate(`/employer/job/${jobId}/preview`)}>
            Next
          </ShGreenBtn>
        }

      </Stack>
    </Stack>
  );
};

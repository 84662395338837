import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {
    Autocomplete,
    Box,
    Checkbox,
    Container,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import { MobileOnlyStack } from 'Modules/Core/Admin/AdminWrapper.styled';
import { useNotification } from 'Modules/Core/Notification';
import { StrongFitIcon } from 'assets/Icons';
import { IsSmScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { forwardRef, useEffect, useState } from 'react';
import { PasswordValidator } from "shared/SharedComponents/PasswordValidator";
import { ShFlag } from 'shared/SharedComponents/ShFlag';
// @ts-ignore
import ReCAPTCHA from "react-google-recaptcha";
import { PatternFormat } from 'react-number-format';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { ShAvatar } from 'shared/SharedStyles/ShDataDisplay';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { AutocompleteLoaders, ShButton, ShGreenBtn, ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { EmailRegEx, EmployerHomeUrl, LovNoMatchesText } from "shared/constants";
import { setIsWelcomeAcknowledged, setIsWelcomeCreateJobAcknowledged } from 'store/slices/app/app-info-slice';
import { IEmployerSignUpPayload } from 'store/slices/auth-v2/auth-v2-model';
import { loginAccount } from 'store/slices/auth-v2/auth-v2-slice';
import {
    getEmployerCountries, getSources, resetSignUp, resetSignUpApiStates, signUpEmployer
} from 'store/slices/auth-v2/employer-sign-up-slice';
import { resetSocialLogin } from 'store/slices/auth-v2/social-login-slice';
import { getIndustries } from 'store/slices/meta-data/jobs-meta-slice';
import { CompanySizeOptions, CountryCodes } from './AppLoginConstants';

// Phone number format component
const PhoneNumberFormat = forwardRef(function PhoneNumberFormat(props, ref,) {
    // @ts-ignore
    const { onChange } = props;

    return (
        // Using PatternFormat from 'react-number-format' for formatting phone number.        
        <PatternFormat format="(###) ### ####" mask="_" getInputRef={ref} className='phone-number'
            // @ts-ignore
            onValueChange={(values) => { onChange({ target: { name: props.name, value: values.value } }); }} />
    );
});

export const EmployerSignUp = () => {
    const navigate = useNavigate();
    const notification = useNotification();
    const { state } = useLocation();
    const dispatch = useAppDispatch();
    const isSmScreen = IsSmScreen();
    const hashInSignUpUrl = useLocation().hash.substring(1) as 'freeTrial';
    const { employerCountriesApiStatus, signUpEmployerApiStatus, employerCountries, signUpEmployerApiResponse,
        referenceSources, getSourcesApiStatus } = useAppSelector((state) => state.employer.employerSignUp);
    const { isAccountLoggedIn, accountLoginStatus } = useAppSelector((state) => state.auth.auth);
    const { industries, getIndustriesStatus, getIndustriesResponse } = useAppSelector((state) => state.metadata.jobsMeta);
    const { socialSignUpProfile } = useAppSelector((state) => state.auth.socialLogin);
    const [countryMenuAnchorEl, setCountryMenuAnchorEl] = useState<EventTarget & HTMLButtonElement | null>(null);
    const isCountryMenuOpen = Boolean(countryMenuAnchorEl);
    const [selectedCountryCode, setSelectedCountryCode] = useState<'ca' | 'us'>('us');
    const [captchaCode, setCaptchaCode] = useState<any>(null);
    const [isTermsOfUseChecked, setIsTermsOfUseChecked] = useState<boolean>(false);
    const [isPrivacyPolicyChecked, setIsPrivacyPolicyChecked] = useState<boolean>(false);
    const [isSubmitDisabled, setIsSubmitDisable] = useState<boolean>(true);
    const [isEmailInvalid, setIsEmailInvalid] = useState<boolean>(false);
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
    const [employerSignUpForm, setEmployerSignUpForm] = useState<IEmployerSignUpPayload>(() => {
        /*
            state property can be sent in navigate function from the login page.
            useLocation hook holds pathname and state, email can be accessed from state property to auto populate in sign up form.
        */
        return {
            first_name: '',
            last_name: '',
            email: state?.email ?? '',
            password: '',
            confirm_password: '',
            company_name: '',
            phone_area: '+1',
            phone_number: '',
            country_id: 0,
            industry_id: 0,
            num_employees: '',
            how_do_you_know: ''
        }
    });

    // if social sign up profile is available.
    useEffect(() => {
        if (socialSignUpProfile) {
            const { email, family_name, given_name, signup_source } = socialSignUpProfile;
            setEmployerSignUpForm((prev) => {
                return {
                    ...prev, email: email, first_name: given_name,
                    last_name: family_name, signup_source: signup_source
                }
            });
        }
    }, [socialSignUpProfile]);

    const setCountryCodeMenu = (country: { code: any; label?: string; phone: any; } | undefined) => {
        /* 
            Check if user clicked away from the menu instead of selecting any country, 
            so that the initial country value is left as is instead of being set to undefined.
         */
        if (country !== undefined) {
            setSelectedCountryCode(country?.code);
            setEmployerSignUpForm({ ...employerSignUpForm, phone_area: country?.phone });
        }
        setCountryMenuAnchorEl(null);
    };

    const loadIndustries = () => {
        // Load industries only once when the drop down is toggled for the first time.
        if (industries?.length === 0) {
            dispatch(getIndustries());
        }
    };

    const loadCountries = () => {
        // Load countries only once when the drop down is toggled for the first time.
        if (employerCountries?.length === 0) {
            dispatch(getEmployerCountries());
        }
    };

    const loadSources = () => {
        // Load sources only once when the drop down is toggled for the first time.
        if (referenceSources?.length === 0) {
            dispatch(getSources());
        }
    };

    // Disable submit button if required fields are not filled and Terms & Policy aren't checked.
    useEffect(() => {
        setIsSubmitDisable(() => {
            const { first_name, last_name, phone_number, email,
                country_id, industry_id, num_employees, company_name } = employerSignUpForm;
            return !isTermsOfUseChecked || isEmailInvalid || !isPrivacyPolicyChecked ||
                (socialSignUpProfile?.email === undefined && !isPasswordValid) ||
                first_name.trim()?.length === 0 || last_name.trim()?.length === 0 ||
                phone_number.trim()?.length === 0 || company_name.trim()?.length === 0 || email.trim()?.length === 0 ||
                country_id === 0 || industry_id === 0 || num_employees.trim()?.length === 0;
        });
    }, [isEmailInvalid, isPasswordValid, isPrivacyPolicyChecked, isTermsOfUseChecked, employerSignUpForm, socialSignUpProfile?.email]);

    // Email validation function with Regular Expression.
    const validateEmail = (e: { target: { value: string; }; }) => {
        const reg = new RegExp(EmailRegEx);
        setIsEmailInvalid(!reg.test(e.target.value));
        setEmployerSignUpForm({ ...employerSignUpForm, email: e.target.value })
    };

    const signUp = () => {
        // Reset sign upAPIstates before calling sign upAPIto catch any possibleAPIerrors.
        dispatch(resetSignUpApiStates());
        const payload = { ...employerSignUpForm };
        if (hashInSignUpUrl === 'freeTrial') {
            payload.free_trail_signup = true;
        }
        // Remove confirm_password from payload since it is only used for validation and API doesn't accept it.
        delete payload.confirm_password;
        dispatch(signUpEmployer({ payload: payload }));
    };

    useEffect(() => {
        if (signUpEmployerApiStatus === 'success') {
            /* If signing up the new user is successful then resetAPIstates and
             invoke loginAPIwith the saved email and password from sign up form.*/
            dispatch(resetSignUpApiStates());
            dispatch(setIsWelcomeAcknowledged({ _isWelcomeAcknowledged: 'no' }));
            dispatch(setIsWelcomeCreateJobAcknowledged({ _isWelcomeCreateJobAcknowledged: 'no' }));
            const { email, password } = employerSignUpForm;
            if (password) {
                dispatch(loginAccount({ payload: { email, password }, loginPage: 'employer' }));
            } else {
                notification.displayNotification({
                    open: true,
                    type: 'success',
                    message: 'User Sign Up successful. Please login with Social profile'
                });
                navigate('/accounts/login');
            }
        }
    }, [dispatch, navigate, notification, signUpEmployerApiResponse, signUpEmployerApiStatus, employerSignUpForm]);

    useEffect(() => {
        // Once the user is signed up successfully, LoginAPIis invoked automatically to login user into application.
        if (isAccountLoggedIn) {
            navigate(EmployerHomeUrl);
            dispatch(resetSocialLogin());
        }
    }, [dispatch, isAccountLoggedIn, navigate]);

    // Reset on component unmount.
    useEffect(() => { return () => { dispatch(resetSignUp()) } }, [dispatch]);

    return (<>
        <Container maxWidth='md'>
            <Box padding={{ xs: 0.5, sm: 2, md: 3, lg: 4 }}>
                <MobileOnlyStack>
                    {/* Hiding sign up button in contact block if the user is already in sign up page */}
                    <Stack flexDirection='row' alignItems='center' padding={2}>
                        <Typography variant='body2'>
                            Already have an account?
                        </Typography>
                        <Link className='sign-up-link' ml={1} component={RouterLink} to='/accounts/login'>Sign In</Link>
                    </Stack>
                </MobileOnlyStack>
                <ShPaper>
                    <Box padding={{ xs: 1, sm: 2, md: 3, lg: 3 }}>
                        <Typography variant='h5' fontWeight='bold' gutterBottom>Sign Up</Typography>
                        <Stack flexDirection='row' alignItems='center' mb={3} columnGap={1}>
                            <StrongFitIcon />
                            <Typography variant='body2' fontSize='inherit'>
                                Looking for acclaim badges?&nbsp;
                                {isSmScreen && <><br></br></>}
                                <Link component={RouterLink} to='/candidate/new' fontWeight='bold' underline='hover'>Sign Up</Link>
                                &nbsp;here
                            </Typography>
                        </Stack>
                        {/* Alert messages from Sign UpAPI*/}
                        {signUpEmployerApiStatus === 'success' && <ShAlert marginBottom={2} severity='success'
                            onClose={() => { dispatch(resetSignUpApiStates()) }}>{signUpEmployerApiResponse}</ShAlert>}
                        {signUpEmployerApiStatus === 'failed' && <ShAlert marginBottom={2} severity='error'
                            icon={<ErrorIcon />} onClose={() => { dispatch(resetSignUpApiStates()) }}>{signUpEmployerApiResponse}</ShAlert>}
                        {getIndustriesStatus === 'failed' && <ShAlert marginBottom={2} severity='error'
                            icon={<ErrorIcon />} action={<ShButton
                                onClick={() => dispatch(getIndustries())}>Retry</ShButton>}
                        >{getIndustriesResponse}</ShAlert>}
                        {/* Sign Up is success at this point, User is being logged in. */}
                        {accountLoginStatus === 'pending' && <ShAlert marginBottom={2} severity='info'>Logging In...</ShAlert>}
                        <Grid container rowSpacing={{ xs: 3, sm: 3, md: 3.5, lg: 3.5, xl: 3.5 }}
                            columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 4 }}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ShTextFieldV2 required size='small' id='first_name' label='First Name' variant='outlined' fullWidth
                                    value={employerSignUpForm.first_name}
                                    onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, first_name: e.target.value })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ShTextFieldV2 required size='small' id='last_name' label='Last Name' variant='outlined' fullWidth
                                    value={employerSignUpForm.last_name}
                                    onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, last_name: e.target.value })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ShTextFieldV2 required size='small' id='email' label='Email' variant='outlined'
                                    fullWidth disabled={socialSignUpProfile?.email !== undefined}
                                    value={employerSignUpForm.email} onChange={validateEmail} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Stack flexDirection='row'>
                                    {/* Custom PhoneNumberFormat component is passed as input component for textfield. 
                            This masks the numeric phone number from user and displays formatted number as 
                            he enters the digits in the field ex: (987) 654 3210.
                            The output from this field will be numeric string which is not formatted ex: 9876543210. */}
                                    <ShTextFieldV2 required size='small' variant='outlined' fullWidth id='phone' label='Phone Number'
                                        InputProps={{ inputComponent: PhoneNumberFormat as any }} value={employerSignUpForm.phone_number}
                                        onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, phone_number: e.target.value })} />
                                    {/* Country codes drop-down toggle button to prefix phone number with country code.
                                This holds various countries flag icons that are custom icons made with React MUI's SvgIcon component. */}
                                    <ShButton disableElevation endIcon={<ArrowDropDownIcon />}
                                        onClick={(evt) => setCountryMenuAnchorEl(evt.currentTarget)}>
                                        <ShAvatar sizes='' color='transparent' variant='square'>
                                            <ShFlag countryCode={selectedCountryCode} />
                                        </ShAvatar>
                                    </ShButton>
                                    <Menu id='country-menu' anchorEl={countryMenuAnchorEl} open={isCountryMenuOpen}
                                        onClose={() => setCountryCodeMenu(undefined)}>
                                        {CountryCodes?.map((cc) => (<MenuItem key={cc.code} onClick={() => setCountryCodeMenu(cc)}>
                                            <ShAvatar sizes='' color='transparent' variant='square'>
                                                <ShFlag countryCode={cc.code} />
                                            </ShAvatar>
                                            <Typography variant='body1' ml={1}>{cc.label} ({cc?.code?.toUpperCase()}) +{cc.phone}</Typography>
                                        </MenuItem>))}
                                    </Menu>
                                </Stack>
                            </Grid>
                            {!socialSignUpProfile?.email && <>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <ShTextFieldV2 required size='small' id='emp_sign_up_password' label='Password' variant='outlined' fullWidth
                                        type={showPassword ? 'text' : 'password'}
                                        value={employerSignUpForm.password}
                                        onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, password: e.target.value })}
                                        inputProps={{
                                            autoComplete: 'new-password', // Disable auto-fill
                                        }}
                                        InputProps={{
                                            endAdornment: <InputAdornment position='end'>
                                                <IconButton
                                                    edge='end' onClick={() => setShowPassword(!showPassword)}>
                                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        }}
                                    />
                                    {employerSignUpForm.password?.length > 0 &&
                                        <PasswordValidator password={employerSignUpForm.password} confirmPassword={employerSignUpForm.confirm_password ?? ''}
                                            onValidityChange={e => setIsPasswordValid(e)}
                                        />}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <Stack>
                                        <ShTextFieldV2 required size='small' id='confirm_password' label='Confirm Password'
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            variant='outlined' fullWidth value={employerSignUpForm.confirm_password}
                                            onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, confirm_password: e.target.value })}
                                            InputProps={{
                                                endAdornment: <InputAdornment position='end'>
                                                    <IconButton
                                                        edge='end' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                        {showConfirmPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </InputAdornment>
                                            }}
                                        />
                                        {employerSignUpForm?.confirm_password && employerSignUpForm?.confirm_password?.trim()?.length > 0 &&
                                            employerSignUpForm.password !== employerSignUpForm.confirm_password &&
                                            <Typography variant='caption' display='flex' alignItems='center'>
                                                {<ClearIcon color='error' fontSize='small' />}&nbsp;Passwords don't match!</Typography>}
                                    </Stack>
                                </Grid>
                            </>}
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <ShTextFieldV2 required size='small' id='company_name' label='Company Name' variant='outlined' fullWidth
                                    value={employerSignUpForm.company_name} onChange={(e) => setEmployerSignUpForm({ ...employerSignUpForm, company_name: e.target.value })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {/* Using MUI Autocomplete component to display dropdowns with inbuilt search functionality*/}
                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='country'
                                    options={employerCountries ?? []} onOpen={loadCountries}
                                    getOptionLabel={(option) => option.name || ''}
                                    renderInput={(params) => <ShTextFieldV2 required {...params} label='Country'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{employerCountriesApiStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) => { setEmployerSignUpForm({ ...employerSignUpForm, country_id: newValue?.id ?? 0 }) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='industry'
                                    options={industries ?? []} onOpen={loadIndustries}
                                    getOptionLabel={(option) => option.industry_translation?.name || ''}
                                    renderInput={(params) => <ShTextFieldV2 required {...params} label='Industry'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{getIndustriesStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) => { setEmployerSignUpForm({ ...employerSignUpForm, industry_id: newValue?.id ?? 0 }) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' disablePortal id='company_size'
                                    options={CompanySizeOptions} renderInput={(params) =>
                                        <ShTextFieldV2 required {...params} label='Company Size' />}
                                    onChange={(e, newValue) => { setEmployerSignUpForm({ ...employerSignUpForm, num_employees: newValue as string }) }} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <Autocomplete noOptionsText={LovNoMatchesText} size='small' id='how_did_you' disablePortal
                                    options={referenceSources ?? []} onOpen={loadSources}
                                    getOptionLabel={(option) => option.source || ''}
                                    renderInput={(params) => <ShTextFieldV2 {...params} label='How did you hear about us'
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (<>{getSourcesApiStatus === 'pending' ?
                                                <AutocompleteLoaders color='inherit' size={20} /> : null}
                                                {params.InputProps.endAdornment}</>)
                                        }} />}
                                    onChange={(e, newValue) => setEmployerSignUpForm({ ...employerSignUpForm, how_do_you_know: newValue?.id?.toString() ?? '' })} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                {/* Using ReCAPTCHA from 'react-google-recaptcha' to avoid automated SignUps.
                        The sitekey used here has to be changed with the company's dev account for Google reCAPTCHA console.*/}
                                <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={(e: any) => setCaptchaCode(e)} />
                            </Grid>
                        </Grid>
                        <Grid container columnSpacing={{ xs: 0, sm: 2, md: 3, lg: 4, xl: 4 }} mb={2} p={1}>
                            <Grid item xs={12}>
                                <FormControlLabel control={<Checkbox size="small" checked={isTermsOfUseChecked && isPrivacyPolicyChecked}
                                    onChange={() => {
                                        setIsTermsOfUseChecked(!isTermsOfUseChecked);
                                        setIsPrivacyPolicyChecked(!isPrivacyPolicyChecked);
                                    }} />}
                                    label={
                                        <Typography variant="body2"> I agree to the{' '}
                                            <Link href="https://smoothhiring.com/privacy-policy/" target="_blank" underline="hover">
                                                Privacy Policy
                                            </Link> {' '} and{' '}
                                            <Link href="https://smoothhiring.com/terms-of-use/" target="_blank" underline="hover">
                                                Terms of Use
                                            </Link>
                                        </Typography>
                                    } />
                            </Grid>
                        </Grid>
                        <ShGreenBtn className='self-center' variant='contained' disableElevation
                            disabled={isSubmitDisabled || captchaCode === null || signUpEmployerApiStatus === 'pending'}
                            onClick={signUp}>{signUpEmployerApiStatus === 'pending' ? 'Creating...' : 'Create Account'}</ShGreenBtn>
                    </Box>
                </ShPaper>
            </Box>
        </Container>
    </>)
}
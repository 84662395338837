import { Dispatch, MouseEvent, SetStateAction } from 'react';
import { ApiState, IPaginationParams, ISortBase, ISortParamsBase, LayoutTypes, SortOrder } from 'shared/SharedModels';
import { IAllJobDetails, JobType } from 'store/slices/employer/jobs/jobs-slice-model';
import { IApplicant } from '../Applicants/ApplicantsModel';

export type JobsSortCols = 'city' | 'label' | 'hiring_manager' | 'owner' | 'state' | 'name' | 'starts_at' | 'ends' | 'created_at' | 'updated_at' | 'job_id';
export type TemplateJobsSortCols = 'temp_job_name' | 'temp_job_id' | 'temp_job_creator';
export type AdminJobTypes = 'id' | 'hiring_coach_name' | 'last_approved_date' | 'name' | 'company_name' | 'location' | 'review_state';
export type AdminJobReviewTypes = 'name' | 'link' | 'html';
export type AdminJobFeaturesTypes = 'name' | 'status';
export type JobAction = 'START' | 'VIEW' | 'EDIT' | 'REPOST' | 'DESCRIPTION';
export type MatchType = 'STRONG' | 'MATCH' | 'NONE' | 'DISTORTION' | 'WEAK' | 'FAIR' | 'GOOD';
export type TExportJobsFormat = 'csv' | 'xls';

export type TJobsCount = {
    [key in JobType]: number;
};

export enum JobTypes {
    Active = 'active',
    Expired = 'expired',
    Draft = 'draft',
    TemplateJobs = 'template'
};

/* Data models*/
export interface IJob {
    city: string,
    hiring_manager: string,
    id: number,
    jobState: Uppercase<JobType>,
    newCandidateCount: number,
    owner: string,
    state: string,
    starts_at: string,
    ends: string,
    name: string,
    new_candidates_count: number,
    isExpanded?: boolean,
    getJobDescStatus: ApiState,
    getJobDescResponse: string,
    jobDetails?: IAllJobDetails,
    new_stage_count?: number
};

export interface IMatchTypeCount {
    count: number,
    matchType: MatchType
};

export interface IJobSummary {
    code?: string,
    complete?: boolean,
    employerName?: string,
    hiringManagerName?: string,
    inCompleteApplicantsCount?: number,
    totalApplicantsCount?: number,
    applicantList?: IApplicant[],
    inviteUrl?: string,
    jobActions?: JobAction[],
    jobDescription?: string,
    jobDescriptionUrl?: string,
    location?: string,
    matchTypeCounts?: IMatchTypeCount[],
    name?: string,
    ownerName?: string,
    getSummaryStatus?: ApiState,
    getSummaryResponse?: string,
    isAtsJob?: boolean
};

export interface IJobsSummary {
    [candidateEmployerJobId: number]: IJobSummary,
};

export interface ITemplateJobListItem {
    temp_job_name: string,
    temp_job_id: number,
    temp_job_creator: string
}

export interface IJobsListState {
    getJobsCountsStatus: ApiState,
    getJobsCountsResponse: string | undefined,
    getJobsListStatus: ApiState,
    getJobsListResponse: string | undefined,
    activeCount: number,
    draftCount: number,
    expiredCount: number,
    templateCount: number,
    jobsList: IJob[],
    paginationParams: IPaginationParams,
    jobsSummary: IJobsSummary
};

export interface IJobsActionsState {
    deleteJobStatus?: ApiState,
    deleteJobResponse?: string,
    stopHireJobStatus?: ApiState,
    stopHireJobResponse?: string,
    rePostJobStatus?: ApiState,
    rePostJobResponse?: string,
    copiedJobId?: number,
    prevJobId?: number,
    downloadAsCSVStatus: ApiState,
    downloadAsCSVResponse?: string,
    downloadAsExcelStatus: ApiState,
    downloadAsExcelResponse?: string,
    shareJobStatus?: ApiState,
    shareJobResponse?: string
};

export interface IJobsComponentBase {
    jobsList: IJob[],
    jobsSummary: IJobsSummary,
    selectedJobs: number[],
    selectJob: (jobId: number) => void,
    getJobSummary: (jobId: number) => void,
    jobsType: JobType
};

export interface IJobRow {
    job: IJob,
    jobsSummary: IJobsSummary,
    selectedJobs: number[],
    selectJob: (jobId: number) => void,
    getJobSummary: (jobId: number) => void,
    jobsType: JobType,
    indx: number
};


/* API interfaces */
export interface IJobsApiParams {
    sortDirection: SortOrder,
    pageNumber: number,
    pageSize: number,
    sortColumn: JobsSortCols,
    jobType: JobType,
    search?: string
};

export interface IJobSummaryApiParams {
    jobId: number
};

export interface IJobsListResponse {
    data: IJob[],
    stats: IPaginationParams
};
// To be replaced once apis are fixed.

export interface IJobsToolBar extends ISortBase<JobsSortCols> {
    layoutType: LayoutTypes,
    setLayoutType: Dispatch<SetStateAction<LayoutTypes>>,
    searchKey: string,
    searchInJobs: (searchKey: string) => void,
    activeCount: number,
    draftCount: number,
    expiredCount: number,
    templateCount: number,
    jobsType: JobType,
    setJobsType: Dispatch<SetStateAction<JobType>>
};

export interface IJobsFooter {
    paginationParams: IPaginationParams,
    getJobs: (pageNo?: number, pageSize?: number, sortParams?: ISortParamsBase<JobsSortCols>) => void,
    isSmScreen: boolean,
    selectedJobs?: number[],
    hasMoreButton?: boolean,
    careerPage?: boolean,
    jobsType?: JobType
};

export interface IJobActionsMenu {
    job: IJob,
    anchorEl?: HTMLElement | null,
    openMoreMenu?: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>, indx: number) => void,
    setMoreOptionsAnchorEl?: (value: React.SetStateAction<{ [key: number]: HTMLElement | null; }>) => void,
    jobsType: JobType,
    actionMenuType: 'popup' | 'widget'
};

export interface IExportJobsParams {
    exportFormat: TExportJobsFormat,
    type: JobType,
    pageNo: number,
    pageSize: number
};

export interface IShareJobsParams {
  email: string;
  type: JobType;
  pageNo: number;
  pageSize: number;
};

export type TJobShare = 'linkedIn' | 'X' | 'facebook';

export interface ISummaryPanelMatchType {
    matchLabel: string,
    matchType: MatchType | MatchType[],
    matchTypeForFilter: MatchType,
    matchTypeIcon: JSX.Element
}
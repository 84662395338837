import WorkHistoryIcon from '@mui/icons-material/WorkHistoryOutlined';
import WorkOffIcon from '@mui/icons-material/WorkOffOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import { Box, Divider, Stack, Typography, darken, lighten, Link } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor, ShGreen } from 'shared/SharedStyles/styleConstants';
import { fetchDashboardJobsCounts, resetJobsState } from "store/slices/employer/employer/dashboard/dashboard-active-jobs-slice";
import { ShBadge } from '../Dashboard.styled';
import { DashboardTileBordeRadius } from '../DashboardConstants';


export const DashboardJobSummaryWidget = () => {

    const dispatch = useAppDispatch();
    const { activeCount, draftCount, expiredCount } = useAppSelector(state => state.employer.dashboard.dashboardActiveJobs);


    useEffect(() => {
        dispatch(fetchDashboardJobsCounts());
        return () => {
            dispatch(resetJobsState());
        }
    }, [dispatch]);


    return (<>
        <ShPaper variant="outlined" height='100%' borderRadius={DashboardTileBordeRadius}>
            <Stack direction='row' spacing={1} alignItems='center' padding={1.5}>
                <Typography variant='body1' gutterBottom> Job Openings</Typography>
                <ShChip textColor={darken(ShGreen, .6)} bgColor={lighten(ShGreen, .7)} variant='filled'
                    color='success' label={activeCount + draftCount} size='small' />
            </Stack>
            <Divider />
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                justifyContent='space-between' paddingTop={3}>
                <Stack flexDirection='row' alignItems='center' columnGap={6}>
                    <Link href="/employer/jobs/active" underline="none" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Box>
                            <ShBadge bgColor={PrimaryThemeColor} badgeContent={activeCount === 0 ? '0' : activeCount}>
                                <WorkOutlineOutlinedIcon color='action' fontSize="large" />
                            </ShBadge>
                            <Typography variant='subtitle2'>Posted</Typography>
                        </Box>
                    </Link>
                    <Link href="/employer/jobs/draft" underline="none" sx={{ display: 'inline-flex', alignItems: 'center' }}> 
                        <Box>
                            <ShBadge bgColor={PrimaryThemeColor}badgeContent={draftCount === 0 ? '0' : draftCount}>
                                <WorkHistoryIcon color='action' fontSize="large" />
                            </ShBadge>
                            <Typography variant='subtitle2'> Drafts </Typography>
                        </Box>
                    </Link>
                    <Link href="/employer/jobs/expired" underline="none" sx={{ display: 'inline-flex', alignItems: 'center' }}>
                        <Box >
                            <ShBadge bgColor={PrimaryThemeColor} badgeContent={expiredCount === 0 ? '0' : expiredCount}>
                                <WorkOffIcon color='action' fontSize="large" />
                            </ShBadge>
                            <Typography variant='subtitle2'> Expired </Typography>
                        </Box>
                    </Link>
                </Stack>
            </Stack>
        </ShPaper>
    </>);
};

import { INavLink } from "shared/SharedModels";

export const CoreSideNavLinks: INavLink[] = [
  {
    redirectTo: "/employer/dashboard",
    label: "Dashboard",
    feature: 'Dashboard'
  },
  {
    redirectTo: "/employer/job/new",
    label: "Create Job",
    feature: 'Create Job'
  },
  {
    redirectTo: "/employer/jobs",
    label: "My Jobs",
    feature: 'View Jobs'
  },
  {
    redirectTo: "/employer/interviews",
    label: "My Interviews",
    feature: 'Interviews'
  },
  // {
  //   redirectTo: "/employer/talent-dashboard",
  //   label: "Talent Center",
  //   feature: 'Employee Insights'
  // },
  // Temporary, change to ShResourcesIcon
  {
    redirectTo: "/employer/resources",
    label: "Resources",
    feature: 'Resources'
  },
  {
    redirectTo: "/employer/analytics",
    label: "My Analytics",
    feature: 'Analytics'
  },
  // Temporary, change to ShCalendarIcon
  {
    redirectTo: "/employer/calendar",
    label: "My Calendar",
    feature: 'Calendar'
  },
  {
    redirectTo: "/employer/settings/tools/templates/rejection-notification",
    label: "Tools And Automation",
    feature: 'Tools And Automation'
  },
  {
    redirectTo: "/employer/settings/account/account-information",
    label: "Settings",
    feature: 'Settings'
  },
]
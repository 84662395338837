import { Settings } from "@mui/icons-material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, Stack, Toolbar, Tooltip, Typography } from "@mui/material";
import { TopNavItemsContainerStyled } from "Modules/Core/Layouts/AppLayout/AppLayout.styled";
import { PricingInfo } from 'Modules/Core/Pricing/PricingInfo';
import { IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { ShLogo } from "shared/SharedComponents/ShLogo";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import ThemeToggler from "shared/SharedComponents/ThemeToggler";
import { MobileOnlyStack } from "shared/SharedStyles/ShLayouts";
import { StyledMenuItem } from "shared/SharedStyles/ShNavigation";
import { ShAppBar } from "shared/SharedStyles/ShSurfaces";
import { getInitialsFromName, stringToColor, toTitleCase } from "shared/utils";
import { togglePricingDialog } from 'store/slices/app/app-info-slice';
import { enterAdminAccount, resetEnterAdminAccount } from "store/slices/app/module-switch-slice";
import { loginByResponse, logoutAccount } from "store/slices/auth-v2/auth-v2-slice";
import { getEmployerDetails } from "store/slices/employer/employer/employer-details-slice";

export const EmployerTopNav = ({ setIsDrawerOpen }: { setIsDrawerOpen: React.Dispatch<React.SetStateAction<boolean>> }) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerId } = useAppSelector((state) => state.auth.auth);
    const { employerDetails } = useAppSelector(state => state.employer.employerDetails);
    const { navigatedFrom, getAdminAccountLoginStatus, adminAccountLogin, navigateBackUrl } = useAppSelector((state) => state.app.moduleSwitch);
    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState<HTMLElement | null>(null);
    const isProfileMenuOpen = Boolean(profileMenuAnchorEl);
    const { firstName, lastName } = useAppSelector((state) => state.auth.auth);
    const userInitials = getInitialsFromName(`${firstName} ${lastName}` || "SK", true, true);
    const avatarBackgroundColor = stringToColor(userInitials);
    const isXsScreen = IsXsScreen();

    // Load employer info once logged in.
    useEffect(() => {
        if (employerId && !employerDetails) {
            dispatch(getEmployerDetails({ empId: employerId }));
        }
    }, [employerId, dispatch, employerDetails]);

    const switchToPrevModule = () => {
        switch (navigatedFrom) {
            case 'admin':
                if (employerId)
                    dispatch(enterAdminAccount());
                break;

            default:
                break;
        }
    }

    useEffect(() => {
        if (getAdminAccountLoginStatus === 'success' && adminAccountLogin) {
            dispatch(loginByResponse({ loginResponse: adminAccountLogin }));
            if (navigateBackUrl) {
                navigate(navigateBackUrl);
            }
            dispatch(resetEnterAdminAccount());
        }
    }, [adminAccountLogin, dispatch, getAdminAccountLoginStatus, navigate, navigateBackUrl]);

    const AlwaysOnItems = () => {
        return (<>
            <ThemeToggler />
            {navigatedFrom &&
                <ShTooltip title={`Go back to ${toTitleCase(navigatedFrom)}`} placement="bottom-end">
                    <IconButton onClick={switchToPrevModule}>
                        <SwitchAccountIcon />
                    </IconButton>
                </ShTooltip>}
            <IconButton onClick={e => setProfileMenuAnchorEl(e.currentTarget)}>
                {firstName ?
                    <Avatar style={{ backgroundColor: avatarBackgroundColor }}>
                        {firstName[0]?.toUpperCase()}
                    </Avatar> :
                    <PersonIcon />
                }
            </IconButton>
        </>)
    }

    return (<>
        <ShAppBar position="sticky">
            <Toolbar>
                <Stack direction="row" alignContent="center" justifyContent="flex-start">
                    <MobileOnlyStack>
                        <IconButton size="large" edge="start" onClick={() => setIsDrawerOpen((prev) => !prev)}>
                            <MenuIcon />
                        </IconButton>
                    </MobileOnlyStack>
                    <Stack direction='row' alignItems='center' spacing={1}>
                        <ShLogo link="/employer/dashboard" />
                        {employerDetails && !isXsScreen &&
                            <>
                                <Divider orientation="vertical" flexItem />
                                <Typography variant="h6">{toTitleCase(employerDetails?.company_name)}</Typography>
                            </>
                        }
                    </Stack>
                </Stack>
                <TopNavItemsContainerStyled>
                    <Tooltip title="Contact">
                        <RouterLink to="/employer/help/contact-support">
                            <IconButton size="large">
                                <SupportAgentIcon />
                            </IconButton>
                        </RouterLink>
                    </Tooltip>
                    <Tooltip title="Pricing">
                        <IconButton size="large" onClick={() => dispatch(togglePricingDialog())}>
                            <MonetizationOnOutlinedIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                        <RouterLink to="/employer/help">
                            <IconButton size="large">
                                <HelpOutlineOutlinedIcon />
                            </IconButton>
                        </RouterLink>
                    </Tooltip>
                    {/* To be enabled once this functionality is implemented */}
                    {/* <Tooltip title="Notifications">
                    <IconButton size="large">
                        <Badge className='notif-badge' color='primary'>
                        <NotificationsOutlinedIcon />
                        </Badge>
                    </IconButton>
                    </Tooltip> */}
                    {/* Button to change the theme */}
                    {AlwaysOnItems()}
                </TopNavItemsContainerStyled>
                <MobileOnlyStack direction='row' alignItems='center'>
                    {/* Button to change the theme */}
                    {AlwaysOnItems()}
                    {/* To be enabled once this functionality is implemented */}
                    {/* <IconButton size="large" edge="end">
                        <Badge className='notif-badge' color='primary'>
                            <NotificationsOutlinedIcon />
                        </Badge>
                    </IconButton> */}
                </MobileOnlyStack>
            </Toolbar>
            <PricingInfo />
            <Menu id="profile-settings-menu" aria-label='Toggle profile settings' anchorEl={profileMenuAnchorEl}
                open={isProfileMenuOpen} onClose={() => setProfileMenuAnchorEl(null)}>
                <StyledMenuItem onClick={() => { navigate('/employer/settings/account/account-information'); setProfileMenuAnchorEl(null) }}>
                    <ListItemIcon><Settings color="primary" /></ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </StyledMenuItem>
                <StyledMenuItem
                    onClick={() => dispatch(logoutAccount({ logoutType: 'user' }))}>
                    <ListItemIcon><LogoutIcon color="warning" /></ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                </StyledMenuItem>
            </Menu>
        </ShAppBar>
    </>
    );
};

import { Stack, Typography, Box } from "@mui/material";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { Link as RouterLink } from 'react-router-dom';
import { Interviews } from "Modules/Core/Interviews/Interviews";
import { DashboardTileBordeRadius } from "../DashboardConstants";

export const DashboardUpcomingInterviewsWidget = () => {

return (
    <ShPaper height='100%' flex={1} marginBottom='10px' variant='outlined' borderRadius={DashboardTileBordeRadius}>
        <Stack height='100%' minWidth={300} rowGap={2}>
            <Stack justifyContent="space-between" alignItems="center" direction='row' rowGap={2} flexWrap='wrap'>
                <Typography variant="body1" display='flex' columnGap={1} alignItems='center'>
                    <UpcomingIcon color='primary' />
                    Upcoming Interviews</Typography>
                <ShButton variant="outlined" color="primary" component={RouterLink}
                    to="/employer/interviews">See all Interviews</ShButton>
            </Stack>
            <Box overflow='auto'>
                <Interviews gridProps={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} usedInDashboard/>
            </Box>
        </Stack>
    </ShPaper>
)
}
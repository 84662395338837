import { Stack, Box, Typography } from '@mui/material';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import EventIcon from '@mui/icons-material/Event';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

interface ViewDateTimeDetailsProps {
  date: string;
  from_time: string;
  to_time: string;
  timezone: string;
  updated_at?: string;
}

const formatTo12Hour = (time: string) => {
  const [hours, minutes] = time.split(':').map((num) => +num);
  const period = hours >= 12 ? 'PM' : 'AM';
  const adjustedHours = hours % 12 || 12;
  return `${String(adjustedHours).padStart(2, '0')}:${minutes} ${period}`;
};

const getTime = (from_time: string, to_time: string, timezone: string) => {
  if (from_time && to_time) {
    const formattedFromTime = formatTo12Hour(from_time);
    const formattedToTime = formatTo12Hour(to_time);
    return timezone
      ? `${formattedFromTime} - ${formattedToTime} ${timezone}`
      : `${formattedFromTime} - ${formattedToTime}`;
  }
};

const ViewDateTimeDetails: React.FC<ViewDateTimeDetailsProps> = ({
  date,
  from_time,
  timezone,
  to_time,
  updated_at,
}) => {
  return (
    <ShPaper variant="outlined">
      <Stack spacing={1}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <EventIcon color="primary" fontSize="medium" />
          <Box>
            <Typography variant="body1" color="black" fontWeight={600}>
              Date
            </Typography>
            <Typography variant="body2" color={'gray'}>
              {date}
            </Typography>
          </Box>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <AccessTimeIcon color="primary" fontSize="medium" />
          <Box>
            <Typography variant="body1" color="black" fontWeight={600}>
              Time
            </Typography>
            <Typography variant="body2" color={'gray'}>
              {getTime(from_time, to_time, timezone)}
            </Typography>
          </Box>
        </Stack>

        {updated_at && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <EventIcon color="primary" fontSize="medium" />
            <Box>
              <Typography variant="body1" color="black" fontWeight={600}>
                Completed At
              </Typography>
              <Typography variant="body2" color={'gray'}>
                {new Date(updated_at).toUTCString().replace(' GMT', '')}
              </Typography>
            </Box>
          </Stack>
        )}
      </Stack>
    </ShPaper>
  );
};

export default ViewDateTimeDetails;

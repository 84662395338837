import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PlaceIcon from "@mui/icons-material/Place";
import ScheduleIcon from '@mui/icons-material/Schedule';
import SwapVertOutlinedIcon from '@mui/icons-material/SwapVertOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import {
    Alert,
    Box,
    CardHeader,
    Checkbox,
    CircularProgress,
    FormControl,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Pagination,
    Select,
    Skeleton,
    Stack,
    Typography,
} from "@mui/material";
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { toTitleCase, truncateText } from "shared/utils";
import { getCompanyLogo, getEmployerPostedJobs } from "store/slices/employer/employer-sub-domain/employer-sub-domain-slice";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { ShAlert } from 'shared/SharedStyles/ShFeedback';
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';

export const CareerPagesHome = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { employerPostedJobs, employerByMask, getEmployerPostedJobsStatus, getEmployerPostedJobsResponse,
        companyLogo, getCompanyLogoApiStatus, getCompanyLogoApiResponse
    } = useAppSelector(state => state.employer.employerSubDomain);
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [sortOption, setSortOption] = useState('recent');
    const [selectedCities, setSelectedCities] = useState<string[]>([]);
    const [openCityFilter, setOpenCityFilter] = useState(false);
    const [defaultJobId, setDefaultJobId] = useState<string>('');
    const [pageSize] = useState(8);
    const filteredJobs = employerPostedJobs?.filter(job =>
        job.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
        (selectedCities.length > 0 ? selectedCities.includes(job.city) : true)
    ) || [];
    const sortedJobs = [...filteredJobs].sort((a, b) => {
        if (sortOption === 'recent') {
            return new Date(b.starts_at).getTime() - new Date(a.starts_at).getTime();
        } else if (sortOption === 'oldest') {
            return new Date(a.starts_at).getTime() - new Date(b.starts_at).getTime();
        } else if (sortOption === 'city') {
            return a.city.localeCompare(b.city);
        }
        return 0;
    });
    const cities = [...new Set(employerPostedJobs?.map(job => job.city).filter(city => city.trim() !== '') || [])];
    const handleCityToggle = (city: string) => {
        setSelectedCities(prevSelectedCities =>
            prevSelectedCities.includes(city)
                ? prevSelectedCities.filter(c => c !== city)
                : [...prevSelectedCities, city]
        );
    };
    const jobsToDisplay = sortedJobs.length > 0 ? sortedJobs : employerPostedJobs || [];
    const totalPages = Math.ceil(jobsToDisplay.length / pageSize);
    const paginatedJobs = jobsToDisplay.slice((currentPage - 1) * pageSize, currentPage * pageSize);
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const { jobId } = useParams(); 
    const jobExists = employerPostedJobs?.some((job) => job.id.toString() === jobId);
    const initialNavigationDone = useRef(false);

    useEffect(() => {
        if (!isSmScreen && !defaultJobId && employerPostedJobs && employerPostedJobs.length > 0) {
            setDefaultJobId(employerPostedJobs[0]?.id.toString());

            if(defaultJobId){
                dispatch(getJobDetails({ jobId: parseInt(defaultJobId) }));
                navigate(`${defaultJobId}`);
            }
        }
    }, [employerPostedJobs, isSmScreen, defaultJobId, navigate, dispatch]);


    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    // Get jobs list on page load.
    useEffect(() => {
        if (employerByMask?.id) {
            dispatch(getEmployerPostedJobs({ employerId: employerByMask?.id }))
        }
    }, [dispatch, employerByMask?.id]);

    // get Company Logo from backend 
    useEffect(() => {
        if (!companyLogo && employerByMask?.id ) {
          dispatch(getCompanyLogo({ empId: employerByMask?.id  }));
        }
      }, [dispatch, employerByMask?.id , companyLogo]);

      useEffect(() => {
          if (!isSmScreen && employerPostedJobs && employerPostedJobs.length > 0 && !initialNavigationDone.current) {
              const firstJobId = employerPostedJobs[0]?.id.toString();
              dispatch(getJobDetails({ jobId: parseInt(firstJobId) }));
              navigate(`/careers/${firstJobId}`);
              initialNavigationDone.current = true; 
          }
      }, [employerPostedJobs, isSmScreen, dispatch, navigate]);

    return (
        <Stack height='100%'>
            <Box overflow='auto'>
                <ShContainer maxWidth='xl' margin='auto'>
                    <Box marginY={1}>
                        <ShPaper height='unset' variant='outlined'>
                            <Stack direction='row' alignItems='center' columnGap={1} spacing={2}>
                                {(companyLogo === undefined) ?
                                        <Typography variant='body2'>(250px in 250px)</Typography> :
                                        getCompanyLogoApiStatus === 'pending' ?
                                            <Skeleton width='25%' height='100%' animation='wave' /> :
                                            getCompanyLogoApiStatus === 'success' && companyLogo ?
                                                <img src={companyLogo} alt="Company Logo" /> :
                                                <>
                                                    {getCompanyLogoApiStatus === 'failed' &&
                                                        <ShAlert severity="error">
                                                            {getCompanyLogoApiResponse}
                                                        </ShAlert>}
                                                </>
                                }
                                <Stack >
                                    <Typography variant="h6">Welcome to {employerByMask?.company_name} jobs.</Typography>
                                    <Typography variant="caption" textAlign='right' color='gray'>Powered by Smoothhiring</Typography>
                                </Stack>
                            </Stack>
                        </ShPaper>
                        <Box paddingTop={2} alignItems="center" justifyContent='center'>
                            <ShPaper variant="outlined">
                                <Stack direction={isSmScreen ? 'column' : 'row'} justifyContent='space-between' spacing={2}>
                                    <Box flex={1} maxWidth={400}>
                                        <ShTextFieldV2
                                            size="small"
                                            label="Search for Jobs"
                                            variant="outlined"
                                            fullWidth
                                            value={searchQuery}
                                            onChange={(e) => setSearchQuery(e.target.value)}
                                        />
                                    </Box>
                                    <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2}>
                                        <Box marginTop={2} maxWidth={400} minWidth={250}>
                                            <FormControl id="city-filter-label" variant="outlined" fullWidth size="small">
                                                <InputLabel>Filter by City</InputLabel>
                                                <Select
                                                    labelId="city-filter-label"
                                                    multiple
                                                    open={openCityFilter}
                                                    onOpen={() => setOpenCityFilter(true)}
                                                    onClose={() => setOpenCityFilter(false)}
                                                    value={selectedCities}
                                                    input={
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <TuneOutlinedIcon />
                                                                </InputAdornment>
                                                            }
                                                            label="Filter by City"
                                                        />
                                                    }
                                                    renderValue={(selected) => (
                                                        <Box>
                                                            {selected.join(', ').length === 2 ? 'Filter by City' : truncateText(selected.join(', '), 20)}
                                                        </Box>
                                                    )}>
                                                    {cities.length > 0 ? (
                                                        cities.map(city => (
                                                            <MenuItem
                                                                key={city}
                                                                value={city}
                                                                onClick={() => handleCityToggle(city)}
                                                            >
                                                                <Checkbox
                                                                    checked={selectedCities.includes(city)}
                                                                />
                                                                <Typography variant="body2">
                                                                    {city}
                                                                </Typography>
                                                            </MenuItem>
                                                        ))
                                                    ) : (
                                                        <MenuItem value="" disabled>No Cities Available</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </Box>
                                        <Box flex={1} maxWidth={250} minWidth={150}>
                                            <FormControl variant="outlined" fullWidth size="small">
                                                <InputLabel> Sort By</InputLabel>
                                                <Select
                                                    input={
                                                        <OutlinedInput
                                                            startAdornment={
                                                                <InputAdornment position="start">
                                                                    <SwapVertOutlinedIcon />
                                                                </InputAdornment>
                                                            }
                                                            label="Sort By"
                                                        />
                                                    }
                                                    value={sortOption} onChange={(e) => setSortOption(e.target.value)} label="Sort By" >
                                                    <MenuItem value="recent">Recent</MenuItem>
                                                    <MenuItem value="oldest">Oldest</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Stack>
                                </Stack>
                            </ShPaper>
                        </Box>
                        <Stack direction={isSmScreen ? 'column' : 'row'} >
                            <Stack marginTop={1} rowGap={2} >
                                <Typography textAlign='center' variant="subtitle1" paddingTop={1}> {filteredJobs?.length} Jobs found</Typography>
                                {getEmployerPostedJobsStatus === 'failed' && <Alert severity='error'>{getEmployerPostedJobsResponse}</Alert>}
                                {getEmployerPostedJobsStatus === 'pending' ?
                                    <Box marginTop={2} width='100%' display='flex' justifyContent='center'><CircularProgress size={100} color='success' /></Box> :
                                    paginatedJobs?.map(job =>
                                        <Stack minWidth={isXsScreen ? 200 : 400} maxWidth={isSmScreen ? '100%' : 400} key={job.id}>
                                            <ShPaper variant='outlined' key={job.id} sx={{ width: '100%' }} transElevateOnHover onClick={() => navigate(`/careers/${job.id}`)}>
                                                <CardHeader
                                                    action={
                                                        <ShGreenBtn size="small" onClick={() => { navigate(`/careers/${job.id}`) }}>
                                                            <Typography variant="caption" style={{ display: 'flex', alignItems: 'center' }}>
                                                                View Job
                                                                <ArrowForwardIcon sx={{ marginLeft: .5, fontSize: 15 }} />
                                                            </Typography>
                                                        </ShGreenBtn>
                                                    }
                                                    title={
                                                        <Typography variant="body2" paddingBottom={1} maxWidth={200}>
                                                            {job.name}
                                                        </Typography>
                                                    }
                                                    subheader={
                                                        <>
                                                            <Stack direction='row' alignItems='center' columnGap={1}>
                                                                <ScheduleIcon fontSize='small' />
                                                                <Typography variant="body2" color="text.secondary" alignItems='center' justifyContent='center'>
                                                                    {toTitleCase(moment(job.starts_at).fromNow())}
                                                                </Typography>
                                                            </Stack>
                                                            <Stack direction='row' alignItems='center' columnGap={1} marginTop={1}>
                                                                <PlaceIcon fontSize='small' />
                                                                <Typography variant="body2" color="text.secondary">
                                                                    {job.city && job.state ? `${job.city}, ${job.state}` : "Remote"}
                                                                </Typography>
                                                            </Stack>
                                                        </>
                                                    }
                                                />
                                            </ShPaper>
                                        </Stack>
                                    )}
                                {!employerPostedJobs &&
                                    <ShPaper variant="outlined">
                                        No Jobs Currently.
                                    </ShPaper>
                                }
                                {employerPostedJobs && totalPages > 1 && (
                                    <Box display="flex" justifyContent="center" margin={2}>
                                        <Pagination
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={(e, value) => setCurrentPage(value)}
                                            color="primary"
                                        />
                                    </Box>
                                )}
                            </Stack>
                            {!isSmScreen &&
                                <Stack paddingTop={1} rowGap={2} width='100%'>
                                        {jobExists ? (
                                            <Outlet />
                                        ) : (
                                            <Box display="flex" alignItems="center" justifyContent="center" height="100vh" textAlign="center">
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <WarningAmberIcon sx={{ color: 'orange', fontSize: 25 }} />
                                                    <Typography>Whoops, Job does not exist or is expired</Typography>
                                                </Stack>
                                            </Box>
                                        )}
                                </Stack>
                            }
                        </Stack>
                    </Box>
                </ShContainer>
            </Box>
        </Stack>
    );
}
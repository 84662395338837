import {
    Box,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    FormControlLabel,
    LinearProgress,
    Typography,
    Select,
    MenuItem,
    SelectChangeEvent,
    FormControl,
    InputLabel
} from "@mui/material";
import { useSelector } from 'react-redux';
import { RootState } from "shared/SharedModels";
import { IsXsScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useNotification } from "Modules/Core/Notification";
import { MouseEvent, useCallback, useEffect, useState, ReactNode } from "react";
import { EmailRegEx } from "shared/constants";
import { ShReactQuill } from "shared/SharedComponents/ShReactQuill/ShReactQuill";
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShButton, ShGreenBtn, ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { resetRejectionEmail, sendRejectionEmail } from "store/slices/employer/applicants/applicant-actions-slice";
import { fetchJobSummary, resetFetchJobSummary } from "store/slices/employer/jobs/jobs-list-slice";
import { RejectionEmailPayload } from "../ApplicantsConstants";
import { IRejectionEmailDialog, IRejectionEmailPayload } from "../ApplicantsModel";
import { ILetterTemplate } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/LettersModel";
import { addRemoveOrUpdateEmployerLetterTemplates, getEmployerLettersTemplates } from "store/slices/employer/employer/employer-details-slice";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { sortTemplates } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/LetterHelpers";
import { Star as StarIcon } from "@mui/icons-material";
import { changeApplicantStageInProfile } from "store/slices/employer/applicants/applicant-profile-slice";

export const RejectionEmailDialog = ({ isRejectionEmailDialogOpen, changeStage, setIsRejectionEmailDialogOpen,
    applicant, jobId }: IRejectionEmailDialog) => {

    const dispatch = useAppDispatch();
    const { employerDetails, employerLetters, getLettersStatus } = useSelector(
        (state: RootState) => state.employer.employerDetails
    );
    const notification = useNotification();
    const isXsScreen = IsXsScreen();
    const { rejectionEmailStatus, rejectionEmailResponse } = useAppSelector(state => state.employer.applicants.applicantActions);
    const { jobsSummary } = useAppSelector(state => state.employer.employerJobs.jobsList);
    const [rejectionEmailPayload, setRejectionEmailPayload] = useState<IRejectionEmailPayload>(() => {
        return { ...RejectionEmailPayload, to: applicant.email }
    });
    const [emailBodyEditorValue, setEmailBodyEditorValue] = useState<string>('');
    const [isSendEmailDisabled, setIsSendEmailDisabled] = useState<boolean>(true);
    const [isToEmailInvalid, setIsToEmailInvalid] = useState<boolean>(false);
    const [isCcEmailInvalid, setIsCcEmailInvalid] = useState<boolean>(false);
    const [isBccEmailInvalid, setIsBccEmailInvalid] = useState<boolean>(false);
    const [templateDesc] = useState("");
    const [ccEmails] = useState<string>("");
    const [isDefaultRejectionChecked, setIsDefaultRejectionChecked] = useState(false);
    const [localRejectionLetters, setLocalRejectionLetters] = useState<ILetterTemplate[]>(employerLetters || []);
    const [selectedLetter, setSelectedLetter] = useState<ILetterTemplate | null>(null);
    const [selectedTemplateId, setSelectedTemplateId] = useState(selectedLetter?.id || "");
    
    useEffect(() => {
        if (employerLetters) {
            const filteredTemplates = employerLetters.filter(
                template => template.templateType === 'Rejection'
            );
            const sortedTemplates = sortTemplates(filteredTemplates);
            setLocalRejectionLetters(sortedTemplates);
        }
    }, [employerLetters]);

    useEffect(() => {
        if (employerDetails?.id) {
            dispatch(getEmployerLettersTemplates({ empId: employerDetails?.id }));
        }
    }, [dispatch, employerDetails?.id]);

    const _sendRejectionEmail = () => {
        dispatch(sendRejectionEmail({ applicationId: applicant.candidateEmployerJobId, jobId: jobId, payload: rejectionEmailPayload }));
    }

    // Get Job summary to pre-populate email template.
    useEffect(() => {
        if (jobId) {
            if (!(jobId in jobsSummary) && jobsSummary[jobId]?.getSummaryStatus !== 'success') {
                dispatch(fetchJobSummary({ jobId: jobId }));
            }
        }
    }, [dispatch, jobId, jobsSummary]);

    useEffect(() => {
        if (jobsSummary[jobId]?.employerName && !rejectionEmailPayload.data) {
            setEmailBodyEditorValue(`<p>Dear ${applicant.fullName},</p>
                                <br/>
                                <p>Thank you for applying to ${jobsSummary[jobId].name} at ${jobsSummary[jobId].employerName}. 
                                We wanted to let you know that we have reviewed your resume.
                                Even though you have a great background we found that you would not be a good match for this position.
                                We regret to inform you that you are not shortlisted for the position of ${jobsSummary[jobId].name}.</p>
                                <br/>
                                <p>We wish you all the best in your job search and future professional endeavors.</p>
                                <br/>
                                <p>Regards, <br/>The Team at ${jobsSummary[jobId]?.employerName}</p>`);
            setRejectionEmailPayload(prevPayload => ({
                ...prevPayload,
                subject: prevPayload.subject || `Regarding your application at ${jobsSummary[jobId]?.employerName}`
            }));
            dispatch(resetFetchJobSummary(jobId));
        }
    }, [applicant.fullName, dispatch, jobId, jobsSummary, rejectionEmailPayload.data]);

    // Update editor value in payload as it changes.
    useEffect(() => {
        setRejectionEmailPayload((prev) => { return { ...prev, data: emailBodyEditorValue } });
    }, [emailBodyEditorValue]);

    const closeRejectEmailDialog = useCallback((e?: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
        if (e) {
            e.stopPropagation();
        }
        setIsRejectionEmailDialogOpen({ [applicant.candidateEmployerJobId]: false });
        setRejectionEmailPayload({ ...RejectionEmailPayload, to: applicant.email });
        setEmailBodyEditorValue('');
    }, [applicant.candidateEmployerJobId, applicant.email, setIsRejectionEmailDialogOpen]);

    const sendRejectionEmailWithNotificationFlag = () => {
        changeStage('rejected', applicant?.candidateEmployerJobId, true);
        dispatch(changeApplicantStageInProfile({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
    }

    useEffect(() => {
        if (rejectionEmailStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: rejectionEmailResponse ?? ''
            });
            dispatch(resetRejectionEmail());
            closeRejectEmailDialog();
            // dispatch(changeApplicantStageInList({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
            // dispatch(changeApplicantStageInProfile({ applicantId: applicant.candidateEmployerJobId, payload: { stage: 'rejected', sendEmail: true } }));
        }
    }, [applicant.candidateEmployerJobId, closeRejectEmailDialog, dispatch, notification,
        rejectionEmailResponse, rejectionEmailStatus]);

    // Disable send button.
    useEffect(() => {
        const { to, data, subject, cc, bcc } = rejectionEmailPayload;
        setIsSendEmailDisabled(!to || !data || !subject || isToEmailInvalid ||
            (cc?.length > 0 && isCcEmailInvalid) || (bcc?.length > 0 && isBccEmailInvalid));
    }, [isBccEmailInvalid, isCcEmailInvalid, isToEmailInvalid, rejectionEmailPayload]);

    // Email validation function with Regular Expression.
    const validateEmail = (e: { target: { value: string; }; }, field: 'to' | 'cc' | 'bcc') => {
        const reg = new RegExp(EmailRegEx);
        setRejectionEmailPayload({ ...rejectionEmailPayload, [field]: e.target.value })
        switch (field) {
            case 'to':
                setIsToEmailInvalid(!reg.test(e.target.value));
                break;
            case 'cc':
                setIsCcEmailInvalid(!reg.test(e.target.value));
                break;
            case 'bcc':
                setIsBccEmailInvalid(!reg.test(e.target.value));
                break;
            default:
                break;
        }
    };

    const handleDefaultRejectionCheckbox = () => {
            const newTemplate: ILetterTemplate = {
                id: Date.now(),
                dateCreated: new Date().toLocaleDateString(),
                templateName: "Rejection Template",
                templateDesc,
                subject: rejectionEmailPayload.subject,
                emailBody: emailBodyEditorValue,
                ccEmails: ccEmails.trim(),
                isPreferred: true,
                templateType: "Rejection",
            };

            if (employerDetails?.id) {
                dispatch(addRemoveOrUpdateEmployerLetterTemplates({
                    empId: employerDetails?.id,
                    action: 'add',
                    template: newTemplate
                }));
            }
    };

    const handleChange = (event: SelectChangeEvent<string | number>, child: ReactNode) => {
        const selectedId = event.target.value as string | number; // Cast to expected type
        setSelectedTemplateId(selectedId);
        const selectedTemplate = localRejectionLetters.find((template) => template.id === selectedId);
        if (selectedTemplate ) {
          handleLetterClick(selectedTemplate);
        }
        else {
            setEmailBodyEditorValue(`<p>Dear ${applicant.fullName},</p>
                <br/>
                <p>Thank you for applying to ${jobsSummary[jobId].name} at ${jobsSummary[jobId].employerName}. 
                We wanted to let you know that we have reviewed your resume.
                Even though you have a great background we found that you would not be a good match for this position.
                We regret to inform you that you are not shortlisted for the position of ${jobsSummary[jobId].name}.</p>
                <br/>
                <p>We wish you all the best in your job search and future professional endeavors.</p>
                <br/>
                <p>Regards, <br/>The Team at ${jobsSummary[jobId]?.employerName}</p>`);
        }
      };

      const handleLetterClick = (template: ILetterTemplate) => {
        setSelectedLetter(template);
    
        // Replace placeholders in the email body
        const updatedEmailBody = template.emailBody
            ?.replace(/\[CANDIDATE_NAME\]/g, `${applicant.fullName}`)
            ?.replace(/\[JOB_NAME\]/g, jobsSummary[jobId]?.name || 'the position')
            ?.replace(/\[YOUR_NAME\]/g, `${employerDetails?.first_name}`)
            ?.replace(/\[COMPANY_NAME\]/g, employerDetails?.company_name || 'our company');
    
        // Prefill the rejection email payload and email body editor
        setRejectionEmailPayload((prev) => ({
            ...prev,
            cc: template.ccEmails || prev.cc,
            subject: template.subject || prev.subject,
            data: updatedEmailBody || prev.data,
        }));
    
        setEmailBodyEditorValue(updatedEmailBody || template.emailBody || '');
    };

    return (<>
        <ShDialog open={isRejectionEmailDialogOpen} fullScreen={isXsScreen} onClose={() => closeRejectEmailDialog()}
            aria-labelledby="title" aria-describedby="sub_title" onClick={e => e.stopPropagation()} maxWidth='md'>
            <DialogTitle id='title' textAlign='center'>Rejection Email</DialogTitle>
            <DialogContent dividers>
                <Stack rowGap={2} height={'100%'}>
                    <ShTextFieldV2 id="email_to" label="To" variant="outlined" size='small' required
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.to}
                        onChange={e => validateEmail(e, 'to')} error={rejectionEmailPayload.to?.length > 0 && isToEmailInvalid}
                        helperText={rejectionEmailPayload.to?.length > 0 && isToEmailInvalid && 'Please enter a valid email'}
                    />
                    <ShTextFieldV2 id="email_cc" label="Cc" variant="outlined" size='small'
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.cc}
                        onChange={e => validateEmail(e, 'cc')} error={rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid}
                        helperText={rejectionEmailPayload.cc?.length > 0 && isCcEmailInvalid && 'Please enter a valid email'}
                    />
                    <ShTextFieldV2 id="email_bcc" label="Bcc" variant="outlined" size='small'
                        fullWidth InputLabelProps={{ shrink: true }} value={rejectionEmailPayload.bcc}
                        onChange={e => validateEmail(e, 'bcc')} error={rejectionEmailPayload.bcc?.length > 0 && isBccEmailInvalid}
                        helperText={rejectionEmailPayload.bcc?.length > 0 && isBccEmailInvalid && 'Please enter a valid email'}
                    />
                    <ShTextFieldV2 id="email_subject" label="Subject" variant="outlined" size='small' required
                        fullWidth InputLabelProps={{ shrink: true }}
                        value={rejectionEmailPayload.subject}
                        onChange={e => { setRejectionEmailPayload({ ...rejectionEmailPayload, subject: e.target.value }) }}
                    />
                    <Box sx={{ width: '100%' }}>
                        {getLettersStatus === 'pending' ? (
                            <Box width="100%" padding={2}>
                            <LinearProgress />
                            </Box>
                        ) : (
                            <FormControl fullWidth variant="outlined">
                                <InputLabel id="template-select-label" shrink>Select Email Template</InputLabel>
                                <Select
                                    labelId="template-select-label"
                                    value={selectedTemplateId}
                                    onChange={handleChange}
                                    displayEmpty
                                    label="Select Email Template" // Ensure the label is passed here
                                    fullWidth
                                    sx={{
                                    maxHeight: '400px',
                                    overflowY: 'auto',
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 1,
                                    },
                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                        borderColor: 'primary.main', // Add color when focused
                                    },
                                    }}
                                    renderValue={(selected) => {
                                    if (!selected) {
                                        return <em style={{ color: '#aaa' }}>Select Email Template</em>;
                                    }
                                    const selectedTemplate = localRejectionLetters.find((template) => template.id === selected);
                                    return selectedTemplate ? selectedTemplate.templateName : 'None';
                                    }}
                                >
                                    <MenuItem value="">
                                    <em>None</em>
                                    </MenuItem>
                                    {localRejectionLetters.map((template) => (
                                    <MenuItem key={template.id} value={template.id}>
                                        <Box display="flex" flexDirection="column">
                                        <Typography variant="body2" color="textPrimary">
                                            {template.templateName}
                                        </Typography>
                                        {template.isPreferred && (
                                            <Typography variant="caption" color="success.main" display="flex" alignItems="center">
                                            <StarIcon fontSize="small" sx={{ marginRight: 0.5 }} /> Preferred
                                            </Typography>
                                        )}
                                        <Typography variant="caption" color="textSecondary">
                                            {template.templateDesc}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            <strong>Subject:</strong> {template.subject}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            Created on: {template.dateCreated}
                                        </Typography>
                                        </Box>
                                    </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        )}
                    </Box>
                    {
                        jobsSummary[jobId]?.getSummaryStatus === 'pending' ? (
                        <ShAlert severity="info">Prefilling email template ...</ShAlert>
                        ) : (
                        <Box flex={1}>
                            <ShReactQuill
                            quillEditorValue={emailBodyEditorValue}
                            setQuillEditorValue={setEmailBodyEditorValue}
                            />
                        </Box>
                        )
                    }

                </Stack>
            </DialogContent>
            <DialogActions>
                <ShButton size='small' onClick={(e) => closeRejectEmailDialog(e)}>
                    Cancel
                </ShButton>
                <FormControlLabel
                    control={
                        <ShSwitch
                            name="defaultRejectionLetter"
                            color="primary"
                            sx={{ transform: 'scale(0.8)' }}
                            checked={isDefaultRejectionChecked}
                            onChange={(e) => setIsDefaultRejectionChecked(e.target.checked)}
                        />
                    }
                    label="Add & make this a default rejection letter."
                    sx={{ marginLeft: '-16px' }}
                />
                <ShGreenBtn size='small' onClick={() => {
                        if (isDefaultRejectionChecked) {
                            handleDefaultRejectionCheckbox();
                        }
                        _sendRejectionEmail();
                        sendRejectionEmailWithNotificationFlag();
                    }}
                    disabled={rejectionEmailStatus === 'pending' || isSendEmailDisabled}>
                    {rejectionEmailStatus === 'pending' ? 'Sending Email ...' : 'Send Email'}
                </ShGreenBtn>
            </DialogActions>
        </ShDialog>
    </>);
}
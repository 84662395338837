import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { Badge, Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactNode } from 'react';
import { ShOnHover } from 'shared/SharedStyles/styleConstants';

export const DashboardFeatureTile = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 10,
  textDecoration: 'none',
  cursor: 'pointer',
  minWidth: 260,
  maxWidth: 360,
  flex: 1,
  ...(ShOnHover(true, theme)),
}));

export const jobBoxStyles = {
  padding: 2,
  border: '1px solid rgba(0, 0, 0, 0.1)',
  borderRadius: 4,
  textDecoration: 'none',
  transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    background: '#DFE8F6',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  },
};

export const ShTimeline = styled(Timeline)(({ theme }) => ({
  padding: -1,
  '& .MuiTimelineItem-root': {
    '&:before': {
      flex: 0,
      padding: 0,
    },
  },
}));

interface CircleBoxProps {
  children: ReactNode;  // Type children as ReactNode
  size?: number;        // Optional size prop (default is 40px)
  color?: string;       // Optional color prop (default is grey)
}

export const CircleBox = ({ children, size = 40, color = 'rgba(0,0,0,0.1)' }: CircleBoxProps) => {
  return (
    <Box
      sx={{
        backgroundColor: color,  // Default grey color, but can be customized
        borderRadius: '50%',     // Circle shape
        width: size,             // Customize size of the circle
        height: size,            // Customize size of the circle
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',    // Center the icon inside the circle
        padding: 1,
      }}
    >
      {children}
    </Box>
  );
};
export const ShTimelineItem = styled(TimelineItem)(({ theme }) => ({
  paddingLeft: 0,
  marginLeft: 0,
  '& .MuiTimelineContent-root': {
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    flexGrow: 1,
    margin: 0,
    marginRight: theme.spacing(2),
  },
  '& .MuiTimelineOppositeContent-root': {
    margin: 0,
    textAlign: 'left',
  },
}));


interface CustomBadgeProps {
  badgeContent: ReactNode;  // Allow ReactNode for badgeContent
  color?: "primary" | "secondary" | "info" | "error" | "warning" | "success";
  bgColor?: string;
  children: ReactNode;
  invisible?: boolean;  // Add the invisible prop
  anchorOrigin?: {
    vertical: 'top' | 'bottom';
    horizontal: 'left' | 'right' | 'center';
  };
  sx?: any;  // Custom styles can be passed as sx
}

export const ShBadge: React.FC<CustomBadgeProps> = ({
  badgeContent,
  color = "info",
  bgColor = "#FFC107",
  children,
}) => {
  return (
    <Badge
      badgeContent={badgeContent}
      sx={{
        "& .MuiBadge-badge": {
          backgroundColor: bgColor,
          color: "#FFFFFF",
          fontSize: "0.75rem",
          fontWeight: "600",
          minWidth: "20px",
          height: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          transform: "translate(50%, -50%)",
          padding: "4px 6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: "#FFA000",
          },
        },
      }}
    >
      {children}
    </Badge>
  );
};


export const DASHBOARD_MENU_ITEM_HEIGHT = 48;
export const DASHBOARD_MENU_ITEM_PADDING_TOP = 8;
export const DashboardMenuProps = {
  PaperProps: {
    style: {
      maxHeight: DASHBOARD_MENU_ITEM_HEIGHT * 4.5 + DASHBOARD_MENU_ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


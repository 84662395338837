import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "shared/SharedModels";
import { fetchCandidateProfileAdditionalQuestionAnswers } from "store/slices/employer/applicants/applicant-profile-slice";
import { getSelectedJobForm } from "store/slices/employer/employer/employer-details-slice";
import { Typography, Box, RadioGroup, FormControlLabel, Radio, Stack } from '@mui/material';
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShTextFieldV2 } from "shared/SharedStyles/ShInputs";

export const ApplicantAdditionalQuestions = () => {
  const { applicantDetails } = useAppSelector((state) => state.employer.applicants.applicantProfile);
  const dispatch = useAppDispatch();
  const { jobId } = useParams();
  const { selectedJobForm } = useSelector((state: RootState) => state.employer.employerDetails);
  const { additionalQuestions } = useSelector((state: RootState) => state.employer.applicants.applicantProfile);

  useEffect(() => {
    if (jobId) {
      dispatch(getSelectedJobForm({ jobId }));
    }
  }, [jobId, dispatch]);


  useEffect(() => {
    if (applicantDetails?.candidateId) {
      dispatch(fetchCandidateProfileAdditionalQuestionAnswers({ candidateId: applicantDetails.candidateId }));
    }
  }, [dispatch, applicantDetails?.candidateId]);


  return (
    <Stack>
      <ShPaper variant="outlined" headerSection noBorderTop borderRadius={0}>
        <Typography variant='body2'> Additional Questions</Typography>
      </ShPaper>
      <ShPaper variant="outlined" noBorderTop borderRadius={0}>
        <Box>
          {selectedJobForm?.questions?.map((question) => {
            const answer = additionalQuestions?.[Number(question.id)]?.answer;
            const autoReject = additionalQuestions?.[Number(question.id)]?.autoReject;

            return (
              <ShPaper key={question.id} variant="outlined" sx={{ marginBottom: 2, padding: 2 }}>
                <Typography variant="body2" color="text.primary" sx={{ fontWeight: 'bold' }}>
                  {question.question}
                </Typography>

                {/* Handle textfield questions */}
                {question.type === 'textfield' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <ShTextFieldV2
                      fullWidth
                      label="Answer"
                      value={answer || ''}
                      disabled
                      variant="outlined"
                      sx={{ marginTop: 1 }}
                    />
                  </Box>
                ) : null}

                {/* Handle radio button questions */}
                {question.type === 'radio' ? (
                  <Box sx={{ marginTop: 1 }}>
                    <RadioGroup value={answer}>
                      {question.options.map((option) => (
                        <FormControlLabel
                          key={option.label}
                          value={option.label}
                          control={<Radio />}
                          disabled
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  </Box>
                ) : null}

                {/* Display answer for other question types like dropdown */}
                {question.type !== 'textfield' && question.type !== 'radio' && answer && (
                  <Box sx={{ marginTop: 1 }}>
                    <Typography variant="subtitle2" color="text.secondary">
                      <strong>Answer:</strong> {answer}
                    </Typography>
                  </Box>
                )}

                {/* Auto reject chip */}
                {autoReject && (
                  <ShChip
                    label="Auto Reject"
                    color="error"
                    sx={{ marginTop: 1, marginLeft: 1 }}
                  />
                )}
              </ShPaper>
            );
          })}
        </Box>
      </ShPaper>
    </Stack>
  );
};

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Paper,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShLoadingDots } from 'shared/SharedComponents/ShLoadingDots/ShLoadingDots';
import { ShBullets } from 'shared/SharedStyles/ShDataDisplay';
import { ShGreenBtn, ShToggleButtonGroup } from 'shared/SharedStyles/ShInputs';
import { completeAtsJobApplication, fetchEEOQuestionsJobApply, resetCompleteAtsJobApplication, updateJobRequirementAnswer } from 'store/slices/candidate/apply-job/apply-job-questionnaire.slice';
import { completeJobApplication } from 'store/slices/candidate/survey/survey-slice';
import { CandidateLoginRedirect } from '../AuthV2/AuthConstants';
import { useNotification } from '../Notification';
import { EqualOpportunityQuestions } from './ApplyJobEEOQuestions';
import { ApplyJobAdditionalQuestions } from './ApplyJobAdditionalQuestions';
import { getSelectedJobForm } from 'store/slices/employer/employer/employer-details-slice';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { RootState } from 'shared/SharedModels';

export const ApplyJobQuestionnaire = () => {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const notification = useNotification();
    const currentPath = location.pathname + location.search;
    const { jobApplicationRequirements, isQuestionnaireComplete,
        completeAtsJobApplicationStatus, completeAtsJobApplicationResponse, eeoQuestions, additionalQuestions} = useAppSelector((state) => state.candidate.applyJobQuestionnaire);
    const { candidateAssessmentMetadata } = useAppSelector((state) => state.metadata.assessmentMeta);
    const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
    const { jobDetailsByCode, getJobDetailsByCodeStatus} = useAppSelector(state => state.employer.employerJobs.jobInfo);
    const { completeJobApplicationStatus, completeJobApplicationResponse } = useAppSelector((state) => state.candidate.survey);
    const isSmScreen = IsSmScreen();
    const { selectedJobForm } = useSelector((state: RootState) => state.employer.employerDetails);
    const [isAllRequiredAnswered, setIsAllRequiredAnswered] = useState(false);
    const handleAllRequiredAnswered = (isValid: boolean) => {
        setIsAllRequiredAnswered(isValid);
    };
    const updateAnswer = (reqId: number, answerId: number) => {
        if (candidateDetails?.jobApplicationId) {
            dispatch(updateJobRequirementAnswer({
                jobId: candidateDetails?.jobApplicationId,
                payload: { requirementId: reqId, requirementLevelId: answerId }
            }))
        }
    }

    useEffect(() => {
        if (completeAtsJobApplicationStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: completeAtsJobApplicationResponse ?? ''
            });
            dispatch(resetCompleteAtsJobApplication());
            navigate(CandidateLoginRedirect);
        } else if (completeAtsJobApplicationStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: completeAtsJobApplicationResponse ?? ''
            });
        }
    }, [completeAtsJobApplicationResponse, completeAtsJobApplicationStatus, dispatch, navigate, notification]);

    const onSubmitInQuestionnaire = () => {
        if (candidateDetails?.jobApplicationId) {
            if (jobDetailsByCode?.ats_purchased) {
                dispatch(completeAtsJobApplication(candidateDetails?.jobApplicationId));
            } else {
                dispatch(completeJobApplication(candidateDetails?.jobApplicationId));
            }
        }
    }
    
    useEffect(() => {
        if (completeJobApplicationStatus === 'success') {
            notification.displayNotification({
                open: true,
                type: 'success',
                message: completeJobApplicationResponse ?? ''
            });
            navigate(CandidateLoginRedirect);
        } else if (completeJobApplicationStatus === 'failed') {
            notification.displayNotification({
                open: true,
                type: 'error',
                message: completeJobApplicationResponse ?? ''
            });
        }
    }, [completeJobApplicationResponse, completeJobApplicationStatus, dispatch, navigate, notification]);

    useEffect(() => {    
        if (jobDetailsByCode?.id) {
          dispatch(fetchEEOQuestionsJobApply({ jobId: jobDetailsByCode?.id.toString() }));
        }
      }, [jobDetailsByCode?.id, dispatch]);

      useEffect(() => {
        if (jobDetailsByCode) {
          dispatch(getSelectedJobForm({ jobId: jobDetailsByCode?.id.toString() }));
        }
      }, [dispatch, jobDetailsByCode]);
      

      useEffect(() => {
        if(selectedJobForm){
            const checkAllRequiredAnswered = () => {
            if (additionalQuestions && selectedJobForm) {
                const allAnswered = selectedJobForm?.questions.every((question) => {
                if (question.required !== "required") return true;
                
                
                return additionalQuestions[Number(question.id)]?.answer; // Ensure answer exists for required questions
                });
                setIsAllRequiredAnswered(allAnswered);
            }
            };
            checkAllRequiredAnswered();
        }else{
            setIsAllRequiredAnswered(true);
        }
      }, [additionalQuestions, selectedJobForm]);
    

    return (<>
        <Stack rowGap={2}>
            <Typography variant="body2">
                Please rate your experience level for each of the following questions.
                Your answers will be included in your application.
            </Typography>
            <Stack rowGap={1} minHeight={200} paddingRight={1}>
                {jobApplicationRequirements?.map((requirement) => (
                    <Paper variant="outlined" key={requirement.id}>
                        <Grid container padding={1} spacing={1} alignItems='center'>
                            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
                                <Typography variant="subtitle2">
                                    {requirement.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={7} xl={7} textAlign='end'>
                                <ShToggleButtonGroup color="primary" size="small" exclusive
                                    minWidth='100px' fullWidth={isSmScreen}
                                    orientation={isSmScreen ? 'vertical' : 'horizontal'}
                                    disabled={requirement?.updateJobRequirementAnswerStatus === 'pending'}
                                    value={requirement.applicant_requirement_level_id}>
                                    {candidateAssessmentMetadata?.skills[requirement.requirement_category_id].map((option) => (
                                        <ToggleButton value={option.id} key={option.id}
                                            onClick={() => option.id !== requirement.applicant_requirement_level_id &&
                                                updateAnswer(requirement.id, option.id)}>
                                            {requirement?.updateJobRequirementAnswerStatus === 'pending' &&
                                                requirement.updateJobRequirementAnswerId === option.id &&
                                                <ShLoadingDots variant='positive' />
                                            }
                                            <Typography variant='body2'>
                                                {option.name}
                                            </Typography>
                                        </ToggleButton>
                                    ))}
                                </ShToggleButtonGroup>
                            </Grid>
                        </Grid>
                    </Paper>
                ))}
            </Stack>

            {selectedJobForm &&
                <Accordion variant="outlined" defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content" id="additional-questions">
                        <Typography variant="body2">
                        Additional Questions
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <ApplyJobAdditionalQuestions 
                        selectedJobForm={selectedJobForm} 
                        onFormValidation={handleAllRequiredAnswered}
                        />
                    </AccordionDetails>
                </Accordion>
            }   
            {eeoQuestions && eeoQuestions?.length > 0 && 
                <Accordion variant='outlined' defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content" id="eeo-questions">
                        <Typography variant='body2'>
                            Equal Employment Opportuntity Questions
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Stack rowGap={1} minHeight={200} paddingRight={1}>
                            <EqualOpportunityQuestions eeoQuestions={eeoQuestions}/>
                        </Stack>
                    </AccordionDetails>
                </Accordion>
            }
            
            <Accordion variant='outlined'>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="preview-content" id="preview-header">
                    <Typography variant='body2'>
                        Preview
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ShBullets>
                        {jobApplicationRequirements?.map((requirement) => (
                            <li key={requirement.id}>
                                <Typography variant='caption'>{requirement.name} :&nbsp;
                                    <strong>
                                        {candidateAssessmentMetadata?.skills[requirement.requirement_category_id]
                                            .find(option => option.id === requirement.applicant_requirement_level_id)?.name}
                                    </strong></Typography>
                            </li>
                        ))}
                    </ShBullets>
                </AccordionDetails>
            </Accordion>
            <Stack direction='row' justifyContent='flex-end'>
                {jobDetailsByCode?.ats_purchased ?
                    <ShGreenBtn size='small' disabled={!isQuestionnaireComplete || !isAllRequiredAnswered} variant="outlined"
                        onClick={onSubmitInQuestionnaire}>
                        {completeAtsJobApplicationStatus === 'pending' ? 'Submitting Application...' : 'Submit'}</ShGreenBtn> :
                    <ShGreenBtn size='small' disabled={!isAllRequiredAnswered || !isQuestionnaireComplete || completeAtsJobApplicationStatus === 'pending'}
                        variant="outlined" onClick={() => navigate(`${currentPath.replace('/questionnaire', `/assessment`)}`)}>
                        Next</ShGreenBtn>}
            </Stack>
        </Stack>
    </>)
}
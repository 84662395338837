
export type ApplicantDetailsSection = 'profile' | 'resume' | 'comments' | 'questions' | 'stage' | 'eeo' | 'additionalquestions';

export enum ApplicantPathEnum {
    Profile = 'profile',
    Resume = 'resume',
    Comments = 'comments',
    Questions = 'questions',
    Stage = 'stage',
    EEO = 'eeo',
    AdditionalQuestions = 'additionalquestions'
};

export  const hashToSectionMap: { [key: string]: ApplicantDetailsSection } = {
    '#profile': 'profile',
    '#resume': 'resume',
    '#comments': 'comments',
    '#additionalquestions': 'additionalquestions',
    '#eeo': 'eeo',
    '#questions': 'questions',
};
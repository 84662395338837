import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  darken,
  lighten,
} from "@mui/material";
import { IsSmScreen, useAppDispatch, useAppSelector } from "helpers/hooks";
import { IApplicant, IApplicantsApiParams } from "Modules/Core/Applicants/ApplicantsModel";
import { IJobsApiParams } from "Modules/Core/JobsTs/JobsModel";
import { useCallback, useEffect, useState } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { PrimaryThemeColor, ShGreen } from 'shared/SharedStyles/styleConstants';
import { resetApplicantsState } from "store/slices/employer/applicants/applicants-list-slice";
import { fetchJobSummaryWidgetApplicantList } from "store/slices/employer/employer/dashboard/dashboard-summaray-widget-applicant-list";
import { fetchJobSummaryWidgetActiveJobsList } from "store/slices/employer/employer/dashboard/dashboard-summary-widget-active-jobs.slice";
import { setSelectedJobId } from "store/slices/employer/employer/dashboard/dashboard-summary-widget-selected-job-slice";
import { CircleBox, DashboardMenuProps, ShBadge } from "../Dashboard.styled";
import { DashboardListSize, DashboardTileBordeRadius, MaxDashboardApplicantSize } from "../DashboardConstants";
import { truncateText } from 'shared/utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ShGreenBtn } from "shared/SharedStyles/ShInputs";
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import { Link as RouterLink } from "react-router-dom";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CelebrationIcon from '@mui/icons-material/Celebration';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export const DashboardApplicantSummaryWidget = () => {
    const dispatch = useAppDispatch();
    const isSmScreen = IsSmScreen();
    const [candidateCount, setCandidateCount] = useState<number>(0);
    const [newStageCount, setNewStageCount] = useState<number>(0);
    const [interviewStageCount, setInterviewStageCount] = useState<number>(0);
    const [rejectedStageCount, setRejectedStageCount] = useState<number>(0);
    const [offerStageCount, setOfferStageCount] = useState<number>(0);
    const [hiredStageCount, setHiredStageCount] = useState<number>(0);
    const [phoneInterviewStageCount, setPhoneInteriewStageCount] = useState<number>(0);
    const [selectedJob, setSelectedJob] = useState<number | 0>(0);
    const [applicantList, setApplicantList] = useState<IApplicant[]>([]);

    const { jobsList: activeJobsList } = useAppSelector(state => state.employer.dashboard.dashboardSummaryWidgetJobList);
    const { applicantsList: applicantsData } = useAppSelector(state => state.employer.dashboard.dashboardSummaryWidgetApplicantList);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForJobSummaryWidget);

    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        dispatch(fetchJobSummaryWidgetActiveJobsList(params));
    }, [dispatch]);

    useEffect(() => {
        getJobs();
    }, [getJobs]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: MaxDashboardApplicantSize,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };
        dispatch(fetchJobSummaryWidgetApplicantList(params));
    }, [dispatch]);

    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);

    useEffect(() => {
        if (applicantsData) {
            setApplicantList(applicantsData);
        }
    }, [applicantsData]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);

            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);

    const handleDashboardApplicantSummaryChange = (event: SelectChangeEvent<string>) => {
        const jobId = parseInt(event.target.value);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }
    };

    useEffect(() => {
        const fetchData = () => {
            const stageCounts = applicantList.reduce<Record<string, number>>((acc, { jobStage }) => {
                acc[jobStage] = (acc[jobStage] || 0) + 1;
                return acc;
            }, {});

            setCandidateCount(applicantList.length);
            setNewStageCount(stageCounts.new || 0);
            setInterviewStageCount(stageCounts.interview || 0);
            setRejectedStageCount(stageCounts.rejected || 0);
            setOfferStageCount(stageCounts.offer || 0);
            setHiredStageCount(stageCounts.hired || 0);
            setPhoneInteriewStageCount(stageCounts.phone_screening || 0)
        };
        fetchData();

        return () => {
            dispatch(resetApplicantsState())
        };
    }, [activeJobsList, selectedJob, applicantList, dispatch]);

    return (
        <ShPaper flex={.5} variant="outlined" height='100%' borderRadius={DashboardTileBordeRadius}>
            <Stack direction={isSmScreen ? 'column' : 'row'} spacing={2} alignItems={isSmScreen ? 'left' : 'center'} marginBottom={1} justifyContent='space-between'>
                <Stack direction='row' spacing={2} alignItems='center'>
                    <Typography variant='body1' padding={.5}>Total Applications</Typography>
                    <ShChip textColor={darken(ShGreen, .6)} bgColor={lighten(ShGreen, .7)} variant='filled'
                        color='success' label={candidateCount} size='small' />
                </Stack>
                <Box width={250}>
                    <FormControl fullWidth size='small' disabled={activeJobsList.length === 0}>
                        <InputLabel style={{ fontSize: 'small' }}>{activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}</InputLabel>
                        <Select
                            style={{ fontSize: 'small' }}
                            MenuProps={DashboardMenuProps}
                            onChange={handleDashboardApplicantSummaryChange}
                            value={activeJobsList.length > 0 ? selectedJob.toString() : ''}
                            input={<OutlinedInput label="Select a JobsX" />}
                        >
                            {activeJobsList?.map((job) => (
                                <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                    {truncateText(job.name, 30)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </Stack>
            <Divider />
            <Stack direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} justifyContent="space-between" alignContent="center" paddingTop={2}>
      <Stack direction="row" alignItems="center" columnGap={4.5} padding={1}>
        {/* New Stage */}
        <Box>
          <Stack alignItems="center" spacing={0.5}>
            <ShBadge badgeContent={newStageCount === 0 ? "0" : newStageCount}  color="success">
              <CircleBox size={30} color={PrimaryThemeColor}> {/* Blue Circle */}
                <AddCircleIcon fontSize="small" sx={{ color: "#ffffff" }} /> {/* White Icon */}
              </CircleBox>
            </ShBadge>
            <Typography variant="subtitle2" align="center">New</Typography>
          </Stack>
        </Box>

        {/* Interview Stage */}
        <Box>
          <Stack alignItems="center" spacing={0.5}>
            <ShBadge badgeContent={(phoneInterviewStageCount ?? 0) + (interviewStageCount ?? 0) === 0 ? "0" : `${(phoneInterviewStageCount ?? 0) + (interviewStageCount ?? 0)}`} color="warning">
              <CircleBox size={30} color={PrimaryThemeColor}> {/* Blue Circle */}
                <EventAvailableIcon fontSize="small" sx={{ color: "#ffffff" }} /> {/* White Icon */}
              </CircleBox>
            </ShBadge>
            <Typography variant="subtitle2" noWrap>Interview</Typography>
          </Stack>
        </Box>

        {/* Rejected Stage */}
        <Box>
          <Stack alignItems="center" spacing={0.5}>
            <ShBadge badgeContent={rejectedStageCount === 0 ? "0" : rejectedStageCount} color="warning">
              <CircleBox size={30} color={PrimaryThemeColor}> {/* Blue Circle */}
                <CancelIcon fontSize="small" sx={{ color: "#ffffff" }} /> {/* White Icon */}
              </CircleBox>
            </ShBadge>
            <Typography variant="subtitle2" align="center">Rejected</Typography>
          </Stack>
        </Box>

        {/* Offer Stage */}
        <Box>
          <Stack alignItems="center" spacing={0.5}>
            <ShBadge badgeContent={offerStageCount === 0 ? "0" : offerStageCount} color="warning">
              <CircleBox size={30} color={PrimaryThemeColor}> {/* Blue Circle */}
                <CelebrationIcon fontSize="small" sx={{ color: "#ffffff" }} /> {/* Solid Confetti-like Icon */}
              </CircleBox>
            </ShBadge>
            <Typography variant="subtitle2" align="center">Offer</Typography>
          </Stack>
        </Box>

        {/* Hired Stage */}
        <Box>
          <Stack alignItems="center" spacing={0.5}>
            <ShBadge badgeContent={hiredStageCount === 0 ? "0" : hiredStageCount} color="warning">
              <CircleBox size={30} color={PrimaryThemeColor}> {/* Blue Circle */}
                <CheckCircleIcon fontSize="small" sx={{ color: "#ffffff" }} /> {/* White Icon */}
              </CircleBox>
            </ShBadge>
            <Typography variant="subtitle2" align="center">Hired</Typography>
          </Stack>
        </Box>
      </Stack>

      <Box alignContent="center" flex={0.5} justifyContent='center' >
        <ShGreenBtn variant="outlined" size="small" component={RouterLink} to={`/employer/applicants/${selectedJob}`} endIcon={<EastOutlinedIcon />}>
          View All
        </ShGreenBtn>
      </Box>
    </Stack>

        </ShPaper>
    );
};

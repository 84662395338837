import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Alert, Box, Divider, LinearProgress, MenuItem, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { IApplicantsApiParams } from "Modules/Core/Applicants/ApplicantsModel";
import { IJobsApiParams } from "Modules/Core/JobsTs/JobsModel";
import { useCallback, useEffect, useState } from "react";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { fetchDashboardActiveJobsList } from "store/slices/employer/employer/dashboard/dashboard-active-jobs-slice";
import { fetchDashboardApplicantsList } from "store/slices/employer/employer/dashboard/dashboard-applicants-slice";
import { setSelectedJobId } from "store/slices/employer/employer/dashboard/dashboard-recent-activity-selected-job-slice";
import { DashboardMenuProps, ShTimeline, ShTimelineItem } from "../Dashboard.styled";
import { DashboardListSize, DashboardTileBordeRadius, getDotPropertiesAndMessage } from "../DashboardConstants";
import { truncateText } from 'shared/utils';
import { PrimaryThemeColor } from 'shared/SharedStyles/styleConstants';
import { ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

export const DashboardRecentActivityWidget = () => {
    const [selectedJob, setSelectedJob] = useState<number>(0);
    const [selectedJobName, setSelectedJobName] = useState<string>("");

    const { jobsList: activeJobsList, getJobsListStatus } = useAppSelector(state => state.employer.dashboard.dashboardActiveJobs);
    const { applicantsList: applicantsData } = useAppSelector((state) => state.employer.dashboard.dashboardApplicants);
    const { selectedJobId } = useAppSelector(state => state.employer.dashboard.selectedJobForRecentActivity);

    const dispatch = useAppDispatch();
    const getJobs = useCallback(() => {
        const params: IJobsApiParams = {
            jobType: 'active',
            pageNumber: 1,
            pageSize: DashboardListSize,
            sortDirection: 'desc',
            sortColumn: 'created_at',
            search: '',
        };
        // FOR FETCHING DRAFT JOBS
        dispatch(fetchDashboardActiveJobsList(params));
    }, [dispatch]);

    // Get jobs list on page load.
    useEffect(() => {
        getJobs();
    }, [dispatch, getJobs]);

    const getApplicants = useCallback((jobId: number) => {
        const params: IApplicantsApiParams = {
            jobId: jobId,
            pgNo: 1,
            pgSize: 50,
            sortCol: 'first_name',
            sortDir: 'asc',
            search: '',
        };

        dispatch(fetchDashboardApplicantsList(params));
    }, [dispatch]);

    // Get applicants list when summary panel is toggled and has applicants.
    useEffect(() => {
        if (selectedJob !== 0) {
            getApplicants(selectedJob);
        }
    }, [getApplicants, selectedJob]);

    useEffect(() => {
        if (activeJobsList && activeJobsList.length > 0) {
            let jobIdToUse: number;

            if (selectedJobId !== null) {
                jobIdToUse = selectedJobId;
            }

            const selectedJobData = activeJobsList.find((job) => job.id === jobIdToUse);
            setSelectedJobName(selectedJobData?.name ?? '')
            if (selectedJobData) {
                dispatch(setSelectedJobId(selectedJobData.id));
                setSelectedJob(selectedJobData.id)
            } else {
                const firstActiveJob = activeJobsList[0];
                dispatch(setSelectedJobId(firstActiveJob.id));
            }
        }
    }, [activeJobsList, applicantsData, selectedJobId, dispatch]);


    const handleDashboardRecentActivityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const jobId = parseInt(event.target.value as string);
        if (jobId) {
            dispatch(setSelectedJobId(jobId));
        }
    };
    

    /*
    * If the applicant is new, then use the completedAtDate when sorting, otherwise use the stageModifiedAt Date
    */
    const sortedApplicantList = [...applicantsData].sort((a, b) => {
        const dateA = (a.jobStage === 'new') ? new Date(a.applicationCompletedAt) : new Date(a.stageModifiedAt);
        const dateB = (b.jobStage === 'new') ? new Date(b.applicationCompletedAt) : new Date(b.stageModifiedAt);
        return dateB.getTime() - dateA.getTime();
    });

    return (
        <ShPaper height='100%' marginBottom='10px' variant='outlined' borderRadius={DashboardTileBordeRadius} overflow='auto'>
            <Stack direction='row' justifyContent='space-between' padding={1}>
                <Typography variant='subtitle1' sx={{ display: 'flex', alignItems: 'center' }}>
                    <AccessAlarmIcon color='primary' sx={{ marginRight: 1 }} />
                    Activity
                </Typography>
                <Box minWidth='150px'>              
                    <ShTextFieldV2
                        size="small"
                        disabled={activeJobsList.length === 0}
                        select
                        label={activeJobsList.length > 0 ? 'Select a Job' : 'No Active Jobs'}
                        value={activeJobsList.length > 0 ? selectedJob.toString() : 'No Active Jobs'}
                        onChange={handleDashboardRecentActivityChange}
                        SelectProps={{
                            MenuProps: DashboardMenuProps,
                        }}>
                        {activeJobsList.map((job) => (
                            <MenuItem style={{ fontSize: 'small' }} key={job.id} value={job.id}>
                                <Typography variant='body2'>{truncateText(job.name, 25)}</Typography>
                            </MenuItem>
                        ))}
                    </ShTextFieldV2>
                </Box>
            </Stack>
            {getJobsListStatus === "pending" ?<> <LinearProgress /></> :
                <Stack>
                    {sortedApplicantList?.length > 0 ?
                        <>
                        <Divider/>
                        <Stack direction='row' marginBottom={2} maxWidth={800} justifyContent='center'>
                                <ShTimeline>
                                    {sortedApplicantList.map((applicant, index) => {
                                        const { dotColor, dotVariant, message } = getDotPropertiesAndMessage(applicant, selectedJobName);
                                        return (
                                            <ShTimelineItem key={index}>
                                                <TimelineOppositeContent color="text.secondary">
                                                <ShChip
                                                    size='small'
                                                    customSize='small'
                                                    borderRadius='12px'
                                                    textColor='white'
                                                    bgColor={PrimaryThemeColor}
                                                    label={new Date(applicant.stageModifiedAt).toLocaleString([], { weekday: 'short', hour: '2-digit', minute: '2-digit' })}
                                                    />
                                                </TimelineOppositeContent>
                                                <TimelineSeparator>
                                                    <TimelineDot color={dotColor} variant={dotVariant} />
                                                    {index !== sortedApplicantList.length - 1 && <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    <Typography variant='subtitle2' >
                                                        {message}
                                                    </Typography>
                                                </TimelineContent>
                                            </ShTimelineItem>
                                        );
                                    })}
                                </ShTimeline>
                        </Stack>
                        </>
                        :
                        <Alert severity="info">No Recent Activity</Alert>
                    }
                </Stack>
            }
        </ShPaper>
    )
}
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Badge, Stack, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import moment from "moment-timezone"; // Make sure to import moment-timezone
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import { ShAvatar, ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShDarkGreen } from 'shared/SharedStyles/styleConstants';
import { getInitialsFromName, stringToColor } from "shared/utils";
import { DashboardTileBordeRadius } from '../DashboardConstants';
import { useEffect } from 'react';
import { getAccInfo, resetUpdateAccInfo } from 'store/slices/employer/settingsTs/acc-info-slice';

export const DashboardEmployerWelcomeWidget = () => {
    const dispatch = useAppDispatch();
    const { firstName, middleName, lastName, accountAccess, accountLoginInfo, twoStepAuth } = useAppSelector((state) => state.auth.auth);
    const userInitials = getInitialsFromName(`${firstName} ${lastName}`, true, true)
    const getAvatarBackgroundColor = stringToColor(userInitials);
    const { accountInfo} = useAppSelector((state) => state.employer.employerSettings.accInfo);
    const userTimezone = accountInfo.timezone || moment.tz.guess();
    const currentHour = moment.tz(userTimezone).hour();
    
    useEffect(() => {
        if (!accountInfo.id) {
            dispatch(getAccInfo());
        }
        return () => {
            dispatch(resetUpdateAccInfo());
        }
    }, [accountInfo.id, dispatch]);
    
    const getGreetingMessage = () => {
        if (currentHour < 12) {
            return "Good Morning";
        } else if (currentHour < 18) {
            return "Good Afternoon";
        } else {
            return "Good Evening";
        }
    }

    return (
        <ShPaper variant="outlined" height="100%" borderRadius={DashboardTileBordeRadius}>
            <Stack padding={2} direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }}
                justifyContent='space-between' alignContent='center'>
                <Stack flexDirection='row' alignItems='center' columnGap={2}>
                    <Badge
                        overlap="rectangular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        badgeContent={!twoStepAuth?.emailVerification && !twoStepAuth?.securityVerification &&
                            <ShTooltip title="Two-Step Authentication is not enabled. Your account/data may be vulnerable!"
                                placement='bottom-start'>
                                <ErrorOutlineIcon color='warning' />
                            </ShTooltip>
                        }>
                        <ShAvatar className="dashboard-user-avatar" backgroundColor={getAvatarBackgroundColor}>
                            <Typography variant='h6'>
                                {userInitials.toUpperCase()}
                            </Typography>
                        </ShAvatar>
                    </Badge>
                    <Typography variant="h6">
                        {getGreetingMessage()}, {[firstName, middleName, lastName].filter(Boolean).join(' ')}
                    </Typography>

                    {/* Replaces '-' with space */}
                    <ShChip 
                        borderRadius='25'
                        textColor="#ffffff" 
                        bgColor={ShDarkGreen}   
                        label={accountAccess.role?.toUpperCase().replace(/_/g, ' ')}
                    />

                </Stack>
            </Stack>
            {accountLoginInfo?.last_sign_in_at &&
                <Typography padding={1} textAlign='left' variant="subtitle2" sx={{opacity:.7}}>
                    Last login: {moment(accountLoginInfo?.last_sign_in_at)?.fromNow()}
                </Typography>
            }
        </ShPaper>
    );
}

import { ErrorPage } from "Modules/Core/RedirectPages/ErrorPage";
import { AccessLevels } from "Modules/Core/SettingsTs/AccountSettings/AccessLevels/AccessLevels";
import { AccountInfo } from "Modules/Core/SettingsTs/AccountSettings/AccountInfo";
import { AccountSettings } from "Modules/Core/SettingsTs/AccountSettings/AccountSettings";
import { CompanyInfo } from "Modules/Core/SettingsTs/AccountSettings/CompanyInfo";
import { Security } from "Modules/Core/SettingsTs/AccountSettings/Security/Security";
import { Criteria } from "Modules/Core/SettingsTs/ToolsAndAutomation/Automation/AutoReject/Criteria";
import { Notifications } from "Modules/Core/SettingsTs/ToolsAndAutomation/Automation/Notifications";
import { InterviewReminders } from "Modules/Core/SettingsTs/ToolsAndAutomation/Automation/Reminders/InterviewReminders";
import { ApplicationReceived } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/ApplicationConfirmation";
import { InterviewInvitation } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/InterviewInvitation";
import { OfferLetter } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/OfferLetter";
import { RejectionNotification } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/EmailTemplates/RejectionNotification";
import { FormTemplates } from "Modules/Core/SettingsTs/ToolsAndAutomation/Tools/FormTemplates/FormTemplates";
import { ToolsAndAutomation } from "Modules/Core/SettingsTs/ToolsAndAutomation/ToolsAndAutomation";
import { Navigate, Route, Routes } from "react-router-dom";

export const SettingsRouter = () => {

    return (<>
        <Routes>
            {/* Error 404 page */}
            <Route path="*" element={<ErrorPage />} />
            <Route path="/account" element={<AccountSettings />} >
                <Route path="/account" element={<Navigate replace to="/employer/settings/account/account-information" />} />
                <Route path="/account/account-information" element={<AccountInfo />} />
                <Route path="/account/company-information" element={<CompanyInfo />} />
                <Route path="/account/access-levels" element={<AccessLevels />} />
                <Route path="/account/security" element={<Security />} />
                <Route path="/account/branding" element={<></>} />
                <Route path="/account/integrations" element={<></>} />
            </Route>
            <Route path="/" element={<ToolsAndAutomation />} >
                <Route path="/tools" element={<Navigate replace to="/employer/settings/tools" />} />
                <Route path="/tools/templates/rejection-notification" element={<RejectionNotification />} />
                <Route path="/tools/templates/offer-letter" element={<OfferLetter />} />
                <Route path="/tools/templates/interview-invitation" element={<InterviewInvitation />} />
                <Route path="/tools/templates/application-received" element={<ApplicationReceived />} />
                <Route path="/tools/templates/form-templates" element={<FormTemplates />} />
                <Route path="/automation/auto-reject/criteria" element={<Criteria />} />
                <Route path="/automation/reminders/interview-reminders" element={<InterviewReminders />} />
                <Route path="/automation/notifications" element={<Notifications />} />
            </Route>
        </Routes>
    </>)
}
import {
    Box,
    DialogContent,
    Divider,
    Fade,
    FormControl,
    FormControlLabel,
    Grid,
    Grow,
    IconButton,
    InputAdornment,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";
import { ShAlert, ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShTextFieldV2, ShButton, ShGreenBtn, ShToggleButtonGroup } from "shared/SharedStyles/ShInputs";
import { ShSwitch } from "shared/SharedStyles/ShNavigation";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { useEffect, useState } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { IQuestion, IForm, FormTemplatesProps, AdditionalQuestionTypes } from "./FormTemplateModel";
import AddIcon from "@mui/icons-material/Add";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { FormTemplateHeader } from "./FormTemplateHeader";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSelector } from "react-redux";
import { RootState } from "shared/SharedModels";
import { IsXsScreen, useAppDispatch } from "helpers/hooks";
import { addRemoveOrUpdateEmployerFormTemplates, getEmployerFormsTemplates, getSelectedJobForm, saveSelectedJobForm } from "store/slices/employer/employer/employer-details-slice";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useParams } from "react-router-dom";
import { CreateJobFormHeader } from "./CreateJobFormHeader";
import AdditionalQuestionPreviewDialog from "Modules/Core/CreateJob/AdditionalQuestionPreviewDialog";
import EditIcon from '@mui/icons-material/Edit';
import { PrimaryThemeColor } from "shared/SharedStyles/styleConstants";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { FormTemplatePrefilledOptions } from "./FormTemplatePrefilledOptions";

export const FormTemplates: React.FC<FormTemplatesProps> = ({ usedInCreateJob, onFormSelect }) => {
    const dispatch = useAppDispatch();
    const { jobId } = useParams(); 
    const { getFormsStatus, employerForms, employerDetails, selectedJobForm} = useSelector(
        (state: RootState) => state.employer.employerDetails
    );
    const [formTitle, setFormTitle] = useState("");
    const [questions, setQuestions] = useState<IQuestion[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [previewDialogOpen, setPreviewDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [questionText, setQuestionText] = useState("");
    const [questionType, setQuestionType] = useState<AdditionalQuestionTypes>('textfield');
    const [questionRequired, setQuestionRequired] = useState('optional');
    const [IsPreferred, setIsPreferred] = useState(false);
    const handleDialogOpen = () => setDialogOpen(true);
    const [isEditMode, setIsEditMode] = useState(false); 
    const [currentFormId, setCurrentFormId] = useState<string | null>(null); 
    const [currentForm, setCurrentForm] =  useState<IForm | null>(null);
    const [selectedLocalFormInCreateJob, setIsSelectedLocalFormInCreateJob] =  useState<IForm | null>(null);
    const handleDialogClose = () => {
        setDialogOpen(false);
        setDeleteDialogOpen(false)
        setIsEditMode(false);
        setCurrentFormId(null);
        resetForm();
        resetQuestionForm();
    };
    const isXsScreen = IsXsScreen();
    const handleTypeChange = (event: SelectChangeEvent<AdditionalQuestionTypes>) => {
        setQuestionType(event.target.value as AdditionalQuestionTypes);
        
    };
    const handleRequiredChange = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
        if (newValue !== null) {
            setQuestionRequired(newValue);
        }
    };
    const handleOpenPreview  = (form: IForm) => {
        setIsSelectedLocalFormInCreateJob(form);
        setPreviewDialogOpen(true);
      };
    

    useEffect(() => {
        if (employerDetails?.id) {
            dispatch(getEmployerFormsTemplates({ empId: employerDetails?.id }));
        }
    }, [dispatch, employerDetails?.id]);
    
    const handleSaveForm = async () => {
        // Filter out empty options from questions
        const cleanedQuestions = questions.map((question) => ({
            ...question,
            options: question.options.filter((option) => option.label.trim() !== ""), // Remove empty options
        }));
    
        if (isEditMode && currentFormId) {
            const updatedForms = employerForms?.map((form) =>
                form.id === currentFormId
                    ? { ...form, title: formTitle, questions: cleanedQuestions, IsPreferred: IsPreferred }
                    : form
            );
    
            const updatedForm = updatedForms?.find((form) => form.id === currentFormId);
            if (updatedForm && employerDetails?.id) {
                await dispatch(addRemoveOrUpdateEmployerFormTemplates({
                    empId: employerDetails.id,
                    action: 'update',
                    form: updatedForm,
                }));
    
                if (jobId && (selectedJobForm?.id === updatedForm.id)) {
                    dispatch(saveSelectedJobForm({ jobId, selectedForm: updatedForm }));
                }
            }
        } else {
            const newForm = {
                id: Date.now().toString(),
                title: formTitle,
                questions: cleanedQuestions, // Use cleaned questions
                IsPreferred: IsPreferred,
            };
    
            if (employerDetails?.id) {
                await dispatch(addRemoveOrUpdateEmployerFormTemplates({
                    empId: employerDetails?.id,
                    action: 'add',
                    form: newForm
                }));
            }
        }
    
        if (employerDetails?.id) {
            dispatch(getEmployerFormsTemplates({ empId: employerDetails?.id }));
        }
        resetForm();
        handleDialogClose();
    };
    
    const resetQuestionForm = () => {
        setQuestionText("");
        setQuestionType('textfield');
        resetForm();
    };

    const resetForm = () => {
        setFormTitle("");
        setQuestions([]);
    };

    const onDragEnd = (result: any) => {
        const { destination, source } = result;
        if (!destination) return; // If dropped outside a droppable area
        if (destination.index === source.index) return; // If the item was dropped in the same position
        const reorderedQuestions = Array.from(questions);
        const [removed] = reorderedQuestions.splice(source.index, 1);
        reorderedQuestions.splice(destination.index, 0, removed);
        setQuestions(reorderedQuestions);
    };
    
    const handleRemoveOption = (qIndex: number, oIndex: number) => {
        const updatedQuestions = [...questions];
        if (updatedQuestions[qIndex] && updatedQuestions[qIndex].options) {
            const updatedOptions = [...updatedQuestions[qIndex].options];            
            if (oIndex >= 0 && oIndex < updatedOptions.length) {
                updatedOptions.splice(oIndex, 1);
    
                
                updatedQuestions[qIndex] = {
                    ...updatedQuestions[qIndex], 
                    options: updatedOptions,      
                };
    
                setQuestions(updatedQuestions);
            } else {
                console.error('Option index is out of bounds');
            }
        }
    };

    const handleAddOption = (qIndex: number) => {
        const updatedQuestions = [...questions];
        
        const updatedOptions = [...updatedQuestions[qIndex].options, { label: "", autoReject: false }];
        
        updatedQuestions[qIndex] = {
            ...updatedQuestions[qIndex],
            options: updatedOptions,
        };
        
        setQuestions(updatedQuestions);
    };
    
    const handleOptionChange = (qIndex: number, oIndex: number, value: string, isAutoReject: boolean) => {
        const updatedQuestions = [...questions];
    
        const updatedOptions = [...updatedQuestions[qIndex].options];
    
        updatedOptions[oIndex] = {
            label: value,
            autoReject: isAutoReject,
        };
    
        updatedQuestions[qIndex] = {
            ...updatedQuestions[qIndex],
            options: updatedOptions,
        };
    
        // Set the updated questions array into state
        setQuestions(updatedQuestions);
    };
    
    const handleAddQuestion = () => {
        setQuestionText("");
        const newQuestion: IQuestion = {
            id: Date.now().toString(),
            question: questionText,
            type: questionType,
            options: [],
            required: questionRequired,
        };

        setQuestions([...questions, newQuestion]);
    };
    
   // Delete question handler
    const handleDeleteQuestion = (qIndex: number) => {
        const updatedQuestions = [...questions];
        updatedQuestions.splice(qIndex, 1);  // Remove the question at the given index
        setQuestions(updatedQuestions);
    };

    const handleEditForm = (formId: string) => {

        if(employerForms){
        const formIndex = employerForms.findIndex((form) => form.id === formId);
        if (formIndex === -1) {
            console.error(`Form with ID ${formId} not found.`);
            return;
        }
        const selectedForm = employerForms[formIndex];
        setIsPreferred(selectedForm.IsPreferred)
        setFormTitle(selectedForm.title);
        setQuestions(selectedForm.questions);
        setCurrentFormId(formId); // Set the current form ID
        setIsEditMode(true); // Enable edit mode
        setDialogOpen(true); // Open the dialog
    }
    };

    const handleDeleteForm = async (form: IForm) => {
         // Dispatch action to add the new form
         if (employerDetails?.id) {
            await dispatch(addRemoveOrUpdateEmployerFormTemplates({
                empId: employerDetails?.id,
                action: 'remove',
                form: form
            }));
            dispatch(getEmployerFormsTemplates({ empId: employerDetails?.id }));
            setDeleteDialogOpen(false)
        }
    };
    
    const handleSelectFormInCreateAJob = (form: IForm) => {
        // If the clicked form is already selected, unselect it; otherwise, select it
        const emptyForm: IForm = {
            id: '-1',
            title: 'Empty',
            questions: [],
            IsPreferred: false,
          };

        if (selectedLocalFormInCreateJob?.id === form.id) {
            if(jobId){
                setIsSelectedLocalFormInCreateJob(form);
                dispatch(saveSelectedJobForm({ jobId, selectedForm: emptyForm }));
            }
            setIsSelectedLocalFormInCreateJob(null);
        } else {
            if(jobId){
                setIsSelectedLocalFormInCreateJob(form);
                dispatch(saveSelectedJobForm({ jobId, selectedForm: form }));
            }
        }
        if (form && onFormSelect) {
            onFormSelect(form); 
        }
    };

    useEffect(() => {
        if (jobId && usedInCreateJob) {
            dispatch(getSelectedJobForm({ jobId }));
           
        }
    }, [jobId, dispatch, usedInCreateJob]);

    useEffect(() => {
        // Set the fetched form as selected if it exists
        if(usedInCreateJob){
            setIsSelectedLocalFormInCreateJob(selectedJobForm || null);
        }
    }, [selectedJobForm, usedInCreateJob]);

    return (
        <>
            <Fade in={true} timeout={800}>
                    <Stack>
                        {usedInCreateJob ? <CreateJobFormHeader/>:<FormTemplateHeader/>}
                        {/* Display Created Forms */}
                        <ShPaper variant="outlined" borderRadius={0}>
                            <Typography variant="subtitle2" paddingLeft={2}>
                                Add a questionnaire or choose an existing one
                            </Typography>
                            {getFormsStatus === 'pending' ? (
                                    <Box width={'100%'} padding={2}>
                                        <LinearProgress />
                                    </Box>
                                ) : (
                            <Grid container spacing={2} padding={2}>
                                    {employerForms?.map((form, index) => (
                                    <Grid item xs={12} xl={12} key={index}>
                                        <ShPaper  transElevateOnHover variant="outlined"  onClick={() => handleEditForm(form.id)} cursor="pointer">  
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                <Stack direction='row' spacing={2} alignItems='center'>
                                                    {usedInCreateJob && 
                                                        <ShSwitch color="success" checked={selectedLocalFormInCreateJob?.id === form.id} 
                                                            onClick={(e) => e.stopPropagation()} 
                                                            onChange={() => {handleSelectFormInCreateAJob(form)}}
                                                        />
                                                    }
                                                    <Typography variant="subtitle2">
                                                    {form.title}
                                                    </Typography>
                                                    {form.IsPreferred && 
                                                        <ShChip size='small' label={"Preferred"} bgColor="rgba(76, 175, 80, 0.2)"  textColor="#2E7D32"/>
                                                    }
                                                </Stack>

                                                <Stack direction="row" spacing={1}>
                                                    <IconButton onClick={(e) => {e.stopPropagation(); handleOpenPreview(form);}} 
                                                        color="primary" >
                                                        <VisibilityIcon />
                                                    </IconButton>
                                                    <Divider orientation="vertical" flexItem sx={{ height: 36 }} />
                                                    <IconButton onClick={(e) => {}} 
                                                        color="warning" >
                                                        <EditIcon />
                                                    </IconButton>
                                                    <Divider orientation="vertical" flexItem sx={{ height: 36 }} />
                                                    <IconButton onClick={(e) => {e.stopPropagation();  setDeleteDialogOpen(true); setCurrentForm(form) }}  color="error">
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Stack>
                                            </Stack>
                                        </ShPaper>
                                    </Grid>
                                    ))}
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Box height={175} display="flex" alignItems="center" justifyContent="center" border="2px dashed #74C05A">
                                        <IconButton onClick={handleDialogOpen} sx={{ color: "green", fontSize: 35 }}>
                                            <AddIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </Grid>
                            </Grid>)}
                        </ShPaper>
                        
                        <ShDialog open={dialogOpen} onClose={handleDialogClose} maxWidth='xl'>
                            <ShPaper variant="outlined" borderRadius={0} noBorderTop headerSection>
                                <Box display="flex" alignItems="center">
                                    {isEditMode ? <EditIcon color='primary' fontSize="small" /> : <AddIcon color='primary' fontSize="small" />}
                                    <Typography variant="h6" marginLeft={1}>
                                        {isEditMode ? "Edit Questionnaire Form" : "Create Questionnaire Form"}
                                    </Typography>
                                </Box>
                            </ShPaper>
                            <DialogContent>
                                <Stack direction='row' spacing={2}>
                                    <Stack spacing={2}>
                                        <ShTextFieldV2  size='small'  label="Questionnaire Title" fullWidth value={formTitle} 
                                        onChange={(e) => setFormTitle(e.target.value)} error={!formTitle} helperText={!formTitle && "Questionnaire Title is required."}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography variant="caption" mr={1}>Preferred Template</Typography>
                                                    <ShSwitch
                                                        color='primary'
                                                        checked={IsPreferred}
                                                        onChange={() => setIsPreferred(!IsPreferred)} 
                                                    />
                                                </InputAdornment>
                                            )}}/>  
                                        <ShPaper variant="outlined" headerSection sx={{maxHeight:'250px'}}>
                                            <Grid container columnGap={2} rowGap={2} alignItems='center'>
                                                <Typography variant="subtitle2" ml={1}> Enter a question, its type, and whether its optional</Typography>
                                                
                                                {/* Question Creation Text Field */}
                                                <Grid item xs={12}>
                                                    <ShTextFieldV2
                                                        size="small"
                                                        label="Question Text"
                                                        fullWidth
                                                        value={questionText}
                                                        onChange={(e) => setQuestionText(e.target.value)}
                                                        error={!questionText.trim()}
                                                        helperText={!questionText.trim() ? "Please enter a question." : ""}
                                                    />
                                                </Grid>

                                                {/* Question Type Dropdown */}
                                                <Grid item xs={6} sm={4}>
                                                    <FormControl fullWidth size="small">
                                                        <InputLabel id="question-type-label">Question Type</InputLabel>
                                                        <Select
                                                            labelId="question-type-label"
                                                            value={questionType}
                                                            onChange={handleTypeChange}
                                                            label="Question Type"
                                                        >
                                                            <MenuItem value="textfield">Textfield</MenuItem>
                                                            <MenuItem value="radio">Radio</MenuItem>
                                                            <MenuItem value="dropdown">Dropdown</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                {/* Toggle Button Group for Required or Optional */}
                                                <Grid item xs={6} sm={4}>
                                                    <ShToggleButtonGroup
                                                        size="small"
                                                        borderRadius={35}
                                                        value={questionRequired}
                                                        exclusive
                                                        onChange={handleRequiredChange}
                                                        aria-label="question requirement"
                                                        fullWidth
                                                    >
                                                        <ToggleButton value="required" aria-label="Required" color="primary">
                                                            Required
                                                        </ToggleButton>
                                                        <ToggleButton value="optional" aria-label="Optional" color="primary">
                                                            Optional
                                                        </ToggleButton>
                                                    </ShToggleButtonGroup>
                                                </Grid>

                                                {/* Add Question Button */}
                                                <Grid item xs={12} sm={3}>
                                                    <ShChip
                                                        bgColor={PrimaryThemeColor}
                                                        borderRadius="25"
                                                        label="ADD QUESTION"
                                                        icon={<AddIcon />}
                                                        color="primary"
                                                        onClick={handleAddQuestion}
                                                        clickable
                                                        disabled={!questionText.trim()} 
                                                    />
                                                </Grid>
                                            </Grid>
                                        </ShPaper>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Stack spacing={2}>
                                                {/* Droppable for Questions */}
                                                <Droppable droppableId="questions" type="list">
                                                    {(provided) => {
                                                        const { innerRef, droppableProps } = provided;
                                                        return (
                                                            <Box ref={innerRef}
                                                                {...droppableProps}>
                                                                {questions.map((question, qIndex) => (
                                                                <Draggable key={question.id} draggableId={question.id} index={qIndex}>
                                                                {(provided) => (
                                                                    <Grow in={true} timeout={700}>
                                                                        <Box
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            padding={2}
                                                                            paddingTop={0.5}
                                                                            marginBottom={2} >
                                                                            <ShPaper variant="outlined" borderStyle="dashed" borderColor="#74C05A" transElevateOnHover>
                                                                                <Stack direction="row" display="flex" alignItems="center">
                                                                                        <Stack direction='row' alignItems='center' spacing={0.5}>
                                                                                            <IconButton {...provided.dragHandleProps} aria-label="drag" size="small" sx={{ cursor: 'grab' }}>
                                                                                                    <DragIndicatorIcon color='success' />
                                                                                            </IconButton>
                                                                                            <IconButton onClick={() => handleDeleteQuestion(qIndex)} color="error">
                                                                                                <HighlightOffIcon />
                                                                                            </IconButton>
                                                                                            {question.required === "required" &&
                                                                                                <ShChip 
                                                                                                    size="small" 
                                                                                                    customSize="xs" 
                                                                                                    label={"(Required)"}
                                                                                                    bgColor="rgba(255, 152, 0, 0.2)"  
                                                                                                    textColor="#FF6F00"  
                                                                                                    />
                                                                                            }
                                                                                        </Stack>
                                                                                        <Typography ml={1} variant="subtitle2">{`Question ${qIndex + 1}: ${question.question}`}</Typography>
                                                                                        <ShChip sx={{ml:'auto'}} size='small' customSize="small" label={question.type.toLocaleUpperCase()}
                                                                                        bgColor = 'rgba(74, 144, 226, 0.1)' 
                                                                                        textColor = '#4A90E2'
                                                                                        />
                                                                                </Stack>
                                                                                {question.options?.map((option, oIndex) => (
                                                                                    <Grow in={true} timeout={700}>
                                                                                        <Box key={oIndex} justifyContent="space-between"  alignItems="center" padding={1} marginBottom={1}>
                                                                                            <Stack direction="row" spacing={2}>
                                                                                                <ShTextFieldV2
                                                                                                    size="small"
                                                                                                    label={`Option ${oIndex + 1}`}
                                                                                                    value={option.label}
                                                                                                    onChange={(e) =>
                                                                                                        handleOptionChange(qIndex, oIndex, e.target.value, option.autoReject)
                                                                                                    }
                                                                                                    disabled={question.type === 'textfield'}
                                                                                                    fullWidth
                                                                                                    InputProps={{
                                                                                                        endAdornment: (
                                                                                                            <InputAdornment position="end">
                                                                                                                <IconButton onClick={() => handleRemoveOption(qIndex, oIndex)}>
                                                                                                                {question.type !== 'textfield' &&
                                                                                                                    <DeleteIcon color="warning" />
                                                                                                                }
                                                                                                                </IconButton>
                                                                                                            </InputAdornment>
                                                                                                        ),
                                                                                                    }}
                                                                                                />
                                                                                                <FormControlLabel
                                                                                                    control={
                                                                                                        <ShSwitch
                                                                                                            checked={option.autoReject}
                                                                                                            onChange={() =>
                                                                                                                handleOptionChange(qIndex, oIndex, option.label, !option.autoReject)
                                                                                                            }
                                                                                                        />
                                                                                                    }
                                                                                                    label={<Typography pl={1} noWrap>Auto Reject</Typography>}
                                                                                                />
                                                                                            </Stack>
                                                                                        </Box>
                                                                                    </Grow>
                                                                                ))}
                                                                                <Box pl={1}>
                                                                                {question.type !== 'textfield' &&
                                                                                        <ShGreenBtn
                                                                                            size="small"
                                                                                            borderRadius={25}
                                                                                            variant="contained"
                                                                                            onClick={() => handleAddOption(qIndex)}>
                                                                                            Add Option
                                                                                        </ShGreenBtn>
                                                                                    }
                                                                                </Box>
                                                                            </ShPaper>
                                                                        </Box>
                                                                    </Grow>
                                                                )}
                                                            </Draggable>
                                                                ))}
                                                                {provided.placeholder}
                                                            </Box>
                                                        );
                                                    }}
                                                </Droppable>
                                            </Stack>
                                        </DragDropContext>
                                    </Stack>
                                    {!isXsScreen &&
                                        <FormTemplatePrefilledOptions onAddQuestion={(question) => setQuestions([...questions, question])} />
                                    }
                                </Stack>
                            </DialogContent>
                            <ShPaper variant="outlined" headerSection>
                                    <Stack direction='row' justifyContent='space-between' spacing={2}>
                                        <ShButton sx={{mt:2}} onClick={() => { handleDialogClose(); resetQuestionForm(); }} color="info">
                                            Cancel
                                        </ShButton>
                                        <ShButton onClick={handleSaveForm} color="primary" variant="contained">
                                            {isEditMode ? "Update Questionnaire" : "Save Questionnaire"}
                                        </ShButton>
                                    </Stack>
                            </ShPaper>
                        </ShDialog>
                        
                        {selectedLocalFormInCreateJob &&
                            <AdditionalQuestionPreviewDialog open={previewDialogOpen} onClose={() => setPreviewDialogOpen(false)} selectedForm={selectedLocalFormInCreateJob}/>
                        }

                        <ShDialog open={deleteDialogOpen} onClose={handleDialogClose}>
                            <ShPaper variant="outlined" borderRadius={0} noBorderTop headerSection>
                                    <Typography variant="subtitle1">
                                        Delete Additional Question Form
                                    </Typography>
                            </ShPaper>

                            <DialogContent>
                                <ShAlert severity="warning">
                                    <Typography variant="subtitle1">
                                        Are you sure you want to delete <strong>{currentForm?.title}</strong>?
                                    </Typography>
                                </ShAlert>
                            </DialogContent>

                            
                            <ShPaper variant="outlined">
                                <Stack direction='row' justifyContent='space-between' spacing={1}>
                                    <ShButton onClick={(e) => {e.stopPropagation();  setDeleteDialogOpen(false)}}>
                                        Cancel
                                    </ShButton>
                                    {currentForm &&
                                        <ShButton variant="contained" color='error' onClick={(e) => {e.stopPropagation();  handleDeleteForm(currentForm);}}>
                                            Confirm
                                        </ShButton>
                                    }
                                </Stack>
                            </ShPaper>
                            
                        </ShDialog>
                    </Stack>
            </Fade>
        </>
    );
};

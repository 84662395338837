import { Alert, Box, CircularProgress, LinearProgress, Stack, Tab, Tabs, Typography } from "@mui/material";
import { ApplicantsFooter } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsFooter';
import { ApplicantsListView } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsListView';
import { ApplicantsTableView } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsTableView';
import { ApplicantsToolBar } from 'Modules/Core/Applicants/ApplicantsList/ApplicantsToolBar';
import { ApplicantsSortCols, FilterType, IApplicant, IApplicantsApiParams, IApplicantsFilter, IIncompleteApplicant, TInterviewStage } from 'Modules/Core/Applicants/ApplicantsModel';
import { MatchType } from "Modules/Core/JobsTs/JobsModel";
import { IsSmScreen, IsXsScreen, useAppDispatch, useAppSelector } from 'helpers/hooks';
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { ISortParamsBase, LayoutTypes, SortOrder } from 'shared/SharedModels';
import { ShBackdrop } from "shared/SharedStyles/ShFeedback";
import { ShButton } from "shared/SharedStyles/ShInputs";
import { ListWrapper } from "shared/SharedStyles/ShLayouts";
import { DefaultPageSize } from 'shared/constants';
import { updateArrayById } from "shared/utils";
import { TBreadcrumbNavigation } from "store/slices/app/app-model";
import { setBreadcrumbLabelRegistry, setBreadcrumbNavFrom } from "store/slices/app/breadcrumb-slice";
import { downloadApplicantHiringGuide } from "store/slices/employer/applicants/applicant-actions-slice";
import {
    changeApplicantStageInList, fetchApplicantSummary, fetchApplicantsList, resetApplicantsState, setAiSuggestedApplicants, toggleApplicantSummary
} from 'store/slices/employer/applicants/applicants-list-slice';
import { ApplicantsFiltersMap } from "../ApplicantsConstants";
import { getIncompleteApplicantsList, resetIncompleteApplicants } from "store/slices/employer/applicants/incomplete-applicants-slice";
import { InterviewsType } from "Modules/Core/Interviews/InterviewsModel";
import { InterviewsFooter } from "Modules/Core/Interviews/InterviewsFooter";
import { getAiApplicantSuggestions } from "store/slices/app/resources-slice";
import JellyLoader from "shared/ShCustomLoaders/JellyLoader";
import { ShChip } from "shared/SharedStyles/ShDataDisplay";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { ShGreen, PrimaryWordpressThemeColor } from "shared/SharedStyles/styleConstants";
import SparkleIcon from '@mui/icons-material/AutoAwesome';

export const ApplicantsList = () => {
    const { jobId } = useParams();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const isSmScreen = IsSmScreen();
    const isXsScreen = IsXsScreen();
    const { applicantsList, getApplicantsListStatus, paginationParams, applicantsSummary, stageChangeStatus,
        getApplicantsListResponse, isAtsPurchased, aiSuggestedApplicants, getBatchAutoRejectStatus } = useAppSelector((state) => state.employer.applicants.applicantsList);
    const [layoutType, setLayoutType] = useState<LayoutTypes>(LayoutTypes.Table);
    const [selectedFilters, setSelectedFilters] = useState<IApplicantsFilter>({ stages: [], fit: [], keywords: [], rating: [] });
    const [sortParams, setSortParams] = useState<ISortParamsBase<ApplicantsSortCols>>({ orderBy: 'created_at', order: 'desc' });
    const [selectedApplicants, setSelectedApplicants] = useState<number[]>([]);
    const [searchKey, setSearchKey] = useState<string>("");
    const { breadcrumbNavFrom } = useAppSelector((state) => state.app.breadcrumb);
    const [localIncompleteApplicantsList, setLocalIncompleteApplicantsList] = useState<IApplicant[]>([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const {incompleteApplicantsList, getIncompleteApplicantsListStatus, paginationParams: incompletePaginationParams} = useAppSelector((state) => state.employer.applicants.incompleteApplicants);
    const { aiApplicantSuggestions, getAiApplicantSuggestionsStatus } = useAppSelector(state => state.app.resources);
    const [showSparkle, setShowSparkle] = useState(false);

    const fetchIncompleteApplicantsList = useCallback(async () => {
            await dispatch(
                getIncompleteApplicantsList({
                    jobId: parseInt(jobId ?? ""),
                    pageNo: incompletePaginationParams.requestedPageNumber,
                    pageSize: incompletePaginationParams.requestedPageSize,
                })
            );
        
            if (incompleteApplicantsList) {
                const convertedList = incompleteApplicantsList?.map((applicant: IIncompleteApplicant) => convertToApplicant(applicant));
                setLocalIncompleteApplicantsList(convertedList);
            }
    }, [dispatch, jobId, incompleteApplicantsList, incompletePaginationParams.requestedPageNumber, incompletePaginationParams.requestedPageSize]);
    
    const convertToApplicant = (incompleteApplicant: IIncompleteApplicant): IApplicant => {
        return {
            applicationCompletedAt: incompleteApplicant.updatedAt,
            atsPurchased: incompleteApplicant.is_background_checks, 
            candidateEmployerJobId: incompleteApplicant.id,
            candidateId: incompleteApplicant.candidate_id,
            id: incompleteApplicant.id,
            commentTotal: 0, 
            distortionCount: 0,
            email: incompleteApplicant.candidate.account.email,
            employerJobId: incompleteApplicant.employerjob.id,
            firstName: incompleteApplicant.candidate.first_name,
            fullName: incompleteApplicant.candidate.first_name + incompleteApplicant.candidate.last_name,
            jobStage: incompleteApplicant.stage as TInterviewStage,
            stageModifiedAt: new Date(),
            lastName: incompleteApplicant.candidate.last_name,
            matchType: 'Incomplete', 
            resumeScore: incompleteApplicant.resume_match_score, 
            rating: parseInt(incompleteApplicant.rating),
        };
    };
    
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
        if (newValue === 1) {
            fetchIncompleteApplicantsList();
        }
    };
    
    useEffect(() => {
        if (incompleteApplicantsList?.length === 0) {
            fetchIncompleteApplicantsList();
        }

        if(aiSuggestedApplicants?.length === 0){
            const filteredApplicantList = applicantsList.map(applicant => ({
                candidateId: applicant.candidateId,
                commentTotal: applicant.commentTotal,
                matchType: applicant.matchType,
                resumeScore: applicant.resumeScore,
                rating: applicant.rating
            }));

            const payload = { applicantList: filteredApplicantList };
            dispatch(getAiApplicantSuggestions(payload));
        }
    }, [fetchIncompleteApplicantsList, incompleteApplicantsList?.length,aiSuggestedApplicants , applicantsList, dispatch]);

    useEffect(() => {
        return () => {
          dispatch(resetIncompleteApplicants());
        };
      }, [dispatch]);
 
    /*
        Set Layout Type to List in smaller screen.
        Layout toggler will be hidden in small screen to block user from going to Table view.
    */
    useEffect(() => {
        if (isSmScreen) {
            setLayoutType(LayoutTypes.List);
        }
    }, [isSmScreen]);

    // Get applicants to form payload and make the API call.
    const getApplicants = useCallback((pageNo?: number, pageSize?: number, filters?: IApplicantsFilter,
        sortParams?: ISortParamsBase<ApplicantsSortCols>, search?: string) => {
        const params: IApplicantsApiParams = {
            jobId: parseInt(jobId ?? ''),
            pgNo: search ? 1 : pageNo ?? 1,
            pgSize: pageSize ?? DefaultPageSize,
            sortCol: sortParams?.orderBy ?? 'created_at',
            sortDir: sortParams?.order ?? 'desc',
            search: search ?? '',
            stages: filters?.stages?.map(stg => stg.value).join(','),
            ratings: filters?.rating?.map(stg => stg.value).join(','),
            finalMatches: filters?.fit?.map(stg => stg.value).join(','),
            keywords: filters?.keywords?.map(stg => stg.value).join(','),
        };
        if (isNaN(parseInt(jobId ?? ''))) {
            // If the job id is not a number then navigate to wild card route.
            navigate('/404');
        } else {
            dispatch(fetchApplicantsList(params));
        }
    }, [dispatch, jobId, navigate]);

    const applyFilters = useCallback((filters: IApplicantsFilter, doNotSaveFilters?: boolean) => {
        setSelectedFilters(filters);
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, undefined, filters, sortParams, searchKey);

        if (!doNotSaveFilters) {
            // Add filters into session storage.
            sessionStorage.setItem(jobId + '', JSON.stringify(filters));
        }
    }, [getApplicants, jobId, searchKey, sortParams]);

    const filterByMatchType = useCallback((matchType: MatchType) => {
        const matchTypesForFilters = ApplicantsFiltersMap.fit.find(fit => fit.matchTypeKey === matchType);
        if (matchTypesForFilters) {
            applyFilters({
                stages: [],
                fit: [{ label: matchTypesForFilters?.label, value: matchTypesForFilters?.value }],
                keywords: [],
                rating: []
            }, true);
        }
    }, [applyFilters]);

    const filterByStage = useCallback((stage: TInterviewStage) => {
        const stagesForFilters = ApplicantsFiltersMap.stages.find(stg => stg.value === stage);
        if (stagesForFilters) {
            applyFilters({
                stages: [{ label: stagesForFilters?.label, value: stagesForFilters?.value }],
                fit: [],
                keywords: [],
                rating: []
            }, true);
        }
    }, [applyFilters]);

    // Get applicants list on page load.
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const matchType = searchParams.get('matchType') as MatchType;
        const stage = searchParams.get('stage') as TInterviewStage;
        if (matchType) {
            filterByMatchType(matchType);
        } else if (stage) {
            filterByStage(stage);
        }
        else {
            const searchParams = new URLSearchParams(location.search);
            const pageNoString = searchParams.get('page');
            const page = Number(pageNoString) || undefined;

            const appliedFilters = sessionStorage.getItem(jobId + '');
            if (appliedFilters) {
                const parsedAppliedFilters = JSON.parse(appliedFilters) as IApplicantsFilter;
                const { fit, keywords, rating, stages } = parsedAppliedFilters;
                if ([...fit, ...keywords, ...rating, ...stages].length > 0) {
                    applyFilters(parsedAppliedFilters);
                }
                else {
                    getApplicants(page);
                }
            } else {
                getApplicants(page);
            }
        }
        return () => {
            // Reset applicant state on component unmount.
            dispatch(resetApplicantsState());
        }
    }, [applyFilters, dispatch, filterByMatchType, filterByStage, getApplicants, jobId, location.search, getBatchAutoRejectStatus]);

    const removeFilter = (filterType: FilterType, val: string) => {
        const tmpStages = [...selectedFilters[filterType]];
        const indx = tmpStages.map(ts => ts.value).indexOf(val);
        tmpStages.splice(indx, 1);
        const temp = { ...selectedFilters, [filterType]: tmpStages }
        setSelectedFilters(temp);
        sessionStorage.setItem(jobId + '', JSON.stringify(temp));
        /*
            Call get applicants function with page number and size with undefined.
            Sets page size and page number to default while making API call. 
        */
        getApplicants(undefined, paginationParams.requestedPageSize, temp, sortParams, searchKey);
    };

    const sortList = (sortBy: ApplicantsSortCols, order: SortOrder) => {
        setSortParams({ order: order, orderBy: sortBy });
        /*
           Call get applicants function with page number and size from pagination params.
       */
        getApplicants(paginationParams.requestedPageNumber,
            paginationParams.requestedPageSize,
            selectedFilters,
            { order: order, orderBy: sortBy },
            searchKey);
    };

    /*
        Call get applicant summary only if the applicant summary isn't already loaded.
        If applicant summary is already loaded, call 'toggleApplicantSummary' reducer in applicants slice to toggle expand/collapse.
    */
    const getApplicantSummary = (_candidateEmployerJobId: number) => {
        if (_candidateEmployerJobId in applicantsSummary && applicantsSummary[_candidateEmployerJobId].baseballCardUrl) {
            dispatch(toggleApplicantSummary(applicantsList.findIndex(al => al.candidateEmployerJobId === _candidateEmployerJobId)));
        } else {
            dispatch(fetchApplicantSummary({
                candidateEmployerJobId: _candidateEmployerJobId,
                jobId: parseInt(jobId ?? ''),
                isExpandPanel: true
            }));
        }
    };

    const searchInApplicants = (searchKey: string) => {
        getApplicants(paginationParams.requestedPageNumber, paginationParams.requestedPageSize, selectedFilters, sortParams, searchKey);
        setSearchKey(searchKey);
    };

    const changeStage = (stage: Lowercase<TInterviewStage>, candidateEmployerJobId: number, sendEmail: boolean) => {
        dispatch(changeApplicantStageInList({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: sendEmail } }));
    };

    useEffect(() => {
        if (stageChangeStatus === 'success') {
            const appliedFilters = sessionStorage.getItem(jobId + '');
            if (appliedFilters) {
                applyFilters(JSON.parse(appliedFilters));
            }
        }
    }, [applyFilters, jobId, stageChangeStatus]);

    const goToProfile = (applicant: IApplicant) => {
        navigate(`/employer/job/${jobId}/applicant/${applicant.candidateEmployerJobId}/details`, { state: applicant });
        if (breadcrumbNavFrom) {
            let breadcrumbNav: TBreadcrumbNavigation = 'active_jobs';
            switch (breadcrumbNavFrom) {
                case 'active_jobs':
                    breadcrumbNav = 'active_jobs_applicants';
                    break;
                case 'expired_jobs':
                    breadcrumbNav = 'expired_jobs_applicants';
                    break;
                default:
                    break;
            }
            dispatch(setBreadcrumbNavFrom({ breadcrumbNav: breadcrumbNav }));
            dispatch(setBreadcrumbLabelRegistry({
                labelReg: {
                    [applicant.candidateEmployerJobId]: `${applicant.fullName}`,
                    [`applicants_${applicant.candidateEmployerJobId}_page`]: `${paginationParams.requestedPageNumber}`
                }
            }));
        }
    };

    const Alerts = () => {
        switch (getApplicantsListStatus) {
            case 'pending':
                return <ShBackdrop open={true}><CircularProgress color="inherit" /></ShBackdrop>;
            case 'failed':
                return <Alert severity="error">{getApplicantsListResponse}</Alert>;
            default:
                <></>;
        }
    };

    const downloadHiringGuide = (applicant: IApplicant) => {
        dispatch(downloadApplicantHiringGuide({ applicantId: applicant.candidateEmployerJobId, applicantName: applicant.fullName }));
    }

    const getNextDataIncompleteApplicants = ( _interviewType: InterviewsType, pageNo: number | undefined, pageSize: number | undefined) => {
        dispatch(
          getIncompleteApplicantsList({
            jobId: parseInt(jobId ?? ""),
            pageNo,
            pageSize,
          }));
    };

    useEffect(() => {
        if (incompleteApplicantsList) {
            const convertedList = incompleteApplicantsList.map((applicant: IIncompleteApplicant) => convertToApplicant(applicant));
            setLocalIncompleteApplicantsList(convertedList);
        }
    }, [incompleteApplicantsList]);

    useEffect(() => {
        if (getAiApplicantSuggestionsStatus === 'success' && aiApplicantSuggestions.length > 0) {
            const updatedApplicants = applicantsList.filter(applicant =>
                aiApplicantSuggestions.includes(applicant.candidateId) 
            );
            setAiSuggestedApplicants(updatedApplicants);
            dispatch(setAiSuggestedApplicants(updatedApplicants));
            setShowSparkle(false); 
        }
    }, [getAiApplicantSuggestionsStatus, aiApplicantSuggestions, applicantsList, dispatch]); 
    


    return (<>
        <ListWrapper>
            {/* ApplicantsToolBar component holds the template with Search, Filter, Sort and Layout toggle elements. */}
                <ApplicantsToolBar applicantList={selectedTab === 0 ? applicantsList: localIncompleteApplicantsList} isSmScreen={isSmScreen} layoutType={layoutType} setLayoutType={setLayoutType}
                    applyFilters={applyFilters} selectedFilters={selectedFilters} searchKey={searchKey}
                    sortList={sortList} sortParams={sortParams} removeFilter={removeFilter} searchInApplicants={searchInApplicants} />
            {/* Show loading screen while list is being fetched. */}
            {Alerts()}
            {/* Switch between layout types. */}
            {applicantsList?.length > 0 ? (
                layoutType === LayoutTypes.Table ?
                    <>
                        <Stack spacing={1}>
                            <Tabs value={selectedTab} onChange={handleTabChange} aria-label="applicants tabs" >
                                <Tab sx={{ textTransform: 'none' }} label="Completed Applicants" 
                                    iconPosition="start"/>
                                <Tab sx={{ textTransform: 'none' }} label="Incomplete Applicants"
                                    iconPosition="start"/>
                                <Tab sx={{ textTransform: 'none' }} 
                                    label={<><SparkleIcon color='primary' fontSize="small" sx={{ marginRight: 1 }} />AI Picks</>} 
                                    iconPosition="start"
                                />
                            </Tabs>
                            {selectedTab === 1 && getIncompleteApplicantsListStatus !== 'success' &&
                                <LinearProgress color='success'/>
                            }
                        </Stack>
                       
                        <ApplicantsTableView 
                            applicantsList={
                                selectedTab === 2 
                                ? aiSuggestedApplicants || []  
                                : (selectedTab === 0 
                                    ? applicantsList  
                                    : localIncompleteApplicantsList) 
                            }
                        applicantsSummary={applicantsSummary} getApplicantSummary={getApplicantSummary}
                            goToApplicantProfile={goToProfile} isAtsPurchased={selectedTab === 0 ? isAtsPurchased : true} selectApplicant={(applicantId) => setSelectedApplicants(updateArrayById<number, number>(applicantId, selectedApplicants))} 
                            selectedApplicants={selectedApplicants} sortParams={sortParams} sortList={sortList} changeStage={changeStage}
                            downloadHiringGuide={downloadHiringGuide} isIncompleteApplicantView={selectedTab === 1}
                        />

                        {showSparkle && (
                                        <Box paddingBottom={2}>
                                            <ShPaper variant="outlined">
                                                    <Box display="flex" justifyContent="center" marginTop={2}>
                                                    <Stack alignItems="center" spacing={1}> 
                                                    <ShChip borderRadius='50px' size='medium' color='info' label="Finding your best fit ..." variant="outlined"/> 
                                                    <JellyLoader size="70px" speed="4s" 
                                                    cycleColors={[
                                                        ShGreen.toString(),
                                                        PrimaryWordpressThemeColor.toString(),
                                                        ShGreen.toString(),
                                                        PrimaryWordpressThemeColor.toString()
                                                        ]}
                                                        cycleSpeed={400}
                                                    />
                                                    </Stack>
                                                </Box>
                                            </ShPaper>
                                        </Box>
                                    )}
                    </>
                     :
                    <ApplicantsListView applicantsList={applicantsList} applicantsSummary={applicantsSummary}
                        getApplicantSummary={getApplicantSummary} goToApplicantProfile={goToProfile} isAtsPurchased={isAtsPurchased}
                        selectApplicant={(applicantId) => setSelectedApplicants(updateArrayById<number, number>(applicantId, selectedApplicants))}
                        selectedApplicants={selectedApplicants} changeStage={changeStage} downloadHiringGuide={downloadHiringGuide} />
            ) : (
                getApplicantsListStatus === 'success' && <Alert severity="info">No Applicants Yet</Alert>
            )}
            
            {selectedTab === 0 && applicantsList?.length > 0 && paginationParams && paginationParams.totalPages > 0 && (
                <ApplicantsFooter isSmScreen={isSmScreen} paginationParams={paginationParams} selectedApplicants={selectedApplicants}
                    getApplicants={(pgNo, pgSize) => getApplicants(pgNo, pgSize, selectedFilters, sortParams, searchKey)}
                    jobId={parseInt(jobId ?? '')} />
            )}

            {/* 
                Incomplete Applicants Footer, I have no idea why its called interviews Footer, 
                was taken from the IncompleteApp component
            */}
            {selectedTab === 1 &&
                <InterviewsFooter
                    getInterviews={getNextDataIncompleteApplicants}
                    interviewsType={"scheduled"}
                    isSmScreen={isSmScreen}
                    paginationParams={incompletePaginationParams}
                />
            }

            {applicantsList?.length === 0 && !isXsScreen && !isAtsPurchased &&
                <Box marginTop={2}>
                    <ShButton component={RouterLink} to={`/employer/incomplete-applicants/${jobId}`}>
                        <Typography variant='body2'>View Incomplete Applicants</Typography>
                    </ShButton>
                </Box>}
        </ListWrapper>
    </>);
};
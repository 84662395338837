import ContactPageIcon from "@mui/icons-material/ContactPage";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import {
  Avatar,
  Box,
  Collapse,
  DialogContent,
  DialogTitle,
  Fade,
  IconButton,
  LinearProgress,
  Stack,
  TableCell,
  TableHead,
  Tooltip,
  Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableSortLabel from "@mui/material/TableSortLabel";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { ApplicantsHeaders } from "Modules/Core/Applicants/ApplicantsConstants";
import { ApplicantComment } from "Modules/Core/Applicants/ApplicantsList/ApplicantComment";
import { ApplicantRating } from "Modules/Core/Applicants/ApplicantsList/ApplicantRating";
import { ApplicantSummary } from "Modules/Core/Applicants/ApplicantsList/ApplicantSummary";
import { StagesToggler } from "Modules/Core/Applicants/ApplicantsList/StagesToggler";
import {
  IApplicantRowModel,
  IApplicantsTable,
} from "Modules/Core/Applicants/ApplicantsModel";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ShTooltip } from "shared/SharedComponents/ShTooltip";
import {
  CollapsibleTableRow,
  RowSelectCell,
  ShTable,
  ShTableContainer,
  ShTableRow,
  ThemeColorDivider,
} from "shared/SharedStyles/ShDataDisplay";
import { ShCheckbox } from "shared/SharedStyles/ShInputs";
import { stringToColor } from "shared/utils";
import { ResumeScoreGauge } from "../ApplicantHelpers";
import { renderMatchChip } from "./ApplicantsListView";
import DescriptionIcon from '@mui/icons-material/Description';
import { CircleBox } from "Modules/Core/Dashboard/Dashboard.styled";
import { ShGreen } from "shared/SharedStyles/styleConstants";
import { getApplicantResume } from "store/slices/employer/applicants/applicant-profile-slice";
import { ShDialog } from "shared/SharedStyles/ShFeedback";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";

// Table Row component
const Row = ({
  applicant,
  selectedApplicants,
  selectApplicant,
  indx,
  applicantsSummary,
  getApplicantSummary,
  goToApplicantProfile,
  changeStage,
  isAtsPurchased,
  downloadHiringGuide,
  isIncompleteApplicantView,
}: IApplicantRowModel) => {
  const dispatch = useAppDispatch();
  const [openResumeViewDialog, setResumeViewDialog] = useState(false);
  const openResumeDialog = (event:any) => {
    event.stopPropagation();
    dispatch(getApplicantResume({ applicantId: applicant.candidateEmployerJobId ?? 0 }));
    setResumeViewDialog(true);
  };
  const handleCloseDialog = () => { setResumeViewDialog(false)};
  const { getApplicantResumeStatus, resumeS3Url } = useAppSelector((state) => state.employer.applicants.applicantProfile);
  return (
    <>
      <ShTableRow
         onClick={(e) => {
          if (!isIncompleteApplicantView) {
            getApplicantSummary(applicant.candidateEmployerJobId);
          }
        }}
        cursor={isIncompleteApplicantView ? "default" : "pointer"}
        id={`a_t_v_r_${applicant.candidateEmployerJobId}`}
      >
        <RowSelectCell onClick={(e) => e.stopPropagation()}>
          <ShCheckbox
            id={"chk_" + indx}
            checked={selectedApplicants?.includes(
              applicant.candidateEmployerJobId
            )}
            size="small"
            onChange={() => selectApplicant(applicant.candidateEmployerJobId)}
          />
        </RowSelectCell>
        <TableCell>
          <Stack
            direction="row"
            spacing={{ lg: 3, md: 2, sm: 1, xs: 0.5 }}
            alignItems="center"
          >
            <Avatar
              style={{
                backgroundColor: stringToColor(`${applicant.fullName}`),
              }}
              className="avatar"
              alt={applicant.fullName}
              src={`user-avatar-to-be-loaded-here`}
            />
            <Stack>
              <Typography variant="body2">
                {applicant.fullName}
              </Typography>
              <Typography variant="caption">{applicant.email}</Typography>
            </Stack>
            {!isIncompleteApplicantView &&
              <Tooltip
                title="View Applicant's details"
                onClick={(e) => e.stopPropagation()}
              >
                <Box
                  component="span"
                  onClick={(e) => {
                    e.stopPropagation();
                    goToApplicantProfile(applicant);
                  }}
                >
                  <ContactPageIcon
                    fontSize="small"
                    color="primary"
                    className="on-hover"
                  />
                </Box>
              </Tooltip>
            }
          </Stack>
        </TableCell>
        <TableCell>
          {applicant.applicationCompletedAt &&
            <Typography variant="body2">
              {moment(applicant.applicationCompletedAt).format("MMM DD, YYYY")}
            </Typography>}
        </TableCell>
        <TableCell>
          <ApplicantRating
            className="dsk-only-inline-flex"
            applicant={applicant}
            usedIn="list"
          />
        </TableCell>
        {/* Show this cell only for non ats jobs */}
        {!isAtsPurchased && (
          <TableCell>
            <Typography component={"div"} variant="body2">
              {renderMatchChip(applicant.matchType)}
            </Typography>
          </TableCell>
        )}
        <TableCell>
          <ResumeScoreGauge score={applicant.resumeScore || 0} />
        </TableCell>
        <TableCell>
          <Stack direction="row" alignItems="center" spacing={2}>
            {!isIncompleteApplicantView && 
              <StagesToggler
                applicant={applicant}
                usedIn="list"
                changeStage={changeStage}
              />
            }
            <Stack>
              {applicant.hasReceivedRejectionEmail && (
                <ShTooltip
                  title="A rejection email was sent to the candidate"
                  placement="left-end"
                >
                  <IconButton
                    onClick={(e) => e.stopPropagation()}
                    style={{ cursor: "default" }}
                  >
                    <MarkEmailReadIcon color="primary" />
                  </IconButton>
                </ShTooltip>
              )}
            </Stack>
          </Stack>
        </TableCell>
        
        <TableCell>
          <ApplicantComment applicantId={applicant.candidateEmployerJobId} />
        </TableCell>
        
   
        <TableCell>
          <CircleBox color='#e8f5e9'>
          <ShTooltip title="View resume"placement="top">
              <DescriptionIcon onClick={openResumeDialog} style={{ color: ShGreen, cursor:'pointer' }} />
          </ShTooltip>
          </CircleBox>
        </TableCell>
        
      </ShTableRow>
      <CollapsibleTableRow>
        <TableCell colSpan={8}>
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "pending" && <LinearProgress />}
          {applicantsSummary[applicant.candidateEmployerJobId]
            ?.getSummaryStatus === "success" && (
              <Collapse in={applicant.isExpanded} timeout="auto" unmountOnExit>
                <ApplicantSummary
                  changeStage={changeStage}
                  applicant={applicant}
                  key={applicant.candidateEmployerJobId}
                  goToApplicantProfile={goToApplicantProfile}
                  isAtsPurchased={isAtsPurchased}
                  summary={applicantsSummary[applicant.candidateEmployerJobId]}
                  applicantId={applicant.candidateEmployerJobId}
                  downloadHiringGuide={downloadHiringGuide}
                />
                <ThemeColorDivider />
              </Collapse>
            )}
        </TableCell>
      </CollapsibleTableRow>
      <ShDialog open={openResumeViewDialog} onClose={handleCloseDialog} maxWidth="lg" fullWidth>
        <ShPaper variant='outlined' headerSection>
          <DialogTitle>Applicant Resume</DialogTitle>
          <Typography> </Typography>
        </ShPaper>
        <DialogContent>
            {getApplicantResumeStatus !== 'success' &&
              <LinearProgress/>
            }
          <Box flex={1} display='flex' flexDirection='column' height={'80vh'}>
            <iframe
              title="applicant resume"
              src={resumeS3Url}
              width="100%"
              height="100%"
            ></iframe>
          </Box>
        </DialogContent>
      </ShDialog>

    </>
  );
};

export const ApplicantsTableView = ({
  applicantsList,
  applicantsSummary,
  isAtsPurchased,
  selectedApplicants,
  selectApplicant,
  sortList,
  sortParams,
  getApplicantSummary,
  goToApplicantProfile,
  changeStage,
  downloadHiringGuide,
  isIncompleteApplicantView,
}: IApplicantsTable) => {
  const location = useLocation();
  const { getApplicantsListStatus } = useAppSelector(
    (state) => state.employer.applicants.applicantsList
  );

  useEffect(() => {
    if (getApplicantsListStatus === "success") {
      const searchParams = new URLSearchParams(location.search);
      const applicantIdString = searchParams.get("applicantId");
      const applicantId = Number(applicantIdString) || undefined;
      const currRow = document.getElementById(`a_t_v_r_${applicantId}`);
      currRow?.setAttribute("tabindex", "0");
      currRow?.scrollIntoView({ behavior: "smooth", block: "center" });
      currRow?.focus();
    }
  }, [getApplicantsListStatus, location.search]);

  return (
    <>
      <Fade in={true} timeout={800}>
        <ShTableContainer>
          <ShTable size="small">
            <TableHead>
              <ShTableRow cursor="pointer">
                <TableCell className="select-header"></TableCell>
                {/* Sortable constant table headers */}
                {ApplicantsHeaders.map((headCell) => (
                  <TableCell
                    key={headCell.columnName}
                    sortDirection={
                      sortParams.orderBy === headCell.columnName
                        ? sortParams.order
                        : false
                    }
                  >
                    {headCell.columnName === "rating" ? ( // Apply tooltip and sorting label for rating col
                      <Typography
                        variant="body2"
                        display="flex"
                        alignItems="center"
                      >
                        <TableSortLabel
                          active={sortParams.orderBy === headCell.columnName}
                          direction={
                            sortParams.orderBy === headCell.columnName
                              ? sortParams.order
                              : "asc"
                          }
                          onClick={() => {
                            sortList(
                              headCell.columnName,
                              sortParams.order === "asc" ? "desc" : "asc"
                            );
                          }}
                        >
                          {headCell.label}
                          &nbsp;
                          <Tooltip
                            title="Recruiters can rate their candidates based on interviews, qualifications, etc."
                            placement="top"
                          >
                            <InfoOutlinedIcon fontSize="small" color="disabled" />
                          </Tooltip>
                        </TableSortLabel>
                      </Typography>
                    ) : (
                      <TableSortLabel
                        active={sortParams.orderBy === headCell.columnName}
                        direction={
                          sortParams.orderBy === headCell.columnName
                            ? sortParams.order
                            : "asc"
                        }
                        onClick={() => {
                          sortList(
                            headCell.columnName,
                            sortParams.order === "asc" ? "desc" : "asc"
                          );
                        }}
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
                {/* Show this column only for non ats jobs */}
                {!isAtsPurchased && (
                  <TableCell>
                    <Stack direction="row" alignItems="center">
                      <Typography variant="body2">Job Fit &nbsp;</Typography>
                      <Tooltip
                        title="This is your candidates match with the job's skills and their personality fit based on assessment."
                        placement="right"
                      >
                        <InfoOutlinedIcon fontSize="small" color="disabled" />
                      </Tooltip>
                    </Stack>
                  </TableCell>
                )}
                <TableCell>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    display="flex"
                  >
                    <Typography variant="body2">Resume Score</Typography>
                    <ShTooltip
                      title="Your candidate's resume match with the job is in beta testing.
                                        Scores are accurate only for applications submitted after 10/28/2024. If scores are lower than expected, 
                                        consider updating your job description with more specific keywords."
                      placement="right"
                      showArrowIcon
                      arrowLink="/employer/help/optimize-job-posting"
                    >
                      <InfoOutlinedIcon
                        fontSize="small"
                        color="disabled"
                        style={{ display: "flex", alignItems: "center" }}
                      />
                    </ShTooltip>
                  </Stack>
                </TableCell>

                {!isIncompleteApplicantView && 
                  <TableCell>Stage</TableCell>
                }
                
                <TableCell></TableCell>
                <TableCell></TableCell>
                {isIncompleteApplicantView &&
                  <TableCell></TableCell>
                }


              </ShTableRow>
            </TableHead>
            <TableBody>
              {applicantsList.map((aplcnt, index) => (
                // Row component holds both applicant and collapsible summary rows.
                <Row
                  key={index}
                  indx={index}
                  applicant={aplcnt}
                  goToApplicantProfile={goToApplicantProfile}
                  applicantsSummary={applicantsSummary}
                  selectedApplicants={selectedApplicants}
                  getApplicantSummary={getApplicantSummary}
                  isAtsPurchased={isAtsPurchased}
                  selectApplicant={(applcntId: number) =>
                    selectApplicant(applcntId)
                  }
                  downloadHiringGuide={downloadHiringGuide}
                  changeStage={changeStage}
                  isIncompleteApplicantView={isIncompleteApplicantView}
                />
              ))}
            </TableBody>
          </ShTable>
        </ShTableContainer>
      </Fade>
    </>
  );
};

import { Stack, Typography } from "@mui/material"
import { ShPaper } from "shared/SharedStyles/ShSurfaces"


export const FormTemplateHeader = () => {
    return (
    <ShPaper variant="outlined"  borderRadius={0} headerSection>
        <Stack direction='row' justifyContent='space-between'> 
            <Stack spacing={1}>
                <Typography variant="subtitle1" gutterBottom paddingLeft={2}>
                    Create Custom Additional Question Templates
                </Typography>
                <Typography variant="body2" color="textSecondary" paddingLeft={2}>
                    Add questions and specify answer options. You can also mark options as auto-reject to streamline your process.
                </Typography>
            </Stack>
        </Stack>
    </ShPaper>
    )
}
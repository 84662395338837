import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, IconButton, Rating, Skeleton, Stack, Typography } from "@mui/material";
import { renderFingerprintImage } from "Modules/Core/Applicants/ApplicantHelpers";
import { ShImageWithIconBox } from "Modules/Core/Applicants/ApplicantProfile/Applicant.styled";
import { MatchTypes, SkillMap } from "Modules/Core/Applicants/ApplicantsConstants";
import { ApplicantRating } from "Modules/Core/Applicants/ApplicantsList/ApplicantRating";
import { FingerprintImageContainer, FingerprintImgContainer } from "Modules/Core/Applicants/ApplicantsList/ApplicantsList.styled";
import { StagesToggler } from "Modules/Core/Applicants/ApplicantsList/StagesToggler";
import { TInterviewStage } from "Modules/Core/Applicants/ApplicantsModel";
import { DistortionIcon, FitIcon, StrongFitIcon, WeakFitIcon } from "assets/Icons";
import { FairFitIcon } from 'assets/Icons/FairFitIcon';
import HiringGuide from 'assets/Images/hiring_guide.png';
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ShTooltip } from 'shared/SharedComponents/ShTooltip';
import { ApiState } from "shared/SharedModels";
import { ShAvatar, UnderlinedText } from "shared/SharedStyles/ShDataDisplay";
import { ShAlert } from "shared/SharedStyles/ShFeedback";
import { ShContainer } from "shared/SharedStyles/ShLayouts";
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';
import { DefaultAPIErrorMsg } from "shared/constants";
import { changeApplicantStageInProfile } from "store/slices/employer/applicants/applicant-profile-slice";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { getInitialsFromName, stringToColor } from 'shared/utils';
import { getJobDetails } from 'store/slices/employer/jobs/job-info-slice';

export const ApplicantProfile = () => {

    const { applicantId } = useParams();
    const dispatch = useAppDispatch();
    const { applicantSummary, getApplicantSummaryStatus,
        getApplicantSummaryResponse } = useAppSelector((state) => state.employer.applicants.applicantsList);
    const { getFullFingerPrintImageStatus, getFullFingerPrintImageResponse, applicantDetails,
        fullFingerPrintImage } = useAppSelector(state => state.employer.applicants.applicantProfile);
    const applicantInitials = getInitialsFromName(`${applicantDetails?.firstName} ${applicantDetails?.lastName}` || "AN", true, true);
    const applicantAvatarBackgroundColor = stringToColor(applicantInitials);
    const { jobId } = useParams();
    // const [jobName, setJobName] = useState('');

    // Append the svg into parent element once its loaded. 
    useEffect(() => {
        if (getFullFingerPrintImageStatus === 'success') {
            if (fullFingerPrintImage) {
                renderFingerprintImage(fullFingerPrintImage, applicantId +
                    'full_fingerprint_svg_parent');
            }
        }
    }, [applicantId, fullFingerPrintImage, getFullFingerPrintImageStatus]);

    const changeStage = (stage: Lowercase<TInterviewStage>, candidateEmployerJobId: number) => {
        dispatch(changeApplicantStageInProfile({ applicantId: candidateEmployerJobId, payload: { stage: stage, sendEmail: false } }));
    };

    const showFailureAlerts = (apiState?: ApiState, msg?: string) => {
        switch (apiState) {
            case 'failed':
                return (<>
                    <Box marginBottom={2} width='100%'>
                        <ShAlert severity="error">{msg ?? DefaultAPIErrorMsg}</ShAlert>
                    </Box>
                </>);
            default:
                break;
        }
    };

    useEffect(() => {
        if (jobId !== undefined && !isNaN(parseInt(jobId))) {
            const id = parseInt(jobId);
            dispatch(getJobDetails({ jobId: id }))
                .then((response) => {
                    const name = response?.payload?.data?.name;
                    if (name) {
                        // setJobName(name); // Set the job name in state
                    }
                });
        }
    }, [dispatch, jobId]);

    return (<>
        <Stack>
            <ShPaper variant='outlined' headerSection borderRadius={0}>
                <Stack direction='row' rowGap={1} alignItems='center' spacing={2}>
                    <Typography variant='body2'>Applicant Summary</Typography>
                </Stack>
            </ShPaper>
            <ShPaper variant='outlined' noBorderTop borderRadius={0}>
                <ShContainer maxWidth='md' disableGutters>
                    <Stack rowGap={2} width='fit-content'>
                        {applicantDetails &&
                            <Stack direction='row' columnGap={3} flexWrap='wrap'>

                                <Stack rowGap={1}>
                                    <UnderlinedText variant="subtitle2"  underlineType="border" gutterBottom>
                                        Full Name
                                    </UnderlinedText>
                                <Stack direction='row' spacing={1} alignItems='center'>
                                    <ShAvatar height={30} width={30} backgroundColor={applicantAvatarBackgroundColor}>
                                        <Typography variant="body2">
                                            {applicantInitials}
                                        </Typography>
                                    </ShAvatar>
                                    <Stack rowGap={1}>
                                        <Typography variant="body2" component="span" >
                                            {`${applicantDetails?.firstName} ${applicantDetails?.lastName}`}
                                        </Typography>
                                    </Stack>
                                </Stack>
                                </Stack>
                                <Stack rowGap={1}>
                                    <UnderlinedText variant="subtitle2"  underlineType="border" gutterBottom>
                                        Email Address
                                    </UnderlinedText>
                                    <Typography variant="body2">
                                        {applicantDetails?.email}
                                    </Typography>
                                </Stack>
                                <Stack rowGap={1}>
                                    <UnderlinedText variant="subtitle2"  underlineType="border" gutterBottom>
                                        Rating
                                    </UnderlinedText>
                                    <Typography variant="body2">
                                        <ApplicantRating className='dsk-only-inline-flex' applicant={applicantDetails} usedIn="profile" />
                                    </Typography>
                                </Stack>
                                <Stack rowGap={1}>
                                    <UnderlinedText variant="subtitle2" underlineType="border" gutterBottom>
                                        Stage
                                    </UnderlinedText>
                                    <Stack direction='row' spacing={2}>
                                        <StagesToggler className='dsk-only-inline-flex' applicant={applicantDetails}
                                            usedIn='profile' changeStage={changeStage} />
                                        {applicantDetails.jobStage === 'rejected' && applicantDetails.hasReceivedRejectionEmail &&
                                            <ShTooltip title="A rejection email was sent to the candidate" placement="right-end">
                                                <IconButton onClick={e => e.stopPropagation()} style={{ cursor: 'default' }}>
                                                    <MarkEmailReadIcon color='primary' />
                                                </IconButton>
                                            </ShTooltip>
                                        }
                                    </Stack>
                                </Stack>
                            </Stack>}
                        {showFailureAlerts(getApplicantSummaryStatus, getApplicantSummaryResponse)}
                        {applicantDetails && <>
                            {/* Hide fingerprint and summary rating for ats  jobs */}

                            <ShPaper variant="outlined">
                                <Stack direction='row' alignItems='center' columnGap={3} padding={2}>
                                    {!applicantDetails?.atsPurchased &&
                                        <ShImageWithIconBox height={{ xs: '100px', sm: '100px', md: '125px', lg: '150px' }}
                                            width={{ xs: '100px', sm: '100px', md: '125px', lg: '150px' }}>
                                            {
                                                applicantDetails?.matchType === MatchTypes.strong ||
                                                    applicantDetails?.matchType === MatchTypes.strongFit
                                                    ? <StrongFitIcon /> :
                                                    applicantDetails?.matchType === MatchTypes.distortion
                                                        ? <DistortionIcon /> :
                                                        applicantDetails?.matchType === MatchTypes.good ||
                                                            applicantDetails?.matchType === MatchTypes.match
                                                            ? <FitIcon /> :
                                                            applicantDetails?.matchType === MatchTypes.match ||
                                                                applicantDetails?.matchType === MatchTypes.fair
                                                                ? <FairFitIcon /> :
                                                                <WeakFitIcon />
                                            }
                                        </ShImageWithIconBox>}
                                    <Stack>
                                        <UnderlinedText variant="body1"  underlineType="border" gutterBottom width='fit-content'>
                                            Skills Summary
                                        </UnderlinedText>
                                        <Stack height='100%' justifyContent='center' rowGap={1}>
                                        {applicantSummary?.educationQualificationRequirements?.length ?
                                            <Stack>
                                                <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Education & Qualifications</Typography>
                                                {applicantSummary?.educationQualificationRequirements?.map((eq, indx) => (
                                                    <Box key={indx} display="flex" alignItems="center">
                                                        {eq.hasRequirement ? (
                                                            <CheckIcon fontSize="small" color='success' />
                                                        ) : (
                                                            <CloseIcon fontSize="small" color='error' />
                                                        )}
                                                        <Typography variant='body2' ml={1}>
                                                            {eq.name}
                                                        </Typography>
                                                    </Box>
                                                ))}
                                            </Stack>:<></>
                                        }

                                        {applicantSummary?.skillRequirements?.length ? 
                                            <Stack>
                                                <Typography variant='subtitle2' fontWeight='bold' gutterBottom>Skills</Typography>
                                                {applicantSummary?.skillRequirements?.map((skl, indx) =>
                                                (<Stack flexDirection='row' key={indx}>
                                                    <Rating id={applicantId + ''} size='small' max={3}
                                                        value={SkillMap[skl.skillRating] ?? 0} readOnly />
                                                    <Typography variant='body2'>{skl.name}</Typography></Stack>))}
                                            </Stack> : <></>
                                        }
                                        </Stack>
                                    </Stack>
                                </Stack>
                            </ShPaper>
                            {!applicantDetails?.atsPurchased && <>
                                {showFailureAlerts(getFullFingerPrintImageStatus, getFullFingerPrintImageResponse)}
                                <Stack rowGap={2}>
                                    <UnderlinedText variant="body1"  underlineType="border">
                                        Job Fit Details
                                    </UnderlinedText>
                                    <Typography variant="subtitle2">
                                        The JobFingerprint™ below shows whether <strong>{applicantDetails?.fullName}</strong> scores meet, exceed or are below the target areas.
                                        Interview questions have been created for you to probe on the watch out areas,
                                        where the applicant may have a score less than the expectations for this role.
                                    </Typography>
                                    <FingerprintImgContainer item xs={12} sm={12} md={6} lg={5} xl={4}>
                                        {getFullFingerPrintImageStatus === 'pending' ?
                                            <Skeleton width='100%' height='100%' animation='wave' /> :
                                            <>
                                                {/* /Placeholder/Parent block to which the fingerprint SVG will be appended. */}
                                                <FingerprintImageContainer
                                                    id={applicantId + 'full_fingerprint_svg_parent'}>
                                                </FingerprintImageContainer>
                                                <img alt="Hiring guide" src={HiringGuide}></img>
                                            </>
                                        }
                                    </FingerprintImgContainer>
                                </Stack>
                            </>}
                        </>}
                    </Stack>
                </ShContainer>
            </ShPaper>
        </Stack>
    </>);
};
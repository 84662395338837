import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import {
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  ToggleButton,
  Typography,
} from '@mui/material';
import { InterviewPreviewDialog } from 'Modules/Core/Interviews/InterviewPreviewDialog';
import {
  InterviewDialogStyled,
  MenuProps,
} from 'Modules/Core/Interviews/Interviews.styled';
import {
  CreateInterviewPayload,
  CreateZoomMeetPayload,
  InterviewDurations,
  InterviewModeIconMap,
  InterviewModes,
  VideoInterviewTypes,
} from 'Modules/Core/Interviews/InterviewsConstants';
import {
  ICreateZoomMeetPayload,
  IInterviewState,
  InterviewStatus,
  InterviewType,
  ITimeSlot,
  VideoInterviewType,
} from 'Modules/Core/Interviews/InterviewsModel';
import { useNotification } from 'Modules/Core/Notification';
import {
  IsSmScreen,
  IsXsScreen,
  useAppDispatch,
  useAppSelector,
} from 'helpers/hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  ShButton,
  ShGreenBtn,
  ShTextFieldV2,
  ShToggleButtonGroup,
} from 'shared/SharedStyles/ShInputs';
import { ShContainer } from 'shared/SharedStyles/ShLayouts';
import { getTimezones } from 'store/slices/common/timezone-slice';
import {
  resetRejectionEmail,
  resetZoomCreation,
  resetZoomDelete,
  resetZoomUpdate,
} from 'store/slices/employer/applicants/applicant-actions-slice';
import { changeApplicantStageInProfile } from 'store/slices/employer/applicants/applicant-profile-slice';
import { changeApplicantStageInList } from 'store/slices/employer/applicants/applicants-list-slice';
import {
  createInterviewRequest,
  resetCreateInterview,
  resetUpdateInterviewSchedule,
  resetUpdateInterviewRequest,
  updateInterviewCancelStatus,
  updateInterviewRequest,
  updateInterviewSchedule,
  scheduleInterview,
  createScheduledInterview,
  resetCreateScheduledInterview,
  resetScheduleInterview,
} from 'store/slices/employer/interviews/interviews-actions-slice';
import {
  getInterviewDetails,
  resetGetInterviewDetails,
} from 'store/slices/employer/interviews/interviews-details-slice';
import { closeInterviewDialog } from 'store/slices/employer/interviews/interviews-shared-slice';
import AddAttendeesDialog from './AddAttendeesDialog';
import { generateTimeIntervals } from './InterviewsUtilities';
import { ZoomMeet } from './ZoomMeet';
import InterviewTimeSlot from './Components/InterviewTimeSlot';
import InterviewRequestTimeSlots from './Components/InterviewRequestTimeSlots';
import InterviewTimeSlotsSelection from './Components/InterviewTimeSlotsSelection';
import ShowStatus from './Components/ShowStatus';
import { getInterviewsList } from 'store/slices/employer/interviews/interviews-list-slice';
import { isEmpty } from 'lodash';
import { ShPaper } from 'shared/SharedStyles/ShSurfaces';

export const InterviewDialog = () => {
  const isSmScreen = IsSmScreen();
  const isXsScreen = IsXsScreen();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const {
    zoomMeetCreationStatus,
    zoomMeetResponse,
    zoomMeetUpdateStatus,
    zoomMeetDeleteStatus,
  } = useAppSelector((state) => state.employer.applicants.applicantActions);
  const {
    createInterviewScheduleStatus,
    createInterviewScheduleResponse,
    updateInterviewScheduleStatus,
    updateInterviewScheduleResponse,
    updateInterviewRequestResponse,
    updateInterviewRequestStatus,
    scheduleInterviewStatus,
    createScheduledInterviewStatus,
    createScheduledInterviewResponse,
    scheduleInterviewResponse,
  } = useAppSelector((state) => state.employer.interviews.interviewsActions);
  const { interviewDetails, getInterviewDetailsStatus } = useAppSelector(
    (state) => state.employer.interviews.interviewsDetails
  );
  const { requestParams } = useAppSelector(
    (state) => state.employer.interviews.interviewsList
  );
  const {
    applicantInfo,
    candidateName,
    interviewId,
    isInterviewDialogOpen,
    jobId,
    candidateEmployerJobId,
    stage,
    usedIn,
  } = useAppSelector((state) => state.employer.interviews.interviewsShared);
  const zoomMeetRef = useRef<any>(null);
  const [_interviewDetails, _setInterviewDetails] = useState<IInterviewState>({
    ...CreateInterviewPayload,
  });
  const [zoomMeet, setZoomMeet] = useState<ICreateZoomMeetPayload>({
    ...CreateZoomMeetPayload,
  });
  const [videoInterviewType, setVideoInterviewType] = useState<
    VideoInterviewType | ''
  >('');
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] =
    useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
  const [timeInterval, setTimeInterval] = useState<number | 'custom'>(30);
  const [customInterval, setCustomInterval] = useState<number | string>(0);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState<string>('');
  const [isAttendeeDialogOpened, setIsAttendeeDialogOpened] = useState(false);
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState<number>();
  const [selectedCRTSIndex, setSelectedCRTSIndex] = useState<number>();
  const [acceptedTimeSlots, setAcceptedTimeSlots] = useState<ITimeSlot[]>([]);

  const fetchInterviewDetails = useCallback(() => {
    if (interviewId) {
      dispatch(getInterviewDetails({ id: interviewId }));
    }
  }, [dispatch, interviewId]);

  useEffect(() => {
    dispatch(getTimezones());
  }, [dispatch]);

  const _closeInterviewDialog = useCallback(() => {
    /*
     * Reset and clear every state and variable before closing dialog,
     *   to avoid any unexpected behavior.
     * Since the model instance is not complete removed.
     */
    setZoomMeet({ ...CreateZoomMeetPayload });
    _setInterviewDetails({ ...CreateInterviewPayload });
    setVideoInterviewType('');
    setAcceptedTimeSlots([]);
    setSelectedTimeSlotIndex(undefined);
    setSelectedCRTSIndex(undefined);
    setCustomInterval(0);
    setTimeInterval(30);
    setSelectedTimeInterval('');
    setIsPreviewDialogOpen(false);
    setIsSubmitDisabled(false);
    dispatch(closeInterviewDialog());
    dispatch(resetCreateInterview());
    dispatch(resetGetInterviewDetails());
    dispatch(resetUpdateInterviewSchedule());
    dispatch(resetUpdateInterviewRequest());
    dispatch(resetZoomCreation());
    dispatch(resetZoomUpdate());
    dispatch(resetZoomDelete());
    dispatch(resetCreateScheduledInterview());
    dispatch(resetScheduleInterview());
    dispatch(getInterviewsList(requestParams!));
  }, [dispatch, requestParams]);

  const onInterviewModeChange = (interviewType: InterviewType) => {
    // Reset 'zoomMeet' payload and 'videoInterviewType' if interview type is not video.
    if (interviewType !== 'Video') {
      setZoomMeet({
        agenda: '',
        from_time: '',
        password: '',
        schedule_date: '',
        topic: '',
      });
      setVideoInterviewType('');
    }
    _setInterviewDetails({
      ..._interviewDetails,
      type_of_interview: interviewType,
      additional_interview_info: '',
    });
  };

  // Load interview details if interview id is given.
  useEffect(() => {
    if (isInterviewDialogOpen) {
      fetchInterviewDetails();
    }
  }, [dispatch, fetchInterviewDetails, isInterviewDialogOpen]);

  // Load interview details if interview is edited.
  useEffect(() => {
    if (
      zoomMeetUpdateStatus === 'success' ||
      zoomMeetDeleteStatus === 'success'
    ) {
      fetchInterviewDetails();
    }
  }, [fetchInterviewDetails, zoomMeetDeleteStatus, zoomMeetUpdateStatus]);

  useEffect(() => {
    if (getInterviewDetailsStatus === 'pending') {
      dispatch(resetZoomCreation());
      dispatch(resetZoomUpdate());
      dispatch(resetZoomDelete());
      dispatch(resetRejectionEmail());
    }
  }, [dispatch, getInterviewDetailsStatus]);

  // Populate interview details on edit mode.
  useEffect(() => {
    if (getInterviewDetailsStatus === 'success' && interviewDetails) {
      const {
        from_time,
        additional_interview_info,
        note,
        schedule_date,
        to_time,
        type_of_interview,
        zoom_meeting_id,
        zoom_meeting,
        timezone,
        additional_attendees_email,
        request_time_slots,
        status,
        candidate_requested_time_slots,
      } = interviewDetails;

      _setInterviewDetails({
        from_time: from_time,
        additional_interview_info: additional_interview_info,
        note: note,
        schedule_date: schedule_date,
        to_time: to_time,
        type_of_interview: type_of_interview,
        zoom_meeting_id: zoom_meeting_id,
        timezone,
        additional_attendees_email: additional_attendees_email ?? [],
        request_time_slots: request_time_slots ?? [],
        status: status,
        candidate_requested_time_slots,
      });

      if (type_of_interview === 'Video') {
        if (zoom_meeting_id && zoom_meeting?.response_data) {
          setVideoInterviewType('zoom');
          const { agenda, start_time, password, topic } =
            zoom_meeting?.response_data;

          // retrieve date and time from 'start_time' since its in ISO format.
          const dateTime = new Date(start_time);
          const date = dateTime.toDateString();
          const time = dateTime.toLocaleTimeString();

          setZoomMeet({
            agenda: agenda,
            from_time: time,
            password: password,
            schedule_date: date,
            topic: topic,
            zoom_meeting_id: zoom_meeting_id,
          });
        } else {
          setVideoInterviewType('other');
        }
      }

      // Calculate time interval with from and to times.
      if (from_time && to_time) {
        // Convert the times to Date objects
        const fromTimeDate = new Date(`1970-01-01T${from_time}:00`);
        const toTimeDate = new Date(`1970-01-01T${to_time}:00`);

        // Calculate the difference in milliseconds
        const timeDiff: number = toTimeDate.getTime() - fromTimeDate.getTime();

        // Convert the difference to minutes
        const _timeIntervalInMins = timeDiff / (1000 * 60);
        if (
          InterviewDurations.map((d) => d.value).includes(_timeIntervalInMins)
        ) {
          setTimeInterval(_timeIntervalInMins);
        } else {
          setTimeInterval('custom');
          setCustomInterval(_timeIntervalInMins);
        }
        const _timeInterval = generateTimeIntervals(_timeIntervalInMins).find(
          (tI) => tI.startTime === from_time && tI.endTime === to_time
        )?.timeInterval;
        if (_timeInterval) {
          setSelectedTimeInterval(_timeInterval);
        }
      }
    }
  }, [getInterviewDetailsStatus, interviewDetails]);

  // Disable submit button.
  useEffect(() => {
    const {
      additional_interview_info,
      schedule_date,
      type_of_interview,
      timezone,
    } = _interviewDetails;

    if (_interviewDetails?.status === 'Accepted') {
      const isValidSelection =
        Number.isInteger(selectedTimeSlotIndex) ||
        Number.isInteger(selectedCRTSIndex);
      return setIsSubmitDisabled(
        (videoInterviewType !== 'zoom' && !additional_interview_info) ||
          (videoInterviewType === 'zoom' &&
            (!zoomMeet.agenda || !zoomMeet.password)) ||
          !isValidSelection
      );
    }

    if (_interviewDetails.status === 'Requested') {
      const isValidInterval =
        (timeInterval !== 'custom' ? timeInterval : +customInterval) > 0;
      const isValidTimeSlot =
        _interviewDetails.request_time_slots.length !== 0 &&
        _interviewDetails.request_time_slots.every(
          (timeSlot) => !Object.values(timeSlot).includes('')
        );
      const isValidInterviewType =
        _interviewDetails.type_of_interview === 'In-person'
          ? !!_interviewDetails.additional_interview_info
          : !!_interviewDetails.type_of_interview;

      const isFormValid = [
        isValidInterval,
        isValidTimeSlot,
        isValidInterviewType,
      ].every(Boolean);

      return setIsSubmitDisabled(!isFormValid);
    }

    if (_interviewDetails?.status === 'Scheduled') {
      return setIsSubmitDisabled(
        (videoInterviewType !== 'zoom' && !additional_interview_info) ||
          (videoInterviewType === 'zoom' &&
            (!zoomMeet.agenda || !zoomMeet.password)) ||
          !schedule_date ||
          !type_of_interview ||
          !timezone
      );
    }
  }, [
    _interviewDetails,
    videoInterviewType,
    zoomMeet.agenda,
    zoomMeet.password,
    zoomMeet.zoom_meeting_id,
    timeInterval,
    customInterval,
    selectedTimeSlotIndex,
    selectedCRTSIndex,
  ]);

  const getSelectedTimeSlotIndex = useCallback(
    () =>
      interviewDetails?.request_time_slots.findIndex((slot) => {
        const { date, from_time, timezone, to_time } =
          acceptedTimeSlots[selectedTimeSlotIndex!];
        return (
          slot.date === date &&
          slot.from_time === from_time &&
          slot.timezone === timezone &&
          slot.to_time === to_time
        );
      }),
    [
      acceptedTimeSlots,
      interviewDetails?.request_time_slots,
      selectedTimeSlotIndex,
    ]
  );

  useEffect(() => {
    if (zoomMeetCreationStatus !== 'success') return;

    const {
      additional_attendees_email,
      from_time,
      note,
      schedule_date,
      timezone,
      to_time,
      type_of_interview,
    } = _interviewDetails;

    const additional_interview_info = zoomMeetResponse?.response?.agenda ?? '';
    const zoom_meeting_id = zoomMeetResponse?.id;

    if (
      interviewDetails?.status === 'Accepted' &&
      _interviewDetails?.status === 'Accepted'
    ) {
      const scheduleAcceptedIndex =
        selectedTimeSlotIndex && getSelectedTimeSlotIndex();
      if (scheduleAcceptedIndex !== undefined) {
        dispatch(
          scheduleInterview({
            body: {
              additional_attendees_email,
              additional_interview_info,
              note,
              scheduleAcceptedIndex,
              scheduleRequestedIndex: selectedCRTSIndex,
              zoom_meeting_id,
            },
            id: interviewDetails.id,
          })
        );
      }
    } else if (
      (interviewDetails?.status === 'Accepted' ||
        interviewDetails?.status === 'Requested') &&
      _interviewDetails?.status === 'Scheduled'
    ) {
      dispatch(
        updateInterviewSchedule({
          id: interviewDetails.id,
          body: {
            from_time,
            to_time,
            schedule_date,
            type_of_interview,
            additional_interview_info,
            note,
            zoom_meeting_id,
            timezone,
            additional_attendees_email,
          },
        })
      );
    } else {
      dispatch(
        createScheduledInterview({
          additional_attendees_email,
          additional_interview_info,
          from_time,
          note,
          schedule_date,
          timezone,
          to_time,
          type_of_interview,
          application_id: applicantInfo?.candidateEmployerJobId,
          zoom_meeting_id,
        })
      );
    }
  }, [
    getSelectedTimeSlotIndex,
    dispatch,
    interviewDetails,
    _interviewDetails,
    zoomMeetCreationStatus,
    zoomMeetResponse?.id,
    zoomMeetResponse?.response?.agenda,
    applicantInfo?.candidateEmployerJobId,
    selectedCRTSIndex,
    selectedTimeSlotIndex,
  ]);

  const handleInterviewSchedule = () => {
    const {
      from_time,
      to_time,
      schedule_date,
      type_of_interview,
      additional_interview_info,
      note,
      zoom_meeting_id,
      timezone,
      additional_attendees_email,
    } = _interviewDetails;

    if (interviewDetails?.status === 'Scheduled') {
      dispatch(
        updateInterviewSchedule({
          id: interviewDetails.id,
          body: {
            from_time,
            to_time,
            schedule_date,
            type_of_interview,
            additional_interview_info,
            note,
            zoom_meeting_id,
            timezone,
            additional_attendees_email,
          },
        })
      );
    } else if (videoInterviewType === 'zoom' && type_of_interview === 'Video') {
      zoomMeetRef.current?.handleZoomMeet('create');
    } else {
      dispatch(
        createScheduledInterview({
          from_time,
          to_time,
          schedule_date,
          type_of_interview,
          additional_interview_info,
          note,
          timezone,
          additional_attendees_email,
          application_id: applicantInfo?.candidateEmployerJobId,
        })
      );
    }
  };

  const handleInterviewAccept = () => {
    if (
      videoInterviewType === 'zoom' &&
      _interviewDetails.type_of_interview === 'Video'
    ) {
      zoomMeetRef.current?.handleZoomMeet('create');
    } else {
      const {
        additional_attendees_email,
        additional_interview_info,
        note,
        zoom_meeting_id,
      } = _interviewDetails;

      const scheduleAcceptedIndex =
        selectedTimeSlotIndex && getSelectedTimeSlotIndex();

      dispatch(
        scheduleInterview({
          body: {
            additional_attendees_email,
            additional_interview_info,
            note,
            scheduleAcceptedIndex: scheduleAcceptedIndex!,
            zoom_meeting_id,
            scheduleRequestedIndex: selectedCRTSIndex,
          },
          id: interviewDetails?.id!,
        })
      );
    }
  };

  const handleInterviewRequest = () => {
    const { additional_interview_info, request_time_slots, type_of_interview } =
      _interviewDetails;

    if (interviewDetails?.status === 'Requested') {
      dispatch(
        updateInterviewRequest({
          id: interviewDetails.id,
          body: {
            additional_interview_info,
            request_time_slots,
            type_of_interview,
          },
        })
      );
    } else {
      dispatch(
        createInterviewRequest({
          additional_interview_info,
          request_time_slots,
          type_of_interview,
          application_id: applicantInfo?.candidateEmployerJobId,
        })
      );
    }
  };

  // Show snackbar notifications when interview schedule is successfully created.
  useEffect(() => {
    if (createInterviewScheduleStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: createInterviewScheduleResponse,
      });
      // Change stage after interview is scheduled.
      if (candidateEmployerJobId && stage) {
        if (usedIn === 'profile') {
          dispatch(
            changeApplicantStageInProfile({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        } else if (usedIn === 'list') {
          dispatch(
            changeApplicantStageInList({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        }
      }
      _closeInterviewDialog();
    }
  }, [
    _closeInterviewDialog,
    candidateEmployerJobId,
    createInterviewScheduleResponse,
    createInterviewScheduleStatus,
    dispatch,
    notification,
    stage,
    usedIn,
  ]);

  useEffect(() => {
    if (createScheduledInterviewStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: createScheduledInterviewResponse,
      });
      // Change stage after interview is scheduled.
      if (candidateEmployerJobId && stage) {
        if (usedIn === 'profile') {
          dispatch(
            changeApplicantStageInProfile({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        } else if (usedIn === 'list') {
          dispatch(
            changeApplicantStageInList({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        }
      }
      _closeInterviewDialog();
    }
  }, [
    _closeInterviewDialog,
    candidateEmployerJobId,
    createScheduledInterviewResponse,
    createScheduledInterviewStatus,
    dispatch,
    notification,
    stage,
    usedIn,
  ]);

  // Show snackbar notifications when interview schedule is successfully updated.
  useEffect(() => {
    if (updateInterviewScheduleStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: updateInterviewScheduleResponse,
      });
      if (interviewId) {
        dispatch(
          updateInterviewCancelStatus({
            id: interviewId,
            body: { status: 'Scheduled', comments: 'Re scheduled' },
          })
        );
      }
      // Change stage after interview is scheduled.
      if (candidateEmployerJobId && stage) {
        if (usedIn === 'profile') {
          dispatch(
            changeApplicantStageInProfile({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        } else if (usedIn === 'list') {
          dispatch(
            changeApplicantStageInList({
              applicantId: candidateEmployerJobId,
              payload: { stage: stage, sendEmail: true },
            })
          );
        }
      }
      _closeInterviewDialog();
    }
  }, [
    _closeInterviewDialog,
    candidateEmployerJobId,
    dispatch,
    interviewId,
    notification,
    stage,
    updateInterviewScheduleResponse,
    updateInterviewScheduleStatus,
    usedIn,
  ]);
  useEffect(() => {
    if (updateInterviewRequestStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: updateInterviewRequestResponse,
      });
      _closeInterviewDialog();
    }
  }, [
    updateInterviewRequestStatus,
    updateInterviewRequestResponse,
    _closeInterviewDialog,
    notification,
  ]);

  useEffect(() => {
    if (scheduleInterviewStatus === 'success') {
      notification.displayNotification({
        open: true,
        type: 'success',
        message: scheduleInterviewResponse,
      });
      _closeInterviewDialog();
    }
  }, [
    scheduleInterviewStatus,
    scheduleInterviewResponse,
    _closeInterviewDialog,
    notification,
  ]);

  const onTimeIntervalChange = (_timeInterval: number | 'custom') => {
    setTimeInterval(_timeInterval);
    setSelectedTimeInterval('');
    setCustomInterval('');
  };

  const onCustomIntervalChange = (_timeInterval: string) => {
    // Allow only numbers to be entered
    if (/^\d*$/.test(_timeInterval)) {
      setCustomInterval(_timeInterval);
    }
  };

  useEffect(() => {
    const updateTimeSlotOnInterval = (timeSlot: ITimeSlot): ITimeSlot => {
      const interval =
        timeInterval !== 'custom' ? +timeInterval : +customInterval;

      const { from_time, to_time } = timeSlot;
      if (from_time && to_time) {
        const fromTimeDate = new Date(`1970-01-01T${from_time}:00`);
        const toTimeDate = new Date(`1970-01-01T${to_time}:00`);
        const timeDiff = toTimeDate.getTime() - fromTimeDate.getTime();
        const selectedTimeSlotInterval = timeDiff / (1000 * 60);

        if (selectedTimeSlotInterval !== interval) {
          return { ...timeSlot, from_time: '', to_time: '' };
        }
      }
      return timeSlot;
    };

    _setInterviewDetails((prev) => {
      const timeSlots = prev.request_time_slots?.map(updateTimeSlotOnInterval);
      return { ...prev, request_time_slots: timeSlots };
    });
  }, [timeInterval, customInterval]);

  const scheduleInterviewActionLookup: Record<InterviewStatus, () => void> = {
    Requested: handleInterviewRequest,
    Accepted: handleInterviewAccept,
    Scheduled: handleInterviewSchedule,
    Completed: () => {},
    Cancelled: () => {},
  };

  const scheduleInterviewLabelLookup: Record<InterviewStatus, string> = {
    Requested: 'Request interview time',
    Accepted: 'Schedule and Email',
    Scheduled: 'Schedule and Email',
    Completed: '',
    Cancelled: '',
  };

  useEffect(() => {
    const { request_time_slots, accepted_request_time_slots } =
      interviewDetails || {};
    if (request_time_slots && accepted_request_time_slots) {
      const filteredTimeSlots = request_time_slots.filter((_, index) =>
        accepted_request_time_slots.includes(index)
      );
      setAcceptedTimeSlots(filteredTimeSlots);
    }
  }, [interviewDetails]);

  return (
    <ShContainer maxWidth="sm">
      <InterviewDialogStyled
        fullScreen={isSmScreen}
        open={isInterviewDialogOpen}
        onClick={(e) => e.stopPropagation()}
        onClose={() => _closeInterviewDialog()}
        aria-labelledby="title"
        viewSize={900}
      >
        <ShPaper variant='outlined' headerSection>
          <DialogTitle
            variant="h6"
            id="title"
          >
            {getInterviewDetailsStatus === 'pending' ? (
              'Loading...'
            ) : interviewDetails ? (
              `Interview ${interviewDetails.status} - ${candidateName}`
            ) : (
              <Stack
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                <InsertInvitationOutlinedIcon />
                {_interviewDetails.status === 'Requested'
                  ? 'Request interview'
                  : 'Schedule interview'}
              </Stack>
            )}

            <IconButton
              className="close-btn"
              onClick={() => {
                _closeInterviewDialog();
              }}
            >
              <ClearIcon />
            </IconButton>
          </DialogTitle>
        </ShPaper>
        <DialogContent>
          <ShowStatus />
          {getInterviewDetailsStatus === 'pending' ? (
            <Stack justifyContent="center" alignItems="center">
              <CircularProgress />
            </Stack>
          ) : (
            <>
              <Stack gap={2}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gap={2}
                  alignItems="center"
                >
                  <Stack
                    gap={1}
                    display="flex"
                    direction="row"
                    alignItems="center"
                  >
                    <Typography variant="subtitle1">Applicant :</Typography>
                    <Chip label={candidateName || applicantInfo?.fullName} />
                  </Stack>
                  {interviewDetails?.status !== 'Scheduled' && (
                    <Stack direction="row" alignItems="center">
                      <Typography>Skip and schedule interview</Typography>
                      <Switch
                        checked={_interviewDetails.status === 'Scheduled'}
                        onChange={() => {
                          _setInterviewDetails((prev) => ({
                            ...prev,
                            status:
                              prev.status === 'Scheduled'
                                ? interviewDetails?.status ?? 'Requested'
                                : 'Scheduled',
                          }));
                        }}
                      />
                    </Stack>
                  )}
                </Stack>

                {_interviewDetails?.status !== 'Accepted' && (
                  <Stack>
                    <Stack direction="row" columnGap={1}>
                      <Typography variant="subtitle1">
                        Select interview duration
                      </Typography>
                      {isSmScreen && (
                        <Typography variant="subtitle1">(Minutes)</Typography>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      columnGap={1}
                      rowGap={2}
                      flexWrap="wrap"
                    >
                      {isSmScreen ? (
                        <Select
                          size="small"
                          value={timeInterval}
                          onChange={(e) =>
                            onTimeIntervalChange(
                              e.target.value as number | 'custom'
                            )
                          }
                          aria-label="Duration"
                          MenuProps={MenuProps}
                          renderValue={(selected) => selected}
                        >
                          {InterviewDurations.map((dur) => (
                            <MenuItem
                              color="grey"
                              key={dur.value}
                              value={dur.value}
                              aria-label={`${dur.label}`}
                            >
                              {dur.label}
                            </MenuItem>
                          ))}
                          <MenuItem
                            color="grey"
                            value={'custom'}
                            aria-label="Custom Duration"
                          >
                            Custom Duration
                          </MenuItem>
                        </Select>
                      ) : (
                        <ShToggleButtonGroup
                          color="primary"
                          size="small"
                          exclusive
                          orientation={isXsScreen ? 'vertical' : 'horizontal'}
                          value={timeInterval}
                          onChange={(e, newValue) =>
                            onTimeIntervalChange(newValue)
                          }
                          aria-label="Duration"
                        >
                          {InterviewDurations.map((dur) => (
                            <ToggleButton
                              key={dur.value}
                              value={dur.value}
                              aria-label={`${dur.label}`}
                            >
                              {dur.label}
                            </ToggleButton>
                          ))}
                          <ToggleButton
                            value={'custom'}
                            aria-label="Custom Duration"
                          >
                            Custom Duration
                          </ToggleButton>
                        </ShToggleButtonGroup>
                      )}

                      {timeInterval === 'custom' && (
                        <ShTextFieldV2
                          size="small"
                          value={customInterval}
                          onChange={(e) =>
                            onCustomIntervalChange(e.target.value)
                          }
                          label="Duration (Minutes)"
                          placeholder="Enter Duration (Minutes)"
                        />
                      )}
                    </Stack>
                  </Stack>
                )}

                {_interviewDetails.status === 'Requested' && (
                  <Stack gap={1}>
                    <Typography variant="body1" fontWeight={600}>
                      Interview date & time
                    </Typography>
                    <InterviewRequestTimeSlots
                      interval={
                        timeInterval !== 'custom'
                          ? +timeInterval
                          : +customInterval
                      }
                      isDisabled={zoomMeetCreationStatus === 'pending'}
                      timeSlots={_interviewDetails.request_time_slots}
                      updateTimeSlots={(timeSlots: ITimeSlot[]) =>
                        _setInterviewDetails((prev) => ({
                          ...prev,
                          request_time_slots: timeSlots,
                        }))
                      }
                    />
                  </Stack>
                )}

                {_interviewDetails?.status === 'Scheduled' && (
                  <Stack gap={1}>
                    <Typography variant="body1" fontWeight={600}>
                      Interview date & time
                    </Typography>
                    <InterviewTimeSlot
                      isDisabled={zoomMeetCreationStatus === 'pending'}
                      interval={
                        timeInterval !== 'custom'
                          ? +timeInterval
                          : +customInterval
                      }
                      timeSlot={{
                        date: _interviewDetails.schedule_date,
                        timezone: _interviewDetails.timezone,
                        from_time: _interviewDetails.from_time,
                        to_time: _interviewDetails.to_time,
                      }}
                      setTimezone={(timezone) =>
                        _setInterviewDetails((prev) => ({
                          ...prev,
                          timezone,
                        }))
                      }
                      setDate={(date) =>
                        _setInterviewDetails((prev) => ({
                          ...prev,
                          schedule_date: date,
                        }))
                      }
                      setTime={({ to_time, from_time }) =>
                        _setInterviewDetails((prev) => ({
                          ...prev,
                          to_time,
                          from_time,
                        }))
                      }
                    />
                  </Stack>
                )}

                {_interviewDetails?.status === 'Accepted' &&
                  acceptedTimeSlots.length > 0 && (
                    <Stack gap={1}>
                      <Typography variant="body1" fontWeight={600}>
                        Candidate accepted interview slot
                      </Typography>
                      <InterviewTimeSlotsSelection
                        timeSlots={acceptedTimeSlots}
                        onTimeSlotSelect={(selectedIndex) => {
                          setSelectedTimeSlotIndex(selectedIndex);
                          setSelectedCRTSIndex(undefined);
                        }}
                        selectedTimeSlot={selectedTimeSlotIndex}
                        isDisabled={zoomMeetCreationStatus === 'pending'}
                      />
                    </Stack>
                  )}

                {_interviewDetails?.status === 'Accepted' &&
                  !isEmpty(
                    _interviewDetails?.candidate_requested_time_slots
                  ) && (
                    <Stack gap={1}>
                      <Typography variant="body1" fontWeight={600}>
                        Candidate requested interview slot
                      </Typography>
                      <InterviewTimeSlotsSelection
                        timeSlots={
                          _interviewDetails.candidate_requested_time_slots ?? []
                        }
                        onTimeSlotSelect={(selectedIndex) => {
                          setSelectedCRTSIndex(selectedIndex);
                          setSelectedTimeSlotIndex(undefined);
                        }}
                        selectedTimeSlot={selectedCRTSIndex}
                        isDisabled={zoomMeetCreationStatus === 'pending'}
                      />
                    </Stack>
                  )}

                <Stack gap={1}>
                  <Stack>
                    <Typography variant="body1" fontWeight={600} gutterBottom>
                      Interview Type
                    </Typography>
                    <ShToggleButtonGroup
                      color="primary"
                      size="small"
                      exclusive
                      variant="pill"
                      buttonsGap={1.5}
                      minWidth="100px"
                      orientation={isXsScreen ? 'vertical' : 'horizontal'}
                      value={_interviewDetails.type_of_interview}
                    >
                      {InterviewModes.map((interviewMode) => (
                        <ToggleButton
                          value={interviewMode}
                          key={interviewMode}
                          size="small"
                          onClick={() => onInterviewModeChange(interviewMode)}
                          disabled={_interviewDetails.status === 'Accepted'}
                        >
                          <Stack
                            direction="row"
                            columnGap={1}
                            alignItems="center"
                          >
                            {InterviewModeIconMap[interviewMode]}
                            <Typography variant="body2">
                              {interviewMode}
                            </Typography>
                          </Stack>
                        </ToggleButton>
                      ))}
                    </ShToggleButtonGroup>
                  </Stack>
                  {(_interviewDetails.status === 'Scheduled' ||
                    _interviewDetails.status === 'Accepted') && (
                    <Stack gap={1}>
                      {_interviewDetails.type_of_interview === 'Video' && (
                        <Typography variant="body1" fontWeight={600}>
                          Select meeting software
                        </Typography>
                      )}
                      {_interviewDetails.type_of_interview === 'Video' && (
                        <FormControl
                          fullWidth
                          disabled={zoomMeetCreationStatus === 'pending'}
                          size="small"
                        >
                          <InputLabel id="duration-select-label">
                            Meeting software
                          </InputLabel>
                          <Select
                            label="Meeting software"
                            value={videoInterviewType}
                            id="i_t"
                            size="small"
                            onChange={(e) => {
                              setVideoInterviewType(
                                e.target.value as VideoInterviewType
                              );
                              _setInterviewDetails({
                                ..._interviewDetails,
                                additional_interview_info: '',
                              });
                            }}
                          >
                            {VideoInterviewTypes.map((vIT) => (
                              <MenuItem key={vIT.value} value={vIT.value}>
                                {vIT.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Stack>
                  )}
                </Stack>
                {(_interviewDetails.status === 'Scheduled' ||
                  _interviewDetails.status === 'Accepted' ||
                  _interviewDetails.type_of_interview === 'In-person') && (
                  <Stack gap={1}>
                    {_interviewDetails.type_of_interview === 'Video' &&
                      videoInterviewType !== 'zoom' && (
                        <Typography variant="body1" fontWeight={600}>
                          Share video call link
                        </Typography>
                      )}
                    {_interviewDetails.type_of_interview === 'In-person' && (
                      <Typography variant="body1" fontWeight={600}>
                        Enter the Location Address
                      </Typography>
                    )}
                    {_interviewDetails.type_of_interview === 'Phone' && (
                      <Typography variant="body1" fontWeight={600}>
                        Enter the Phone number
                      </Typography>
                    )}

                    {_interviewDetails.type_of_interview &&
                      (applicantInfo || interviewDetails) &&
                      jobId && (
                        <>
                          {videoInterviewType === 'zoom' &&
                          _interviewDetails.type_of_interview === 'Video' ? (
                            <ZoomMeet
                              applicantInfo={applicantInfo}
                              interviewDetails={_interviewDetails}
                              setZoomMeet={setZoomMeet}
                              zoomMeet={zoomMeet}
                              jobId={jobId}
                              forwardedRef={zoomMeetRef}
                              interviewInfo={interviewDetails}
                            />
                          ) : (
                            <ShTextFieldV2
                              size="small"
                              id="i_location"
                              disabled={
                                _interviewDetails.status === 'Accepted' &&
                                _interviewDetails.type_of_interview ===
                                  'In-person'
                              }
                              label={
                                _interviewDetails.type_of_interview ===
                                'In-person'
                                  ? 'Interview Location/Address'
                                  : _interviewDetails.type_of_interview ===
                                    'Video'
                                  ? 'Video Link'
                                  : 'Phone number of the applicant'
                              }
                              required
                              variant="outlined"
                              value={
                                _interviewDetails.additional_interview_info ||
                                ''
                              }
                              onChange={(e) =>
                                _setInterviewDetails({
                                  ..._interviewDetails,
                                  additional_interview_info: e.target.value,
                                })
                              }
                            />
                          )}
                        </>
                      )}
                  </Stack>
                )}
                {(_interviewDetails.status === 'Scheduled' ||
                  _interviewDetails.status === 'Accepted') && (
                  <Stack>
                    <Typography variant="body1" fontWeight={600}>
                      Additional Attendees
                    </Typography>
                    <ShButton
                      variant="outlined"
                      color="inherit"
                      disabled={isAttendeeDialogOpened}
                      startIcon={<AddIcon />}
                      fullWidth
                      onClick={() => setIsAttendeeDialogOpened(true)}
                    >
                      {_interviewDetails.additional_attendees_email.length === 0
                        ? 'Add Additional Attendees'
                        : 'Change Additional Attendees'}
                    </ShButton>
                    <Stack
                      marginTop={2}
                      direction="row"
                      columnGap={1}
                      flexWrap="wrap"
                      rowGap={1}
                    >
                      {_interviewDetails.additional_attendees_email.map(
                        (email, index) => (
                          <Chip size="small" key={index} label={email} />
                        )
                      )}
                    </Stack>
                  </Stack>
                )}
                {(_interviewDetails.status === 'Scheduled' ||
                  _interviewDetails.status === 'Accepted') && (
                  <Stack>
                    <Typography variant="body1" fontWeight={600}>
                      Additional Info
                    </Typography>
                    <ShTextFieldV2
                      minRows={7}
                      id="i_notes"
                      label="More details"
                      variant="outlined"
                      multiline
                      value={_interviewDetails.note}
                      disabled={zoomMeetCreationStatus === 'pending'}
                      onChange={(e) =>
                        _setInterviewDetails({
                          ..._interviewDetails,
                          note: e.target.value,
                        })
                      }
                    />
                  </Stack>
                )}
              </Stack>
            </>
          )}
        </DialogContent>

        <DialogActions>
          {getInterviewDetailsStatus !== 'pending' && (
            <Stack direction="row" justifyContent="space-between" width="100%">
              <ShButton
                variant="contained"
                disableElevation
                size="small"
                onClick={() => setIsPreviewDialogOpen(true)}
              >
                Preview
              </ShButton>
              <ShGreenBtn
                variant="contained"
                disableElevation
                size="small"
                onClick={() => {
                  setIsSubmitDisabled(true);
                  scheduleInterviewActionLookup[_interviewDetails.status]();
                }}
                disabled={
                  isSubmitDisabled ||
                  createInterviewScheduleStatus === 'pending'
                }
                className="self-right"
              >
                {scheduleInterviewLabelLookup[_interviewDetails.status]}
              </ShGreenBtn>
            </Stack>
          )}
        </DialogActions>
      </InterviewDialogStyled>

      {applicantInfo && jobId && (
        <InterviewPreviewDialog
          isDialogOpen={isPreviewDialogOpen}
          setIsDialogOpen={setIsPreviewDialogOpen}
          applicantInfo={applicantInfo}
          interviewInfo={_interviewDetails}
          jobId={jobId}
          selectedTime={selectedTimeInterval}
        />
      )}

      <AddAttendeesDialog
        isDialogOpen={isAttendeeDialogOpened}
        selectedAttendees={_interviewDetails.additional_attendees_email}
        setSelectedAttendees={(emails) =>
          _setInterviewDetails((prevInterview) => ({
            ...prevInterview,
            additional_attendees_email: emails,
          }))
        }
        onClose={setIsAttendeeDialogOpened}
      />
    </ShContainer>
  );
};

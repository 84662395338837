import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { ContactIcon, HelpGrid, HeroHelp, HeroTitle, ItemCard, SearchInput } from 'Modules/Core/Help/Help.styled';
import helpPageCards from 'Modules/Core/Help/HelpContent';
import { Link } from 'react-router-dom';
import { ResourceFooter } from 'Modules/Marketing/Resources/ResourceFooter';
import { ShTextFieldV2 } from 'shared/SharedStyles/ShInputs';

interface ItemProps {
  title: string;
  description: string;
}

export const Help = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  
  // Function to handle search input change
  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  // Filtering logic based on search query
  const filteredCards = helpPageCards.filter(card =>
    card.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    card.desc.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const Item = ({ title, description }: ItemProps) => {
    return (
      <ItemCard color='black'>
        <Typography variant="h6" gutterBottom>{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </ItemCard>
    );
  };

  return (
    <>
      <HeroHelp>
        <HeroTitle>How Can We Help?</HeroTitle>
        <SearchInput>
          <ShTextFieldV2
            placeholder="Search for answers"
            variant="standard"
            value={searchQuery}
            onChange={handleSearchInputChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </SearchInput>
      </HeroHelp>
      <HelpGrid>
        <Grid container spacing={6}>
          {filteredCards.map((card, id) => (
            <Grid key={id} item xs={12} md={6} lg={4} xl={4}>
              <Link to={card.path} style={{ textDecoration: "none" }}>
                <Item title={card.title} description={card.desc} />
              </Link>
            </Grid>
          ))}
        </Grid>
      </HelpGrid>
      <ResourceFooter />
      <ContactIcon>
        {/* To Do In Future */}
        {/* <Fab color="primary" aria-label="contact" style={{ padding: '26px 0' }}>
          <ChatOutlinedIcon />
        </Fab> */}
      </ContactIcon>
    </>
  );
};

import { useState } from "react";
import { Tab, Box, Fade } from "@mui/material";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { DashboardQuickActionTilesWidget } from "./DashboardQuickActionTilesWidget";
import { DashboardToolsTilesWidget } from "./DashboardToolsTilesWidget";
import { DashboardTabs } from "shared/SharedStyles/ShNavigation";
import { Home, Build } from '@mui/icons-material';

export const DashboardQuickActionTiles = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <ShPaper height="100%" marginBottom="10px" variant="outlined" borderRadius={15}>
      <DashboardTabs value={activeTab} onChange={handleTabChange}>
        <Tab
          icon={<Home sx={{ color: activeTab === 0 ? 'white' : 'primary.main' }} />}
          iconPosition="start"
          label="Get Started"
        />
        <Tab
          icon={<Build sx={{ color: activeTab === 1 ? 'white' : 'primary.main' }} />}
          iconPosition="start"
          label="Automation & Tools"
        />
      </DashboardTabs>
      <Box paddingTop={2}>
        <Fade in={activeTab === 0} timeout={1000}>
          <Box>
            {activeTab === 0 && <DashboardQuickActionTilesWidget />}
          </Box>
        </Fade>
        <Fade in={activeTab === 1} timeout={1000}>
          <Box>
            {activeTab === 1 && <DashboardToolsTilesWidget />}
          </Box>
        </Fade>
      </Box>
    </ShPaper>
  );
};

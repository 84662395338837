import { Typography, Stack, Grid, FormControlLabel, Radio, MenuItem, Divider, RadioGroup } from "@mui/material";
import { EEOQutionaareData, EqualOpportunityQuestionsProps } from "../CreateJob/EEOConfig";
import { ShTextFieldV2 } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "helpers/hooks";
import { fetchCandidateEEOQuestionsAnswers, updateCandidateEEOQuestionsJobApply } from "store/slices/candidate/apply-job/apply-job-questionnaire.slice";

export const EqualOpportunityQuestions: React.FC<EqualOpportunityQuestionsProps> = ({ eeoQuestions }) => {
  const dispatch = useAppDispatch();
  const { eeoQuestionAndAnswers } = useAppSelector((state) => state.candidate.applyJobQuestionnaire);
  const [answers, setAnswers] = useState<{ [key: number]: string }>({});
  const { candidateDetails } = useAppSelector((state) => state.candidate.candidateProfile);
  const filteredQuestions = EEOQutionaareData.filter((question) =>
    eeoQuestions.includes(question.idx)
  );

  const handleAnswerChange = (idx: number, answer: string) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [idx]: answer };
      if (candidateDetails) {
        dispatch(updateCandidateEEOQuestionsJobApply({
          jobApplicationId: candidateDetails.jobApplicationId,
          eeoAnswers: newAnswers,
        }));
      }
  
      return newAnswers; 
    });
  };
  
  useEffect(() => {
    if (candidateDetails?.jobApplicationId) {
      dispatch(fetchCandidateEEOQuestionsAnswers({ jobApplicationId: candidateDetails.jobApplicationId }));
    }
  }, [dispatch, candidateDetails?.jobApplicationId]);
  
  useEffect(() => {
    if (eeoQuestionAndAnswers) {
      setAnswers(eeoQuestionAndAnswers); 
    }
  }, [eeoQuestionAndAnswers]);
  
  
  return (
    <>
      <Divider />
      <Typography variant="body2">
        Please answer these Equal Opportunity Questions (Optional)
      </Typography>
      <Stack spacing={2} minHeight={200} paddingRight={1}>
        {filteredQuestions.map((question) => (
          <ShPaper variant="outlined" key={question.id}>
            <Typography variant="body2" gutterBottom>
              {question.label}
            </Typography>
            <Grid container spacing={2}>
              {question.type === "text" && (
                <Grid item xs={12}>
                  <ShTextFieldV2
                    variant="outlined"
                    fullWidth
                    size='small'
                    placeholder="Your answer"
                    value={answers[question.idx] || ""}
                    onChange={(e) => handleAnswerChange(question.idx, e.target.value)}
                  />
                </Grid>
              )}
              {question.type === "radio" && (
                <Grid item xs={12}>
                  <RadioGroup
                    value={answers[question.idx] || ""}
                    onChange={(e) => handleAnswerChange(question.idx, e.target.value)}
                  >
                    {question.options?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        value={option}
                        control={<Radio size='small' />}
                        label={<Typography variant="body2">{option}</Typography>}
                      />
                    ))}
                  </RadioGroup>
                </Grid>
              )}
              {question.type === "dropdown" && (
                <Grid item xs={12}>
                  <ShTextFieldV2
                    select
                    fullWidth
                    size='small'
                    variant="outlined"
                    value={answers[question.idx] || ""}
                    onChange={(e) => handleAnswerChange(question.idx, e.target.value)}
                  >
                    {question.options?.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </ShTextFieldV2>
                </Grid>
              )}
            </Grid>
          </ShPaper>
        ))}
      </Stack>
    </>
  );
};

import AddIcon from '@mui/icons-material/Add';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HelpIcon from '@mui/icons-material/Help';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import SettingsIcon from '@mui/icons-material/Settings';
import WorkIcon from '@mui/icons-material/Work';
import { ShMyInterviewsIcon } from "assets/Icons";
import { IShFeatureTile } from 'shared/SharedModels';
import { ShChip } from 'shared/SharedStyles/ShDataDisplay';
import { ShIconPrimary, ShIconSecondary } from "shared/SharedStyles/styleConstants";
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import { IApplicant } from '../Applicants/ApplicantsModel';

export const DashboardGetStartedFeatureTiles: IShFeatureTile[] = [
    {
        tileLabel: 'Post a Job',
        featureRedirectUrl: '/employer/job/new',
        tileIcon: <AddIcon color='primary' />,
        caption: 'Quickly create customized job listings to Attract Top Candidates.',
        featureName: 'Create Job'
    },
    {
        tileLabel: 'Active Jobs',
        featureRedirectUrl: '/employer/jobs',
        tileIcon: <WorkIcon color='primary' />,
        caption: 'View all active jobs. Track application status',
        featureName: 'View Jobs'
    },
    {
        tileLabel: 'Interviews',
        featureRedirectUrl: '/employer/interviews',
        tileIcon: <ShMyInterviewsIcon primaryColor={ShIconPrimary} secondaryColor={ShIconSecondary} />,
        caption: 'View upcoming interviews. Manage interview schedules.',
        featureName: 'Interviews'
    },
    // {
    //     tileLabel: 'Talent Dashboard',
    //     featureRedirectUrl: '/employer/talent-dashboard',  
    //     tileIcon: <FolderSpecialOutlinedIcon color='primary'/>,
    //     caption: 'Manage talent pool. Gain insights into your past hires & offers',
    //     featureName: 'Employee Insights'
    // },
    {
        tileLabel: 'Calendar',
        featureRedirectUrl: '/employer/calendar',
        tileIcon: <CalendarMonthIcon color='primary' />,
        caption: 'View all scheduled events. Track important dates.',
        featureName: 'Calendar'
    },
    {
        tileLabel: 'Analytics',
        featureRedirectUrl: '/employer/analytics',
        tileIcon: <QueryStatsIcon color='primary' />,
        caption: 'AI Powered Tools, Offer Letter Templates, Interview Kits',
        chip: <ShChip customSize="xs" label="NEW" color="primary" variant='outlined' size='small' />,
        featureName: 'Resources'
    },
    {
        tileLabel: 'Resources',
        featureRedirectUrl: '/employer/resources',
        tileIcon: <LibraryBooksIcon color='primary' />,
        caption: 'AI Powered Tools, Offer Letter Templates, Interview Kits',
        chip: <ShChip customSize="xs" label="NEW" color="primary" variant='outlined' size='small' />,
        featureName: 'Resources'
    },
    {
        tileLabel: 'Settings',
        featureRedirectUrl: '/employer/settings/account/account-information',
        tileIcon: <SettingsIcon color='primary' />,
        caption: 'Manage account preferences. Update personal info.',
        featureName: 'Settings'
    },
    {
        tileLabel: 'Help & More',
        featureRedirectUrl: '/employer/help',
        tileIcon: <HelpIcon color='primary' />,
        caption: 'Find user guides and FAQs. Get in touch with customer service.',
        featureName: 'Help'
    },
];

export const DashboardToolsFeatureTiles: IShFeatureTile[] = [
    // {
    //     tileLabel: 'Interview Templates',
    //     featureRedirectUrl: '/employer/settings/tools/templates/interview-invitation',
    //     tileIcon: <AssignmentIcon color='primary' />,
    //     caption: 'Create and customize interview templates to streamline the hiring process.',
    //     featureName: 'Offer Letter Template'
    // },
    {
        tileLabel: 'Application Confirmation Template',
        featureRedirectUrl: '/employer/settings/tools/templates/application-received',
        tileIcon: <NotificationsIcon color='primary' />,
        caption: 'Create a personalized email template to automatically notify candidates once they complete their job application.',
        featureName: 'Application Received Template'
    },
    {
        tileLabel: 'Rejection Templates',
        featureRedirectUrl: '/employer/settings/tools/templates/rejection-notification',
        tileIcon: <DoNotDisturbIcon color='primary' />,
        caption: 'Craft and manage rejection templates to communicate professionally with candidates.',
        featureName: 'Auto Reject'
    },
    // {
    //     tileLabel: 'Offer Letter Templates',
    //     featureRedirectUrl: '/employer/settings/tools/templates/offer-letter',
    //     tileIcon: <DescriptionIcon color='primary' />,
    //     caption: 'Design and maintain offer letter templates to secure top candidates efficiently.',
    //     featureName: 'Help'
    // },
    // {
    //     tileLabel: 'Interview Reminders',
    //     featureRedirectUrl: '/employer/settings/automation/reminders/interview-reminders',
    //     tileIcon: <NotificationsIcon color='primary' />,
    //     caption: 'Set up automated reminders to ensure timely interviews.',
    //     featureName: 'Interview Reminders'
    // },
    {
        tileLabel: 'Auto Reject Criteria',
        featureRedirectUrl: '/employer/settings/automation/auto-reject/criteria',
        tileIcon: <AutoDeleteIcon color='primary' />,
        caption: 'Define criteria for auto-rejection to streamline candidate screening.',
        featureName: 'Auto Reject Criteria'
    },
];

export const DashboardListSize = 30;
export const MaxDashboardApplicantSize = 999999;


export const getDotPropertiesAndMessage = (applicant: IApplicant, selectedJobName: string) => {
    let dotColor: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' = 'primary';
    let dotVariant: 'filled' | 'outlined' = 'outlined';
    let message = `${applicant.firstName} was ${applicant.jobStage}`;

    switch (applicant.jobStage) {
        case 'new':
            dotColor = 'primary';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} has applied to ${selectedJobName}`;
            break;
        case 'reviewed':
            dotColor = 'primary';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} has been reviewed`;
            break;
        case 'interview':
            dotColor = 'success';
            dotVariant = 'outlined';
            message = `${applicant.firstName} ${applicant.lastName} is scheduled to be interviewed`;
            break;
        case 'second_interview':
            dotColor = 'success';
            dotVariant = 'outlined';
            message = `${applicant.firstName} ${applicant.lastName} 2nd interview has been scheduled`;
            break;
        case 'offer':
            dotColor = 'success';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} was given an offer for ${selectedJobName}`;
            break;
        case 'hired':
            dotColor = 'success';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} was hired for ${selectedJobName} 🎉`;
            break;
        case 'phone_screening':
            dotColor = 'primary';
            dotVariant = 'outlined';
            message = `${applicant.firstName} ${applicant.lastName} has been scheduled for a phone interview`;
            break;
        case 'rejected':
            dotColor = 'error';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} was rejected from ${selectedJobName}`;
            break;
        case 'not_qualified':
            dotColor = 'error';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} has been categorized as not qualified for ${selectedJobName}`;
            break;
        case 'auto_rejected':
            dotColor = 'error';
            dotVariant = 'filled';
            message = `${applicant.firstName} ${applicant.lastName} has been auto rejected for ${selectedJobName}`;
            break;
    }

    return { dotColor, dotVariant, message };
};

export const DashboardTileBordeRadius = 15;
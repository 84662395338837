import { Box, LinearProgress, Stack, Typography } from "@mui/material";
import { useAppSelector } from "helpers/hooks";
import { useNavigate, useParams } from "react-router-dom";
import { ShGreenBtn } from "shared/SharedStyles/ShInputs";
import { ShPaper } from "shared/SharedStyles/ShSurfaces";


export const CreateJobFormHeader = () => {
    const navigate = useNavigate();
    const { jobId } = useParams();
    const { companyInfo, getCompanyInfoApiResponse } = useAppSelector((state) => state.employer.employerSettings.companyInfo);

    return (
        <>
            {getCompanyInfoApiResponse === 'pending' ?
                <LinearProgress/>
            :
            <ShPaper variant="outlined"  borderRadius={0} headerSection>
                <Stack direction='row' justifyContent='space-between' alignItems='center'> 
                    <Stack spacing={1}>
                        <Typography variant="subtitle1" gutterBottom paddingLeft={2}>
                        Choose a questionnaire template to add additional questions. 
                        </Typography>
                        <Typography variant="body2" color="textSecondary" paddingLeft={2} maxWidth={800}>
                            Add questions and specify answer options. You can also mark options as auto-reject to streamline your process. Enable the additional questions by toggling the switch by the form name
                        </Typography>
                    </Stack>
                    <Box height={100}>
                        {companyInfo.is_eeo_enabled === true &&
                            <ShGreenBtn size='small' variant='contained' 
                                onClick={()=>{navigate(`/employer/job/${jobId}/eeoQuestionnaire`)}}>
                                Skip
                            </ShGreenBtn>
                        }

                        {companyInfo?.is_eeo_enabled === false &&
                            <ShGreenBtn size='small' variant='contained' 
                                onClick={()=>{navigate(`/employer/job/${jobId}/preview`)}}>
                                Skip
                            </ShGreenBtn>
                        }
                    </Box>
                </Stack>
            </ShPaper>
            }
        </>

    
    )
}
import {
    Box,
    Button,
    ButtonProps,
    Checkbox,
    Chip,
    CircularProgress,
    InputBase,
    RadioGroup,
    TextField,
    ToggleButtonGroup,
} from '@mui/material';
import { darken, styled, Theme } from '@mui/material/styles';
import {
    BorderColorDark,
    BorderColorLight,
    LogoutRedColor,
    ShBorderRadius,
    ShGreen,
    WhiteColor,
} from 'shared/SharedStyles/styleConstants';


export const AutocompletePopoverProps = { style: { maxHeight: '250px' } };

export const AutocompleteLoaders = styled(CircularProgress)(
    ({ theme }) => ({
        marginRight: '30px'
    })
);

export const LeftNavButtonStyled = styled(Button)<IShButton>(
    ({ theme }) => ({
        fontWeight: 400,
        justifyContent: 'flex-start',
        textTransform: 'none',
        padding: '8px 0px 8px 20px',
        color: 'inherit',
        '& .MuiSvgIcon-root': {
            marginRight: '5px',
        },
        '& .MuiSvgIcon-root.logout-icon': {
            color: theme.palette.mode === 'light' ? LogoutRedColor : WhiteColor,
        }
    })
);

interface IShButton extends Omit<ButtonProps, 'component'> {
    borderRadius?: number;
    component?: React.ElementType;
    to?: string;
    minWidth?: string | number;
    marginLeft?: string;
    textColor?: string;
}

export const ShButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "minWidth" && prop !== "marginLeft" && prop !== "textColor"
})<IShButton>(({ theme, fullWidth, borderRadius = ShBorderRadius, minWidth = 'unset', marginLeft = 'unset', textColor }) => ({
    borderRadius: borderRadius,
    textTransform: 'none',
    width: fullWidth ? "100%" : 'fit-content',
    minWidth: minWidth,
    marginLeft: marginLeft,
    color: textColor, 
    '&.MuiButton-text': {
        textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
    },
    '&.MuiButton-outlined': {
        paddingTop: 0,
        paddingBottom: 0
    }
}));

export const ShGreenBtn = styled(Button, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "minWidth" && prop !== "marginLeft"
})<IShButton>(({ theme, fullWidth, borderRadius = ShBorderRadius, minWidth = 'unset', marginLeft = 'unset' }) => ({
    textTransform: 'none',
    borderRadius: borderRadius,
    width: 'fit-content',
    '&.self-center': {
        alignSelf: 'center'
    },
    '&.self-right': {
        alignSelf: 'flex-end'
    },
    '&:not(.Mui-disabled)': {
        backgroundColor: ShGreen,
        color: WhiteColor,
        '&.MuiButton-outlined': {
            borderColor: ShGreen
        }
    },
    '&:hover': {
        // darken function from @mui. Takes in a color and value to darken the given color.
        backgroundColor: darken(ShGreen, 0.1),
    },
})
);

export const ShFileUploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: '8px 16px',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    textTransform: 'none',
}));

export const ShWordpressButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'borderRadius',
})<IShButton>(({ theme, fullWidth, borderRadius = ShBorderRadius }) => ({
    borderRadius: borderRadius,
    textTransform: 'none',
    width: fullWidth ? '100%' : 'fit-content',
    minWidth: 'unset',
    color: WhiteColor,
    backgroundColor: ShGreen,
    '&:hover': {
        backgroundColor: theme.palette.augmentColor({ color: { main: ShGreen } }).dark,
    },
    '&.MuiButton-text': {
        textDecoration: theme.palette.mode === 'dark' ? 'underline' : 'none',
    },
    '&.MuiButton-outlined': {
        paddingTop: 0,
        paddingBottom: 0,
    },
}));

const ShToggleButtonGroupDefault = (theme: Theme, borderRadius: string | number, minWidth: string | undefined) => {
    return {
        '& .MuiToggleButton-root': {
            padding: theme.spacing(1),
            minWidth: minWidth || 'unset',
            textTransform: 'none',
            ':first-of-type': {
                borderBottomLeftRadius: borderRadius,
                borderTopLeftRadius: borderRadius
            },
            ':last-of-type': {
                borderBottomRightRadius: borderRadius,
                borderTopRightRadius: borderRadius
            }
        },
        '&.MuiToggleButtonGroup-vertical': {
            '& .MuiToggleButton-root': {
                ':first-of-type': {
                    borderTopLeftRadius: borderRadius,
                    borderTopRightRadius: borderRadius,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                },
                ':last-of-type': {
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: borderRadius,
                    borderBottomLeftRadius: borderRadius,
                }
            }
        }
    }
}


const ShToggleButtonGroupPill = (theme: Theme, minWidth: string | undefined, gap: number) => {
    return {
        gap: gap ? theme.spacing(gap) : theme.spacing(1),
        '& .MuiToggleButton-root': {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(0.5),
            paddingBottom: theme.spacing(0.5),
            minWidth: minWidth || 'unset',
            textTransform: 'none',
            borderRadius: '50px',
            border: `1px solid ${theme.palette.divider}`,
            '&.Mui-selected': {
                border: `1px solid ${theme.palette.primary.main}`,
            }
        }
    }
}

interface IShToggleButtonGroup {
    borderRadius?: number;
    minWidth?: string;
    variant?: 'default' | 'pill',
    buttonsGap?: number
}

export const ShToggleButtonGroup = styled(ToggleButtonGroup, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "minWidth" &&
        prop !== "variant" && prop !== "buttonsGap"
})<IShToggleButtonGroup>(({ theme, borderRadius = ShBorderRadius, minWidth, variant = 'default', buttonsGap = 0 }) => ({
    ...(variant === 'default' ? ShToggleButtonGroupDefault(theme, borderRadius, minWidth) :
        ShToggleButtonGroupPill(theme, minWidth, buttonsGap))
}));

export const ShToggleButtonGroupV2 = styled(ToggleButtonGroup, {
    shouldForwardProp: (prop) =>
      prop !== 'borderRadius' && prop !== 'minWidth' &&
      prop !== 'variant' && prop !== 'buttonsGap',
  })<IShToggleButtonGroup>(
    ({
      theme,
      borderRadius = ShBorderRadius,
      minWidth = 'auto',
      variant = 'default',
      buttonsGap = 0,
    }) => ({
      display: 'inline-flex',
      gap: buttonsGap,
      borderRadius,
      overflow: 'hidden',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 2px 4px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.2)'}`,
  
      '& .MuiToggleButtonGroup-grouped': {
        minWidth,
        padding: theme.spacing(1, 2),
        fontWeight: 500,
        transition: 'all 0.3s ease',
        textTransform: 'capitalize',
        border: 'none',
        color: theme.palette.text.primary,
  
        '&:not(:last-of-type)': {
          borderRight: `1px solid ${theme.palette.divider}`,
        },
  
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
  
        '&.Mui-selected': {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
          boxShadow: `inset 0 0 10px ${
            theme.palette.mode === 'dark'
              ? 'rgba(255, 255, 255, 0.3)'
              : 'rgba(0, 0, 0, 0.2)'
          }`,
          fontWeight: 600,
  
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
  
        '&.Mui-disabled': {
          color: theme.palette.text.disabled,
          backgroundColor: theme.palette.action.disabledBackground,
        },
      },
  
      ...(variant === 'pill' && {
        borderRadius: '50px',
        boxShadow: `0px 3px 6px ${theme.palette.mode === 'dark' ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.1)'}`,
      }),
  
      ...(variant === 'default' && {
        border: `1px solid ${theme.palette.divider}`,
        backgroundColor: 'transparent',
        '& .MuiToggleButtonGroup-grouped': {
          '&:hover': {
            backgroundColor: theme.palette.action.hover,
          },
        },
      }),
    })
  );

export const ShCheckbox = styled(Checkbox)(
    ({ theme }) => ({
        '&:not(.Mui-checked)': {
            '& .MuiSvgIcon-root': {
                fill: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark
            }
        },
        '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main
            }
        }
    })
);


export const ShChipCheckbox = styled(Chip)(
    ({ theme }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0.5, 1),
      borderRadius: '16px',
      border: `1px solid ${theme.palette.grey[400]}`, // White outlined border initially
      backgroundColor: theme.palette.background.paper, // Paper background for the chip
      color: theme.palette.text.primary, // Default text color
      transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.3s ease', // Smooth transition
  
      '&:hover': {
        backgroundColor: theme.palette.grey[100], // Light background on hover
        transform: 'scale(1.05)', // Slight scale effect on hover
      },
  
      '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main, // Blue background when selected
        color: theme.palette.common.white, // White text when selected
        borderColor: theme.palette.primary.main, // Blue border when selected
      },
  
      '& .MuiChip-label': {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      },
  
      // No checkbox is needed, so we style the entire chip as an interactive toggle
      '& .MuiCheckbox-root': {
        display: 'none', // Hide the checkbox component
      },
  
      '&:active': {
        transform: 'scale(1.05)', // Slight scale effect when clicked
      },
    })
  );
  


export const ShGreenCheckbox = styled(Checkbox)(
    ({ theme }) => ({
        '&:not(.Mui-checked)': {
            '& .MuiSvgIcon-root': {
                fill: theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark
            }
        },
        '&.Mui-checked': {
            '& .MuiSvgIcon-root': {
                color: ShGreen
            }
        }
    })
);

export const SelectMenuProps = {
    PaperProps: {
        style: {
            maxHeight: '40%'
        },
    },
};

export const ShInputBase = styled(InputBase)(
    ({ theme }) => ({
        '& input': {
            padding: "10px",
            '&:focus-visible, &:focus, &:active, &:hover': {
                backgroundColor: theme.palette.mode === 'light' ? 'whitesmoke' : BorderColorDark
            }
        }
    })
);

export const ShTextareaWrapper = styled(Box)(
    ({ theme }) => ({
        '& textarea': {
            minWidth: '100%',
            maxWidth: '100%',
            padding: theme.spacing(1),
            fontFamily: 'inherit',
            minHeight: '40px',
            borderRadius: '4px',
            '&:active, &:focus, &:focus-within': {
                borderColor: theme.palette.primary.main,
                outlineColor: theme.palette.primary.main,
            }
        }
    })
);

interface IShTextField {
    borderRadius?: string;
    maxWidth?: string;
}

export const ShTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "maxWidth"
})<IShTextField>(
    ({ theme, borderRadius = ShBorderRadius, maxWidth = 'unset' }) => ({
        '& .MuiOutlinedInput-root': {
            minHeight: '40px',
            borderRadius: borderRadius,
            maxWidth: maxWidth,
            '& .phone-number': {
                border: 'none',
                outline: 'none !important',
                padding: '8px 14px',
                background: 'inherit',
                color: 'inherit'
            }
        }
    })
);

export const ShResizableTextField = styled(TextField)(({ theme }) => ({
    '& textarea': {
        resize: 'vertical', // Allows resizing only vertically
    },
}));

interface IShRadioGroup {
    color?: string,
    width?: string,
}

export const ShRadioGroup = styled(RadioGroup, {
    shouldForwardProp: (prop) => prop !== "color" && prop !== "width"
})<IShRadioGroup>(({ theme, color = theme.palette.primary.main, width = 'unset' }) => ({
    flexWrap: 'unset',
    '& .MuiFormControlLabel-label': {
        flex: 1
    },
    '& label': {
        border: `2px solid ${theme.palette.mode === 'light' ? BorderColorLight : BorderColorDark}`,
        borderRadius: ShBorderRadius,
        padding: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: 0,
        marginRight: 0,
        marginBottom: theme.spacing(1),
        width: width,
        '&.selected': {
            borderColor: color,
            '& .MuiRadio-root.Mui-checked': {
                color: color
            }
        }
    }
}));

interface IShTextField {
    borderRadius?: string;
    maxWidth?: string;
    isResizable?: boolean; 
    isReducedPadding?: boolean;
  }
  
  export const ShTextFieldV2 = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "borderRadius" && prop !== "maxWidth" && prop !== "isResizable" && prop !== "isReducedPadding"
})<IShTextField>(
    ({ theme, borderRadius = '10px', maxWidth = 'unset', isResizable = false, isReducedPadding = false }) => ({
        '& .MuiOutlinedInput-root': {
            minHeight: '40px',
            borderRadius: borderRadius,
            maxWidth: maxWidth,
            transition: 'all 0.3s ease',
            '& fieldset': {
                border: `2px solid ${theme.palette.grey[400]}`,
                transition: 'all 0.3s ease',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
                borderWidth: '2px',
            },
            '& .MuiOutlinedInput-input': {
                // padding: isReducedPadding
                //     ? '4px 6px' // Reduced padding
                //     : isResizable
                //     ? '0px 0px' // Padding for resizable input
                //     : '10px 14px', // Normal padding
                color: theme.palette.text.primary,
            },
            '& .phone-number': {
                border: 'none',
                outline: 'none !important',
                padding: '8px 14px',
                background: 'inherit',
                color: 'inherit',
            },
        },
        '& .MuiFormLabel-root': {
            fontWeight: 500,
            color: theme.palette.text.secondary,
            display: 'flex',
            alignItems: 'center', // Vertically center the label
            '&.Mui-focused': {
                color: theme.palette.primary.main,
            },
            // Optional: Adjust transform when the field is focused or when it has value
            '&.MuiFormLabel-animated': {
                transform: 'translate(14px, 8px) scale(1)', // Adjust if you want the label's position when the field is active
            },
        },
        '& textarea': {
            resize: isResizable ? 'vertical' : 'none',
        },
    })
);
